import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Button, Paper, Typography, withStyles } from '@material-ui/core'

import { withTranslation } from 'react-i18next'

import RadiatorModelsGridList from '../components/collections/lists/RadiatorModelsGridList'

import RadiatorModel from '../models/RadiatorModel'
import {
  clearRadiatorsCategoriesModels,
  getModelsByCategoryForRadiator,
} from '../actions/RadiatorModelActions'
import Radiator from '../models/Radiator'

const categories = {
  '141': 'labels.radiator.type.panelRadiators',
  '142': 'labels.radiator.type.plinthRadiators',
  '143': 'labels.radiator.type.horizontalDesign',
  '144': 'labels.radiator.type.verticalDesign',
  '145': 'labels.radiator.type.columnRadiators',
  '146': 'labels.radiator.type.towelWarmers',
}

type RecommendationsViewProps = {
  categoriesLists: Object,
  classes: Object,
  clearRadiatorsCategoriesModels: Function,
  getModelsByCategoryForRadiator: Function,
  onClose: Function,
  onSave: Function,
  radiator: Radiator,
  t: Function,
}

type RecommendationsViewState = {
  radiator: Radiator,
}

class RecommendationsView extends React.Component<
  RecommendationsViewProps,
  RecommendationsViewState
> {
  state = {
    radiator: new Radiator({}),
  }
  static defaultProps = {
    categoriesLists: {},
  }

  componentDidMount() {
    const { getModelsByCategoryForRadiator, radiator } = this.props

    radiator.categories.map((item: string): void =>
      getModelsByCategoryForRadiator(item, radiator)
    )
    this.setState({ radiator: new Radiator(radiator.toJSON()) })
  }

  componentWillUnmount() {
    const { clearRadiatorsCategoriesModels } = this.props
    clearRadiatorsCategoriesModels()
  }

  onSelectModel(oldId: string, selected: RadiatorModel) {
    const { radiator } = this.state
    let newRecommendations = []
    newRecommendations = radiator.recommendation.filter(
      (item: RadiatorModel): boolean => item.id.localeCompare(oldId) !== 0
    )
    newRecommendations.push(selected)

    radiator.recommendation = newRecommendations
    this.setState({ radiator: radiator })
  }

  onCancel() {
    const { onClose } = this.props
    onClose()
  }

  onSave() {
    const { onSave } = this.props
    const { radiator } = this.state
    onSave(
      radiator.recommendation.map((item: RadiatorModel): Object =>
        item.toJSON()
      )
    )
  }

  renderCategoriesLists(): Node {
    const { categoriesLists, t } = this.props
    const { radiator } = this.state

    return Object.entries(categoriesLists).map(
      (list: [string, any], idx: number): Node => (
        <RadiatorModelsGridList
          key={idx}
          title={t(categories[list[0]])}
          data={list[1]}
          selectedIds={radiator.recommendation.map(
            (item: RadiatorModel): string => item.id
          )}
          onSelect={(id: string, model: RadiatorModel): void =>
            this.onSelectModel(id, model)
          }
        />
      )
    )
  }

  render(): Node {
    const { classes, t } = this.props
    return (
      <Paper
        classes={{
          root: classes.container,
        }}>
        <div className={classes.content}>
          <Typography className={classes.title} variant='h2'>
            {t('labels.editRecommendedList')}
          </Typography>
          {this.renderCategoriesLists()}
        </div>
        <div className={classes.actionsContainer}>
          <Button
            variant='outlined'
            color='inherit'
            onClick={(): void => this.onCancel()}>
            {t('labels.cancel')}
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            variant='contained'
            color='primary'
            onClick={(): void => this.onSave()}>
            {t('labels.saveRecommendations')}
          </Button>
        </div>
      </Paper>
    )
  }
}

const styles = {
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  content: {
    paddingTop: 40,
    paddingBottom: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  actionsContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '20px 40px',
    backgroundColor: 'white',
  },
  title: {
    paddingBottom: 40,
  },
}

const mapStateToProps = (state: Object): Object => {
  return {
    categoriesLists: state.models.all,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {
    clearRadiatorsCategoriesModels,
    getModelsByCategoryForRadiator,
  }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RecommendationsView)
  )
)
