import React from 'react'
import type { Node } from 'react'

import classNames from 'classnames'

import { Button, Divider, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import GridItemBase from './base/GridItemBase'

import Space from '../../../../models/Space'
import {
  amountOfSelectedRadiators,
  amountOfSelectedItems,
} from '../../../../selectors/radiatorSelectors'

type SpaceItemProps = {
  classes: Object,
  data: Space,
  disabled: boolean,
  onSelect?: Function,
  t: Function,
}

class SpaceItem extends React.Component<SpaceItemProps> {
  onSelect() {
    const { data, onSelect } = this.props
    if (onSelect) {
      onSelect(data)
    }
  }

  renderInfoValues(): Node {
    const { classes, data, disabled, t } = this.props
    const containerClasses = classNames(
      classes.infoContainer,
      disabled ? classes.disabled : null
    )

    return (
      <React.Fragment>
        <div className={containerClasses}>
          <div className={classes.leftAlignedContainer}>
            <Typography className={classes.label} variant='h5'>
              {`${data.requestedPower - data.alternativePower} W`}
            </Typography>
            <Divider />
            <Typography variant='caption'>
              {t('labels.requiredPower')}
            </Typography>
          </div>
          <div className={classes.rightAlignedContainer}>
            <div className={classes.selectedModelsContainer}>
              <Typography className={classes.label} variant='h5'>
                {`${data.radiators.length}`}
              </Typography>
            </div>
            <Divider />
            <Typography variant='caption'>
              {t('labels.addedRadiators')}
            </Typography>
          </div>
        </div>
        <div className={containerClasses}>
          <div className={classes.leftAlignedContainer}>
            <Typography className={classes.label} variant='h5'>
              {amountOfSelectedRadiators(data.radiators)}
            </Typography>
            <Divider />
            <Typography variant='caption'>
              {t('labels.selectedModels')}
            </Typography>
          </div>
          <div className={classes.rightAlignedContainer}>
            <div className={classes.selectedModelsContainer}>
              <Typography className={classes.label} variant='h5'>
                {amountOfSelectedItems(data.radiators)}
              </Typography>
            </div>
            <Divider />
            <Typography variant='caption'>
              {t('labels.selectedItems')}
            </Typography>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderButton(): Node {
    const { classes, data, disabled, t } = this.props
    if (disabled) {
      return (
        <Button className={classes.button} color='inherit' variant='outlined'>
          {t('labels.enable')}
        </Button>
      )
    } else if (data.radiatorsCount === 0) {
      return (
        <Button
          className={classes.button}
          color='secondary'
          variant='contained'
          onClick={(): void => this.onSelect()}>
          {t('labels.viewSpace')}
        </Button>
      )
    }
    return (
      <Button
        className={classes.button}
        color='secondary'
        variant='contained'
        onClick={(): void => this.onSelect()}>
        {t('labels.editSpace')}
      </Button>
    )
  }

  render(): Node {
    const { classes, data, disabled } = this.props
    const titleClasses = classNames(
      classes.title,
      classes.label,
      disabled ? classes.disabled : null
    )

    return (
      <GridItemBase disabled={disabled}>
        <div className={classes.container}>
          <Typography className={titleClasses} variant='h6'>
            {data.name}
          </Typography>
          {this.renderInfoValues()}
          {this.renderButton()}
        </div>
      </GridItemBase>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 22px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.secondary.main,
    paddingTop: 45,
  },
  leftAlignedContainer: {
    width: '45%',
    textAlign: 'left',
  },
  rightAlignedContainer: {
    width: '45%',
    textAlign: 'right',
  },
  button: {
    marginTop: 40,
  },
  title: {
    fontWeight: 200,
    height: 78,
  },
  label: {
    paddingBottom: 5,
  },
  disabled: {
    color: theme.palette.disabled.main,
  },
  selectedModelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})

export default withTranslation('common')(withStyles(styles)(SpaceItem))
