const common = {
  hints: {
    code: 'Unique project code *',
    customTemperature: 'Desired space temperature',
    email: 'Email *',
    powerForSpace: 'Required power for this space [W]',
    project: 'Project name *',
    radiatorName: 'Name of radiator *',
    roomHeight: 'Height of the space [m] *',
    roomLength: 'Length of the space [m] *',
    roomSelection: 'Select type of the space',
    roomWidth: 'Width of the space [m] *',
    sendRequest: 'Customer’s / designer’s e-mail *',
    space: 'Name of space *',
    waterInflowTemperature: 'Water inflow temperature',
    waterOutflowTemperature: 'Water outflow temperature',
  },
  info: {
    alternativePowerSource:
      'If the space has any alternative power source enter it’s power here',
    codes:
      'If you can’t find the appropriate code of the project don’t worry. Please type your e-mail and you will receive the message with all project names and corresponding codes related with this e-mail.',
    createRadiator:
      'Create a list of recommended models. The end user or designer who will select the style and models will have the list of recommended models displayed at the top of the model list.',
    createSpace:
      'If your room is using the underfloor heating, fireplace or electrical heater please specify the expected summary power in watts. It will be subtracted from the calculated space power requirement.',
    deleteRadiator: 'You are about to delete one of radiators. Are you sure?',
    electricalRadiators:
      'Electric radiators (if you select this the water based radiators will be excluded)',
    emission: {
      part1:
        'Set the temperatures of the heating supply - parameters of the system and boiler.',
      part2:
        'With sliders set the general tolerances for underrating and overheating (from 0 to 30%) or leave at default values.',
    },
    itemSelection:
      'Please select here the appropriate item for the model choice done by designer or customer. Alternatively you can also edit the model selection in favour of your customer or designer.',
    limitConnections:
      'Limit the possible water connections of the water based radiators',
    limitedConnections: {
      part1:
        'If the radiator that you are creating has the limited water connection options use this function to limit the choice according to the selected water connection options.',
      part2:
        'Please be aware that you can reduce the number of available models with this option.',
    },
    parameters: {
      automatically:
        'Select the parameters of building location and heating supply in the building. The underrating and overheating margins will result in greater or smaller choice of radiators fitting the requirements.',
      manually:
        'Enter the space power (heat output) requirements manually for each space later at the time of defining spaces and its parameters. Also provide the heating supply in the building. The underrating and overheating margins will result in greater or smaller choice of radiators fitting the requirements.',
    },
    project: {
      part1:
        'Create new project to freely define the building parameters and spaces inside while visiting your clients construction site. Then define all your technical requirements for the radiators in this building.',
      part2:
        'This will allow you to quickly send online the  “design choice request” to your customer or interior designer so they can select the models basing on your input.',
      part3:
        'The end result for you will be the ready shopping list of radiators that fit all your requirements and parameters calculated automatically.',
    },
    realValues:
      'Real values are subject to the offering process conducted by installer',
    requiredPower:
      'I know required power and will put it manually for each space (i.e. the power has been specified in the heating installation project)',
    technicalSetup: 'Technical setup in progress',
    requiredPowerForRoom:
      'If you know required power for this room enter it in the field at the right side',
    shoppingList: {
      part1:
        'The list above presents the radiators (items) selected in cooperation with customer / designer. If the customer / designer didn’t finish the selection process and prefers you to do so you can do it by switching to the MODEL SELECTION MODE at the summary page.',
      part2:
        'You can also preview the selection in the particular spaces to see which items has been selected for which space.',
    },
    spaceDetails: {
      define:
        'Add radiators and define their parameters in order to fill the required power with the power from radiators added. Also specify the dimensions and other parameters when needed. You can direct now the future model & design choice by specifying all necessary parameters when adding the radiator.',
      modelsSelected:
        'The radiator models have been already selected!. Now specify the single particular item from the available items. The selected item will be added to your final “shopping list” of radiators.',
    },
    spacesOverview: {
      part1:
        'Spaces are the rooms in your building. It can be any type of room for which you want to assign the radiators.',
    },
    summary: {
      part1:
        'Use the form field at the left in order to send the model choice request to your customer or interior designer who will focus on the style and colour to make the choice of radiator models from wide range of HeatPicker radiators in multiple product categories.',
      part2:
        'After the model choice is done you will be notified by e-mail and then you are ready to make the final item selection in each space. After this you will have the ready shopping list of radiators.',
    },
    waterIn:
      'Select all possible inflow water connections at the schematic radiator drawing',
    waterOut:
      'Select all possible outflow water connections at the schematic radiator drawing',
  },
  labels: {
    addedRadiators: 'Added radiators',
    addNewSpace: 'Add new space',
    addNewRadiator: 'Add new radiator',
    addRadiators: 'Add radiators',
    alternativePowerSource: 'Alternative power source',
    back: 'Back',
    calculateAndExit: 'Calculate & exit',
    calculated: 'Calculated',
    calculatedPower: 'Calculated power',
    cancel: 'Cancel',
    cancelAndExit: 'Cancel & exit',
    changeRadiatorColor: 'Change radiator color',
    codesHaveBeenSent: 'Codes have been sent',
    choose: 'Choose',
    createProject: 'Create new project',
    customTemperature: '… or enter the desired space temperature manually',
    deleteSpaceAndExit: 'Delete space & exit',
    designAndModelChoice: 'Design & model choice',
    disableSpace: 'Temporary disable space',
    duplicate: 'Duplicate',
    editedRadiator: 'Edited radiator',
    editRadiator: 'Edit radiator',
    editRecommendedList: 'Edit recommended list',
    editSpace: 'Edit space & radiators',
    editSpaceParameters: 'Edit space parameters',
    emailCodes: 'Email codes',
    email: {
      feedbackTitle: 'Email sent',
      message: 'Your installer has sent you a request to pick your radiators',
      sent: 'Your message to customer has been sent',
      subject: "Pick your radiators' models",
    },
    enable: 'Enable',
    enterCode: 'Enter unique project code (6 characters)',
    enterEmail: 'Enter your email',
    enterPower: 'Enter power requirement',
    enterProject: 'Enter project name',
    enterRadiatorName: 'Enter radiator name or leave default',
    enterRecipientsMails: 'Enter recipients e-mails (separate with coma “,”)',
    enterSpace: 'Enter space name',
    excludingDisabled: 'excluding disabled',
    finalItemsSelected: 'Final items selected',
    finalListOfRadiators: 'Final list of radiators',
    frontOfRadiator: 'Front of radiator',
    goToSummary: 'Go back to summary',
    goToSpaces: 'Go to spaces',
    glazingSurface: {
      noWindows: 'No windows',
      lessThan10: 'Less or equal to 10%',
      between10And20: 'Between 10% and 20%',
      moreThan20: 'More than 20%',
    },
    glazingQuality: {
      single: 'Single glazing',
      doubleOld: 'Double glazing old',
      doubleNew: 'Double glazing new',
      triple: 'Triple glazing modern',
    },
    images: 'Images',
    insulation: {
      poor: 'Poor',
      average: 'Average',
      good: 'Good',
      veryGood: 'Very good',
    },
    item: {
      color: 'Color',
      height: 'Height',
      number: 'Item no.',
      power: 'Power',
      price: 'List price',
      state: 'State',
      type: 'Type',
      width: 'Width',
    },
    locations: {
      midCountry: 'Mid country',
      mountain: 'Mountain or high hills',
      seaSide: 'Sea side',
    },
    manualPowerInput: 'Manual power input',
    modelSelectionMode: 'Model selection mode',
    modelsSelected: 'Models selected',
    noCode: 'No codes found in your inbox?',
    ok: 'OK',
    other: 'Other',
    openExistingProject: 'or open existing project',
    openProject: 'Open project',
    powerDifference: 'Power difference',
    powerRequirements: 'Power requirements',
    preview: 'Preview',
    previewRequest: 'Preview request',
    print: 'Print',
    productRecommendation: 'Installer model recommendations',
    projectCode: 'Project code',
    projectName: 'Project name',
    projectStatus: 'Project status',
    projectSummary: 'Project summary',
    purmoSelector: 'HeatPicker Radiators Tool',
    radiator: {
      allPower: 'Total space power',
      heightLimitations: 'Radiator height limitations',
      location: "Radiator's location",
      power: 'Power of radiator',
      radiator: 'Radiator',
      selectedPower: 'Selected radiator power',
      type: {
        columnRadiators: 'Column radiators',
        horizontalDesign: 'Horizontal design',
        panelRadiators: 'Panel radiators',
        panelsHeight200: 'Panels - height 200 mm',
        plinthRadiators: 'Plinth radiators',
        towelWarmers: 'Towel warmers',
        trenchConvectors: 'Trench convectors',
        verticalDesign: 'Vertical design',
      },
      widthLimitations: 'Radiator width limitations',
    },
    radiatorColorSelection: 'Radiator color selection',
    radiatorsAdded: 'Radiators added',
    radiatorsIn: 'Radiators in',
    radiatorsInProject: 'Radiators in the project',
    radiatorsPower: 'Radiators power',
    radiatorsSubtotal: 'Radiators subtotal',
    recommendedProducts: 'Recommended models',
    requiredPower: 'Required power',
    requiredRadiators: 'Required radiators',
    room: {
      height: 'Space height [m]',
      length: 'Space length [m]',
      type: {
        bathroom: 'Bathroom',
        bedroom: 'Bedroom',
        garage: 'Garage',
        hall: 'Hall',
        kitchen: 'Kitchen',
        livingroom: 'Livingroom',
        office: 'Office',
        storage: 'Storage',
      },
      width: 'Space width [m]',
    },
    saveAndGoToSpaces: 'Save ang go to spaces',
    saveAndExit: 'Save & exit',
    saveList: 'Save list to PDF or XLS',
    saveRecommendations: 'Save recommendations',
    saveSelectionAndClose: 'Save selection & close',
    selectedColor: 'Selected color',
    selectItem: 'Select item',
    selectedItems: 'Selected items',
    selectedModels: 'Selected models',
    selectedProduct: 'Selected product',
    selectRadiatorItem: 'Select radiator item',
    sendRequest: 'Send request',
    sendRequestToCustomer:
      'Send radiators model choice request to customer or interior designer',
    space: {
      name: 'Space name',
      type: 'Space type',
    },
    startProject: 'Start new project',
    storey: {
      groundCeiling: 'Ground-floor and ceiling',
      groundRoof: 'Ground-floor and roof',
      floorCeiling: 'Floor and ceiling',
      floorRoof: 'Floor and roof',
    },
    suggestedListPriceValueOnly: 'Suggested list price value only.',
    summary: 'Summary',
    totalRequiredPower: 'Total power required for the project',
    viewItem: 'View item',
    viewSpace: 'View space',
    uploadImages: 'Upload images',
    waterInTemperature: 'Water in temperature',
    waterOutTemperature: 'Water out temperature',
    windConditions: {
      strong: 'Strong winds',
      normal: 'Normal winds',
      smooth: 'Smooth winds',
    },
  },
  navigation: {
    building: 'Building',
    spaces: 'Spaces',
    start: 'Start',
    projectSummary: 'Project summary',
    radiators: 'Radiators',
  },
  titles: {
    addNewRadiator: 'Add new radiator',
    addSpace: 'Edit space parameters',
    alternativePowerSource: 'Alternative power source',
    app: 'HeatPicker',
    building: 'Building',
    buildingLocation: 'Building location',
    deleteRadiator: 'Delete radiator',
    finalListOfItems: 'Final list of items',
    glazingSurface: 'Glazing surface',
    glazingQuality: 'Glazing quality',
    heatEmission: 'Heating supply',
    heatingSupplyParameters: 'Heating supply parameters',
    insulationQuality: 'Insulation quality',
    limitedConnections: 'Limited connections',
    productCategories: 'Product categories for choice',
    projectSummary: 'Project summary',
    roomType: 'Room type',
    spaceDimensions: 'Space dimensions',
    spaceStoreys: 'Space storeys',
    overheating: 'Overheating',
    spaces: 'Spaces',
    suggestedListPrice: 'Suggested list price value of the selected radiators',
    underheating: 'Underheating',
    waterIn: 'Water in',
    waterOut: 'Water out',
    windConditions: 'Wind conditions',
  },
}

export default common
