import React from 'react'
import type { Node } from 'react'

import {
  Button,
  GridList,
  GridListTile,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames'

import RadiatorModel from '../../../models/RadiatorModel'

type RadiatorModelsGridListProps = {
  classes: Object,
  data: Array<RadiatorModel> | null,
  onSelect: Function,
  selectedIds: Array<string>,
  t: Function,
  title: string | null,
}

class RadiatorModelsGridList extends React.Component<RadiatorModelsGridListProps> {
  currentSelections = []
  static defaultProps = {
    selectedIds: [],
  }

  onItemSelected(item: RadiatorModel) {
    const { onSelect } = this.props
    if (!onSelect) {
      return
    }

    if (this.currentSelections.length > 1) {
      onSelect(this.currentSelections[0], item)
    } else {
      onSelect(null, item)
    }
  }

  renderItems(): Node {
    const { classes, data, selectedIds, t } = this.props
    this.currentSelections = []

    return (data || []).map((item: RadiatorModel, idx: number): Node => {
      let tileClasses
      if (selectedIds.indexOf(item.id) !== -1) {
        if (
          this.currentSelections.length === 0 ||
          selectedIds.indexOf(this.currentSelections[0]) <
            selectedIds.indexOf(item.id)
        ) {
          this.currentSelections.push(item.id)
        } else {
          this.currentSelections.unshift(item.id)
        }

        tileClasses = classNames(classes.gridItem, classes.gridItemSelected)
      } else {
        tileClasses = classNames(classes.gridItem)
      }

      return (
        <GridListTile
          component={'div'}
          classes={{ tile: tileClasses }}
          key={idx}
          cols={1}>
          <img className={classes.image} src={item.thumbnail} alt={item.name} />
          <Typography className={classes.productTitle}>{item.name}</Typography>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              variant='outlined'
              color='inherit'
              onClick={(): void => this.onItemSelected(item)}>
              {t('labels.choose')}
            </Button>
          </div>
        </GridListTile>
      )
    })
  }

  render(): Node {
    const { classes, data, title } = this.props
    let computedTitle: string = title || ''
    if (!data || data.length === 0) {
      computedTitle = ''
    } else if (title == null && (data && data[0])) {
      computedTitle = data[0].categoryName
    }

    return (
      <div className={classes.container}>
        <Typography className={classes.title} variant='h3'>
          {computedTitle}
        </Typography>
        <GridList cellHeight={350} className={classes.gridList} cols={4}>
          {this.renderItems()}
        </GridList>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    overflow: 'hidden',
    marginBottom: 50,
  },
  gridList: {
    width: '100%',
    zIndex: -100,
  },
  title: {
    marginBottom: 10,
    marginLeft: 20,
  },
  gridItem: {
    backgroundColor: 'white',
    borderRadius: 29,
  },
  gridItemSelected: {
    border: `6px solid ${theme.palette.primary.main}`,
  },
  image: {
    marginTop: 10,
    height: 'auto',
    width: '75%',
  },
  productTitle: {
    textAlign: 'center',
    fontWeight: 500,
    height: 48,
    marginTop: 5,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '60%',
    minWidth: 128,
    marginTop: 10,
  },
})

export default withTranslation('common')(
  withStyles(styles)(RadiatorModelsGridList)
)
