import React from 'react'
import type { Node } from 'react'
import { Popper, Tooltip, withStyles } from '@material-ui/core'

type ValueLabelProps = {
  children?: Node,
  classes: Object,
  value: string,
  valueLabelDisplay: string,
  valueLabelFormat: Function,
}

class ValueLabel extends React.Component<ValueLabelProps> {
  static defaultProps = {
    valueLabelFormat: (value: string): string => value,
  }
  updatePopper(ref: Popper) {
    if (ref) {
      ref.update()
    }
  }

  render(): Node {
    const { children, classes, value, valueLabelFormat } = this.props
    return (
      <Tooltip
        PopperProps={{
          popperRef: (ref: Node): void => this.updatePopper(ref),
          transition: true,
        }}
        classes={{ tooltip: classes.container }}
        open
        enterTouchDelay={1000}
        placement='top'
        disableTouchListener
        title={valueLabelFormat(value)}>
        {children}
      </Tooltip>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 500,
    zIndex: 'auto',
  },
})

export default withStyles(styles)(ValueLabel)
