import CreateProjectView from '../views/CreateProjectView'
import ConfigureBuildingView from '../views/ConfigureBuildingView'
import SpacesOverviewView from '../views/SpacesOverviewView'
import CreateSpaceView from '../views/CreateSpaceView'
import SpaceDetailsView from '../views/SpaceDetailsView'
import CreateRadiatorView from '../views/CreateRadiatorView'
import ItemSelectionView from '../views/ItemSelectionView'
import ProjectSummaryView from '../views/ProjectSummaryView'
import ShoppingListView from '../views/ShoppingListView'

import ModelInfoBox from '../components/info/ModelInfoBox'

const mainRoutes: Array<Object> = [
  {
    component: CreateProjectView,
    path: '/start',
  },
  {
    component: ConfigureBuildingView,
    path: '/building',
  },
  {
    component: SpacesOverviewView,
    path: '/spaces/overview',
  },
  {
    component: CreateSpaceView,
    path: '/spaces/create',
  },
  {
    component: CreateSpaceView,
    path: '/spaces/edit',
  },
  {
    component: SpaceDetailsView,
    path: '/spaces/details',
  },
  {
    component: CreateRadiatorView,
    path: '/radiators/create',
  },
  {
    component: CreateRadiatorView,
    path: '/radiators/edit',
  },
  {
    component: CreateProjectView,
    path: '/request',
  },
  {
    component: ItemSelectionView,
    topBarComponent: ModelInfoBox,
    path: '/item/selection',
  },
  {
    component: ProjectSummaryView,
    path: '/project/summary',
  },
  {
    component: ShoppingListView,
    path: '/project/shopping-list',
  },
]

const Routes = {
  main: mainRoutes,
}

export default Routes
