import RadiatorModel from '../models/RadiatorModel'
import type { RadiatorModelAction } from '../actions/RadiatorModelActions'
import { RadiatorModelActionType } from '../actions/RadiatorModelActions'

type RadiatorModelsStateType = {
  all: { [string]: Array<RadiatorModel> },
  current: RadiatorModel | null,
}

const defaultState: RadiatorModelsStateType = {
  all: {},
  current: null,
}

export default (
  state: RadiatorModelsStateType = defaultState,
  action: RadiatorModelAction
): RadiatorModelsStateType => {
  let newState: RadiatorModelsStateType | null = null

  switch (action.type) {
    case RadiatorModelActionType.RADIATOR_MODEL_SET_CATEGORY:
      newState = {
        ...state,
        all: { ...state.all, [action.category]: action.models },
      }
      break
    case RadiatorModelActionType.RADIATOR_MODEL_CLEAR_CATEGORIES:
      newState = {
        ...state,
        all: {},
      }
      break
    default:
      break
  }
  return newState || state
}
