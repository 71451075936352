const common = {
  hints: {
    code: 'Unikalny kod projektu *',
    customTemperature: 'Oczekiwana temperatura pomieszczenia',
    email: 'Email *',
    powerForSpace: 'Wymagana moc dla tego pomieszczenia [W]',
    project: 'Nazwa projektu *',
    radiatorName: 'Nazwa grzejnika *',
    roomHeight: 'Wysokość pomieszczenia [m] *',
    roomLength: 'Długość pomieszczenia [m] *',
    roomSelection: 'Wybierz rodzaj pomieszczenia',
    roomWidth: 'Szerokość pomieszczenia [m] *',
    sendRequest: 'E-mail Klienta / Projektanta *',
    space: 'Nazwa pomieszczenia *',
    waterInflowTemperature: 'Temperatura wody wpływającej',
    waterOutflowTemperature: 'Temperatura wody wypływającej',
  },
  info: {
    alternativePowerSource:
      'Jeśli pomieszczenie posiada dodatkowe źródła ciepła podaj ich łączną przybliżoną moc ',
    codes:
      'Jeśli nie możesz podać kodu projektu nie ma obawy. Wprowadź e-mail aby otrzymać listę powiązanych z nim projektów wraz z ich kodami.',
    createRadiator:
      'Stwórz listę rekomendowanych modeli grzejników. Klient lub projektant wybierający modele otrzyma rekomendowane produkty na górze listy wyboru (jeśli dany model pasuje do podanych parametrów wybieranego grzejnika).',
    createSpace:
      'Jeśli w definiowanym pomieszczeniu występują inne źródła ciepła - takie jak kominek, ogrzewanie podłogowe itp. podaj łączną przybliżoną moc grzewczą tych źródeł. Zostanie ona uwzględniona przy określeniu niezbędnej mocy grzewczej dla grzejników w tym pomieszczeniu.',
    deleteRadiator:
      'Czy na pewno chcesz usunąć ten grzejnik? Tej operacji nie będzie można cofnąć.',
    electricalRadiators:
      'Grzejniki elektryczne (po wybraniu tej opcji jedynie modele elektryczne będą dostępne dla tego grzejnika)',
    emission: {
      part1: 'Podaj zakres temperatur systemu grzewczego w budynku.',
      part2:
        'Podaj zakres tolerancji dla określonej mocy grzewczej grzejników (od 0 to 30%).',
    },
    itemSelection:
      'Wybierz ostateczny produkt (ITEM) dla wybranego modelu grzejnika. Dostępny zakres to produkty spełniające wymagania określone przy definiowaniu parametrów grzejnika w ramach wybranego modelu.',
    parameters: {
      automatically:
        'Określ parametry lokalizacyjne budynku oraz jego systemu grzewczego. Marginesy tolerancji mocy grzewczej pozwolą na wyświetlenie mniejszej lub większej listy ostatecznie pasujących produktów..',
      manually:
        'Zaznacz tę opcję wyłącznie jeśli dla wszystkich pomieszczeń w budynku znasz niezbędną wartość mocy grzewczej (np. z projektu instalacji grzewczej). Sama wartość mocy będzie podawana przy definiowaniu pomieszczeń. Parametry sieci grzewczej i tolerancji są nadal niezbędne.',
    },
    project: {
      part1:
        'Stwórz nowy projekt i łatwo zdefiniuj parametry budynku i pomieszczeń w których będą niezbędne grzejniki. Za pomocą tej aplikacji łatwo określisz wszystkie wymagania dla poszczególnych grzejników w budynku.',
      part2:
        'Następnie będziesz mógł wysłać na email klienta i/lub projektanta wnętrz prośbę o wybór stylistyczny modeli poszczególnych grzejników. Alternatywnie także samodzielnie możesz dokonać takiego wyboru w zależności od ustaleń z klientem.',
      part3:
        'W ostatnim etapie dobierzesz finalny produkt spełniający podane parametry w gamie wybranego modelu. Rezultatem końcowym będzie lista zakupowa grzejników dla całego budynku. Po przesłaniu jej do dystrybutora otrzymasz ofertę z rabatami specjalnie dla Ciebie.',
    },
    realValues:
      'Ostateczne wartości będą ustalone w ramach oferty od dystrybutora HeatPicker.',
    requiredPower:
      'Znam wartości mocy grzewczej dla wszystkich pomieszczeń i wprowadzę je ręcznie (np. z projektu instalacji grzewczej).',
    technicalSetup: 'Definiowanie parametrów technicznych',
    requiredPowerForRoom:
      'Jeśli znasz wartość mocy grzewczej dla tego pomieszczenia podaj ją w polu obok',
    shoppingList: {
      part1:
        'Lista powyżej prezentuje modele grzejników dobrane przez klienta lub projektanta wnętrz w ramach określonych wcześniej parametrów. Alternatywnie możesz ten wybór dokonać także samodzielnie wybierając na stronie PODSUMOWANIE PROJEKTU opcję TRYB WYBORU MODELU.',
      part2:
        'Możesz także podglądnąć wybór modelu i produktu (ITEM) na stronie danego wnętrza i grzejnika.',
    },
    spaceDetails: {
      define:
        'Stwórz planowaną według Ciebie ilość grzejników dla danego pomieszczenia i określ ich parametry i ograniczenia w zakresie wymiarów. Wówczas na etapie stylistycznego wyboru modelu grzejnika dostępne opcje będą zawężone do takich w których zakresie są produkty spełniające te wymagania. Pozwoli to na wyeliminowanie ewentualnego niedopasowanego wyboru modelu przez klienta lub projektanta wnętrz.',
      modelsSelected:
        'Modele grzejników zostały już wybrane!. Teraz wybierz pojedynczy produkt spośród spełniających wszystkie wymagania. Wybrany produkt będzie dodany do finalnej “listy zakupowej” grzejników dla tego projektu.',
    },
    spacesOverview: {
      part1:
        'Pomieszczenia w tym projekcie to pokoje i inne pomieszczenia w budynku dla których będą niezbędne grzejniki.',
    },
    summary: {
      part1:
        'Skorzystaj z opcji wysłania e-mail a z prośbą o wybór stylistyczny do swojego klienta i/lub projektanta wnętrz. Zakres wyboru modeli będzie podlegał ograniczeniom podanym przez Ciebie przy definiowaniu kategorii i parametrów każdego grzejnika więc nie powinno być pomyłek. Dodatkowo wybór modelu jest uzupełniony o inspiracje stylistyczne i wnętrzarskie HeatPicker.',
      part2:
        'Gdy stylistyczny wybór modeli zostanie dokonany zostaniesz poinformowany za pomocą e-maila. Wó∑czas możesz finalnie określić ostatecznie produkty każdego grzejnika (ITEM). Ostatecznie otrzymasz finalną "listę zakupową" grzejników dla tego projektu.',
    },
  },
  labels: {
    addNewSpace: 'Dodaj pomieszczenie',
    addNewRadiator: 'Dodaj grzejnik',
    addRadiators: 'Dodaj grzejniki',
    alternativePowerSource: 'Moc źródeł alternatywnych',
    back: 'Wstecz',
    calculateAndExit: 'Oblicz i wyjdź',
    calculated: 'Wyliczana',
    calculatedPower: 'Moc wyliczana',
    cancel: 'Anuluj',
    cancelAndExit: 'Anuluj i wyjdź',
    changeRadiatorColor: 'Zmień kolor grzejnika',
    codesHaveBeenSent: 'Kody zostały wysłane',
    choose: 'Wybierz',
    createProject: 'Stwórz nowy projekt',
    deleteSpaceAndExit: 'Usuń pomieszczenie i wyjdź',
    designAndModelChoice: 'Wybór stylistyczny modelu',
    disableSpace: 'Tymczasowo wyłącz pomieszczenie',
    editedRadiator: 'Edytowany grzejnik',
    duplicate: 'Duplikuj',
    editRadiator: 'Edytuj grzejnik',
    editRecommendedList: 'Edytuj listę rekomendacji',
    editSpace: 'Edytuj pomieszczenie i grzejniki',
    editSpaceParameters: 'Edytuj parametry pomieszczenia',
    emailCodes: 'Wyślij kody',
    email: {
      feedbackTitle: 'Email wysłany',
      message: 'Twój instalator prosi o wybór grzejników',
      sent: 'Twoja wiadomość do klienta została wysłana',
      subject: 'Wybierz swoje grzejniki',
    },
    enable: 'Włącz',
    enterCode: 'Podaj kod projektu (6 znaków)',
    enterEmail: 'Podaj swój e-mail',
    enterPower: 'Podaj wymaganą moc',
    enterProject: 'Podaj nazwę projektu',
    enterRadiatorName: 'Podaj nazwę grzejnika lub zostaw domyślną',
    enterRecipientsMails:
      'Podaj e-mail odbiorcy (oddziel przecinkiem “,” gdy jest ich więcej)',
    enterSpace: 'Podaj nazwę pomieszczenia',
    excludingDisabled: 'nie licząc wyłączonych',
    finalItemsSelected: 'Produkt (ITEM) wybrany',
    finalListOfRadiators: 'Finalna lista grzejników',
    goToSummary: 'Wróć do podsumowania',
    goToSpaces: 'Wróć do Pomieszczeń',
    glazingSurface: {
      noWindows: 'Bez okien',
      lessThan10: 'Mniej lub równe 10%',
      between10And20: 'Pomiędzy 10% a 20%',
      moreThan20: 'Powyżej 20%',
    },
    glazingQuality: {
      single: 'pojedyncza szyba',
      doubleOld: 'Podwójna szyba - stare',
      doubleNew: 'Podwójna szyba - nowe',
      triple: 'Potrójna szyba',
    },
    images: 'Zdjęcia',
    insulation: {
      poor: 'Słaba',
      average: 'Przeciętna',
      good: 'Dobra',
      veryGood: 'Bardzo dobra',
    },
    item: {
      color: 'Kolor',
      height: 'Wysokość',
      number: 'Numer ITEM',
      power: 'Moc',
      price: 'Cena cennikowa',
      state: 'Status',
      type: 'Typ',
      width: 'Szerokość',
    },
    locations: {
      midCountry: 'Centralna część kraju',
      mountain: 'Obszar górski',
      seaSide: 'Obszar nadmorski',
    },
    manualPowerInput: 'Ręczny wybór mocy',
    modelSelectionMode: 'Tryb wyboru modelu',
    modelsSelected: 'Modele wybrane',
    noCode: 'Nie masz kodu projektu?',
    ok: 'OK',
    openExistingProject: 'lub otwórz istniejący projekt',
    openProject: 'Otwórz projekt',
    powerDifference: 'Różnica mocy',
    powerRequirements: 'Wymagania mocy',
    preview: 'Zobacz',
    previewRequest: 'Podgląd zapytania',
    productRecommendation: 'Rekomendacje instalatora',
    projectCode: 'Kod projektu',
    projectName: 'Nazwa projektu',
    projectStatus: 'Status projektu',
    projectSummary: 'Podsumowanie projektu',
    purmoSelector: 'KONFIGURATOR GRZEJNIKÓW HEATPICKER',
    radiator: {
      allPower: 'Całkowita moc dla pomieszczenia',
      heightLimitations: 'Ograniczenia wysokości grzejnika',
      location: 'Lokalizacja grzejnika',
      power: 'Moc grzejnika',
      radiator: 'Grzejnik',
      selectedPower: 'Wybrana moc grzejnika',
      type: {
        columnRadiators: 'Grzejniki Kolumnowe',
        horizontalDesign: 'Poziome Grzejniki Dekoracyjne',
        panelRadiators: 'Grzejniki Płytowe',
        panelsHeight200: 'Grzejniki Płytowe - wys. 200 mm',
        plinthRadiators: 'Grzejniki Konwektorowe',
        towelWarmers: 'Grzejniki Łazienkowe',
        trenchConvectors: 'Grzejniki Kanałowe',
        verticalDesign: 'Pionowe Grzejniki Dekoracyjne',
      },
      widthLimitations: 'Ograniczenia szerokości grzejnika',
    },
    radiatorColorSelection: 'Wybór koloru grzejnika',
    radiatorsAdded: 'Dodane grzejniki',
    radiatorsIn: 'Grzejniki w',
    radiatorsInProject: 'Grzejniki w projekcie',
    radiatorsPower: 'Moc grzejnika',
    radiatorsSubtotal: 'Podsuma',
    recommendedProducts: 'Rekomendowane modele',
    requiredPower: 'Wymagana moc',
    requiredRadiators: 'Wymagane grzejniki',
    room: {
      height: 'Wysokość [m]',
      length: 'Długość [m]',
      type: {
        bathroom: 'Łazienka',
        bedroom: 'Sypialnia',
        garage: 'Garaż',
        hall: 'Hol',
        kitchen: 'Kuchnia',
        livingroom: 'Pokój dzienny',
        office: 'Pomieszczenie biurowe',
        storage: 'Pomieszczenie magazynowe',
      },
      width: 'Szerokość [m]',
    },
    saveAndGoToSpaces: 'Zapisz dane',
    saveAndExit: 'Zapisz i wyjdź',
    saveListOrPrint: 'Zapisz do PDF lub Drukuj',
    saveRecommendations: 'Zapisz rekomendacje',
    saveSelectionAndClose: 'Zapisz i zamknij',
    selectedColor: 'Wybrany kolor',
    selectItem: 'Wybierz produkt',
    selectedModels: 'Wybrany model',
    selectedProduct: 'Wybrany produkt',
    selectRadiatorItem: 'Wybierz produkt (ITEM)',
    sendRequest: 'Wyślij prośbę',
    sendRequestToCustomer:
      'Wyślij prośbę o stylistyczny wybór modelu do klienta lub projektanta',
    space: {
      name: 'Nazwa pomieszczenia',
      type: 'Typ pomieszczenia',
    },
    startProject: 'Rozpocznij projekt',
    storey: {
      groundCeiling: 'Przyziemie i sufit',
      groundRoof: 'Przyziemie i dach',
      floorCeiling: 'Strop i sufit',
      floorRoof: 'Strop i dach',
    },
    suggestedListPriceValueOnly: 'Jedynie sugerowana wartość cennikowa.',
    summary: 'Summary',
    totalRequiredPower: 'Całkowita moc dla projektu',
    viewItem: 'Zobacz produkt',
    viewSpace: 'Zobacz pomieszczenie',
    uploadImages: 'Załaduj zdjęcia',
    windConditions: {
      strong: 'Mocne wiatry',
      normal: 'Normale wiatry',
      smooth: 'Słabe wiatry',
    },
  },
  navigation: {
    building: 'Budynek',
    spaces: 'Pomieszczenia',
    start: 'Start',
    projectSummary: 'Podsumowanie projektu',
    radiators: 'Grzejniki',
  },
  titles: {
    addNewRadiator: 'Dodaj nowy grzejnik',
    addSpace: 'Edytuj parametry pomieszczenia',
    alternativePowerSource: 'Alternatywne źródła mocy cieplnej',
    app: 'HeatPicker',
    building: 'Budynek',
    buildingLocation: 'Lokalizacja budynku',
    deleteRadiator: 'Usuń grzejnik',
    finalListOfItems: 'Finalna lista produktów',
    glazingSurface: 'Powierzchnia przeszkleń',
    glazingQuality: 'Jakość przeszkleń',
    heatEmission: 'Instalacja grzewcza',
    heatingSupplyParameters: 'Parametry instalacji grzewczej',
    insulationQuality: 'Jakość izolacji',
    productCategories: 'Kategorie grzejników do wyboru',
    projectSummary: 'Podsumowanie projektu',
    roomType: 'typ pomieszczenia',
    spaceDimensions: 'Wymiary pomieszczenia',
    spaceStoreys: 'Rodzaj kondygnacji',
    overheating: 'Przegrzanie',
    spaces: 'Pomieszczenia',
    suggestedListPrice: 'Sugerowana wartość cennikowa wybranych produktów',
    underheating: 'Niedogrzanie',
    windConditions: 'Warunki wiatrów',
  },
}

export default common
