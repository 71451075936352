import React from 'react'
import type { Node } from 'react'

import { Grid, withStyles } from '@material-ui/core'

import RadiatorItem from './items/RadiatorItem'
import AddRadiatorItem from './items/AddRadiatorItem'

import Radiator from '../../../models/Radiator'

type RadiatorsGridProps = {
  classes: Object,
  className: string,
  data: Array<Radiator>,
  onAddItem?: Function,
  onDelete?: Function,
  onDuplicate?: Function,
  onSelect?: Function,
  onSelectItem?: Function,
}

class RadiatorsGrid extends React.Component<RadiatorsGridProps> {
  onAddRadiator() {
    const { onAddItem } = this.props
    if (onAddItem) {
      onAddItem()
    }
  }

  renderItems(): Array<Node> {
    const { data, onDelete, onDuplicate, onSelect, onSelectItem } = this.props
    return data.map((item: Radiator, idx: number): Node => (
      <RadiatorItem
        data={item}
        key={idx}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onSelect={onSelect}
        onSelectItem={onSelectItem}
        title={`#${idx + 1}`}
      />
    ))
  }

  render(): Node {
    const { className } = this.props
    return (
      <Grid className={className} container spacing={6}>
        {this.renderItems()}
        <AddRadiatorItem onClick={(): void => this.onAddRadiator()} />
      </Grid>
    )
  }
}

const styles = {
  container: {},
}

export default withStyles(styles)(RadiatorsGrid)
