import React from 'react'
import type { Node } from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import OutlinedTextField from '../inputs/OutlinedTextField'

import Space from '../../models/Space'

type RoomFormProps = {
  classes: Object,
  disabled: boolean,
  errors: Object,
  onChange: Function,
  space: Space,
  t: Function,
}

class RoomForm extends React.Component<RoomFormProps> {
  static defaultProps = {
    disabled: false,
    errors: {},
  }

  render(): Node {
    const { classes, disabled, errors, onChange, space, t } = this.props

    return (
      <div className={classes.container}>
        <Typography
          className={disabled ? classes.disabledTitle : classes.title}
          variant='h5'>
          {t('titles.spaceDimensions')}
        </Typography>
        <Grid container spacing={6}>
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <OutlinedTextField
              disabled={disabled}
              error={errors.length}
              numeric
              className={classes.textField}
              label={t('labels.room.length')}
              hint={t('hints.roomLength')}
              value={(space && space.length) || 0}
              onChange={(value: number): void => onChange('length', value)}
            />
          </Grid>
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <OutlinedTextField
              disabled={disabled}
              error={errors.width}
              numeric
              className={classes.textField}
              label={t('labels.room.width')}
              hint={t('hints.roomWidth')}
              value={(space && space.width) || 0}
              onChange={(value: number): void => onChange('width', value)}
            />
          </Grid>
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <OutlinedTextField
              disabled={disabled}
              error={errors.height}
              numeric
              className={classes.textField}
              label={t('labels.room.height')}
              hint={t('hints.roomHeight')}
              value={(space && space.height) || 0}
              onChange={(value: number): void => onChange('height', value)}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    marginBottom: 40,
  },
  textField: {
    paddingBottom: 8,
  },
  title: {
    marginBottom: 30,
  },
  disabledTitle: {
    marginBottom: 30,
    color: theme.palette.disabled.main,
  },
})

export default withTranslation('common')(withStyles(styles)(RoomForm))
