import { generateUUID } from '../utils/Uuid'
import RadiatorModel from '../models/RadiatorModel'
import RadiatorItem from '../models/RadiatorItem'

class Radiator {
  name: string
  id: string
  power: number
  heightFrom: number
  heightTo: number
  widthFrom: number
  widthTo: number

  isElectrical: boolean

  categories: Array<string>
  recommendation: Array<RadiatorModel>

  isSelected: boolean
  selectedModelId: string
  model: RadiatorModel | null

  isItemSelected: boolean
  item: RadiatorItem | null

  constructor(data?: Object = {}) {
    this.name = data.name || ''
    this.id = data.id || generateUUID()
    this.power = data.power || 0
    this.heightFrom = data.heightFrom || 0
    this.heightTo = data.heightTo || 0
    this.widthFrom = data.widthFrom || 0
    this.widthTo = data.widthTo || 0

    this.isElectrical = data.isElectrical === true
    this.categories = data.categories || [
      '141',
      '142',
      '143',
      '144',
      '145',
      '146',
    ]

    this.recommendation = (data.recommendation || []).map(
      (item: Object): RadiatorModel => new RadiatorModel(item)
    )

    this.isSelected = data.isSelected === true
    this.selectedModelId = data.selectedModelId || ''

    this.isItemSelected = data.isItemSelected === true
    this.item = data.item ? new RadiatorItem(data.item) : null
  }

  toJSON(): Object {
    const json: Object = {
      name: this.name,
      id: this.id,
      power: this.power,
      heightFrom: this.heightFrom,
      heightTo: this.heightTo,
      widthFrom: this.widthFrom,
      widthTo: this.widthTo,
      isElectrical: this.isElectrical,
      categories: this.categories,
      recommendation: this.recommendation.map((item: RadiatorModel): Object =>
        item.toJSON()
      ),
      isSelected: this.isSelected,
      selectedModelId: this.selectedModelId,
      isItemSelected: this.isItemSelected,
      item: this.item ? this.item.toJSON() : null,
    }

    return json
  }
}

export default Radiator
