import Building from '../models/Building'
import { BuildingActionType } from '../actions/BuildingActions'
import type { BuildingAction } from '../actions/BuildingActions'

type BuildingStateType = {
  current: Building | null,
}

const defaultState: BuildingStateType = {
  current: null,
}

export default (
  state: BuildingStateType = defaultState,
  action: BuildingAction
): BuildingStateType => {
  let newState: BuildingStateType | null = null

  switch (action.type) {
    case BuildingActionType.BUILDING_SET:
      newState = { ...state, current: action.building }
      break
    case BuildingActionType.BUILDING_CLEAR:
      newState = { ...state, current: null }
      break
    case BuildingActionType.BUILDING_UPDATE:
      newState = { ...state, current: action.building }
      break
    default:
      break
  }
  return newState || state
}
