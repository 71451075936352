import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Grid, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'
import SpacesGrid from '../components/collections/grids/SpacesGrid'

import SpacesOverviewCompanionView from './companion/SpacesOverviewCompanionView'

import {
  calculateTotalRadiators,
  calculateTotalRequestedPower,
} from '../selectors/projectSelectors'

import Space from '../models/Space'

type SpacesOverviewViewProps = {
  classes: Object,
  history: Object,
  projectCode: string,
  projectName: string,
  spaces: Array<Space>,
  t: Function,
  totalRadiators: number,
  totalRequiredPower: number,
}

class SpacesOverviewView extends React.Component<SpacesOverviewViewProps> {
  onAddSpace() {
    const { history } = this.props
    history.push('/spaces/create')
  }

  onItemSelected(item: Space) {
    const { history } = this.props
    history.push('/spaces/details?id=' + item.id)
  }

  onProjectSummary() {
    const { history } = this.props
    history.push('/project/summary')
  }

  renderGrid(): Node {
    const { classes, spaces } = this.props
    return (
      <SpacesGrid
        className={classes.spacesCollection}
        data={spaces}
        onAddSpace={(): void => this.onAddSpace()}
        onSelect={(item: Space): void => this.onItemSelected(item)}
      />
    )
  }

  renderInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox>
        <InformationParagraph>
          {t('info.spacesOverview.part1')}
        </InformationParagraph>
      </InformationBox>
    )
  }

  renderContent(): Node {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Typography className={classes.sectionTitle} variant='h3'>
          {t('titles.spaces')}
        </Typography>
        {this.renderGrid()}
        {this.renderInfo()}
      </React.Fragment>
    )
  }
  renderCompanionView(): Node {
    const {
      projectCode,
      projectName,
      totalRadiators,
      totalRequiredPower,
    } = this.props
    return (
      <SpacesOverviewCompanionView
        projectCode={projectCode}
        projectName={projectName}
        radiatorsCount={totalRadiators}
        totalPower={totalRequiredPower}
        onProjectSummary={(): void => this.onProjectSummary()}
      />
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Grid container spacing={8}>
          <Grid item sm={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item sm={4} xs={12}>
            {this.renderCompanionView()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%',
  },
  sectionTitle: {
    paddingBottom: 40,
  },
  spacesCollection: {
    paddingTop: 20,
    paddingBottom: 70,
  },
}

const mapStateToProps = (state: Object): Object => {
  return {
    spaces: state.spaces.all,
    projectCode: state.settings.project.code,
    projectName: state.settings.project.name,
    totalRequiredPower: calculateTotalRequestedPower(state.spaces.all),
    totalRadiators: calculateTotalRadiators(state.spaces.all),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {}
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SpacesOverviewView)
  )
)
