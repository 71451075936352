import React from 'react'
import type { Node } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { Container, CssBaseline, withStyles } from '@material-ui/core'

import Themes from './styles/Themes'
import Routes from './routes/appRoutes'

import ApplicationTopBar from './components/navigation/ApplicationTopBar'

type ApplicationProps = {
  classes: Object,
}

class Application extends React.Component<ApplicationProps> {
  renderRoutes(): Array<Node> {
    return Routes.main.map((item: Object, idx: number): Node => (
      <Route path={item.path} key={idx}>
        {(route: Object): Node => {
          window.scrollTo(0, 0)
          return <item.component {...route} />
        }}
      </Route>
    ))
  }

  renderTopBars(): Array<Node> {
    return Routes.main
      .filter((item: Object): Object => item.topBarComponent != null)
      .map((item: Object, idx: number): Node => (
        <Route path={item.path} key={idx}>
          {(route: Object): Node => <item.topBarComponent {...route} />}
        </Route>
      ))
  }

  render(): Node {
    const { classes } = this.props
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={Themes.main}>
          <CssBaseline>
            <Switch>
              {this.renderTopBars()}
              <Route>
                {(route: Object): Node => <ApplicationTopBar {...route} />}
              </Route>
            </Switch>
            <Container maxWidth='lg' classes={{ root: classes.container }}>
              <Switch>
                {this.renderRoutes()}
                <Route render={(): Node => <Redirect to='/start' />} />
              </Switch>
            </Container>
          </CssBaseline>
        </MuiThemeProvider>
      </BrowserRouter>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    color: theme.palette.dark,
    marginTop: 70,
    marginBottom: 70,
    paddingLeft: 80,
    paddingRight: 80,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
})

export default withStyles(styles)(Application)
