class Building {
  isManual: boolean
  buildingLocation: string
  windConditions: string
  heatEmission: Object
  underheating: number
  overheating: number

  constructor(data?: Object = {}) {
    this.isManual = data.isManual === true
    this.buildingLocation = data.buildingLocation || '28'
    this.windConditions = data.windConditions || '25'
    this.heatEmission = data.heatEmission || {
      flow: '75',
      return: '65',
      room: '20',
    }
    this.underheating = data.underheating || 5
    this.overheating = data.overheating || 15
  }

  toJSON(): Object {
    const json = {
      isManual: this.isManual,
      buildingLocation: this.buildingLocation,
      windConditions: this.windConditions,
      heatEmission: this.heatEmission,
      underheating: this.underheating,
      overheating: this.overheating,
    }
    return json
  }
}

export default Building
