import Api from './Api'

class EmailApi extends Api {
  static resource: string = 'mail'

  static send(email: string, subject: string, message: string): any {
    const payload = {
      token: 'N8L=Bg4mY4BXpsR!=8zQJ_$PtmZ8g#@3Up8Vj87TKEDfyJ?k',
      address: email,
      subject: subject,
      message: message,
    }
    return super.post(EmailApi.resource, payload)
  }
}

export default EmailApi
