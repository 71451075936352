import Api from './Api'
import queryString from 'query-string'

class RadiatorModelsApi extends Api {
  static resource: string = 'model'

  static get(params: Object): any {
    const parsed: string = queryString.stringify(params)
    return super.get(`${RadiatorModelsApi.resource}?${parsed}`)
  }
}

export default RadiatorModelsApi
