import React from 'react'
import type { Node } from 'react'

import { Grid, withStyles } from '@material-ui/core'

import SpaceItem from './items/SpaceItem'
import AddSpaceItem from './items/AddSpaceItem'

import Space from '../../../models/Space'

type SpacesGridProps = {
  classes: Object,
  className: string,
  data: Array<Space>,
  onAddSpace?: Function,
  onSelect?: Function,
}

class SpacesGrid extends React.Component<SpacesGridProps> {
  onSelect(item: Space) {
    const { onSelect } = this.props
    if (onSelect) {
      onSelect(item)
    }
  }

  onAddSpace() {
    const { onAddSpace } = this.props
    if (onAddSpace) {
      onAddSpace()
    }
  }

  renderItems(): Array<Node> {
    const { data } = this.props
    return data.map((item: Space, idx: number): Node => (
      <SpaceItem
        data={item}
        key={idx}
        onSelect={(): void => this.onSelect(item)}
      />
    ))
  }

  render(): Node {
    const { className } = this.props
    return (
      <Grid className={className} container spacing={6}>
        {this.renderItems()}
        <AddSpaceItem onClick={(): void => this.onAddSpace()} />
      </Grid>
    )
  }
}

const styles = {
  container: {},
}

export default withStyles(styles)(SpacesGrid)
