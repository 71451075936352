import React from 'react'
import type { Node } from 'react'

import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames'

import InformationBox from '../info/InformationBox'
import InformationParagraph from '../info/InformationParagraph'
import RadiatorConnectionsSelect from '../inputs/RadiatorConnectionsSelect'

type ConnectionsFormProps = {
  classes: Object,
  t: Function,
}

type ConnectionsFormState = {
  isDisabled: boolean,
}

class ConnectionsForm extends React.Component<
  ConnectionsFormProps,
  ConnectionsFormState
> {
  state = {
    isDisabled: false,
  }

  onToggleForm(isChecked: boolean) {
    this.setState({ isDisabled: !isChecked })
  }

  renderSwitch(): Node {
    const { classes, t } = this.props
    const { isDisabled } = this.state
    return (
      <React.Fragment>
        <FormControlLabel
          classes={{
            label: classes.switchLabel,
            root: classes.switchContainer,
          }}
          control={
            <Switch
              color='primary'
              checked={!isDisabled}
              onChange={(event: Object): void =>
                this.onToggleForm(event.target.checked)
              }
            />
          }
          label={t('info.limitConnections')}
        />
      </React.Fragment>
    )
  }

  renderWaterInSection(): Node {
    const { classes, t } = this.props
    const { isDisabled } = this.state

    const titleClasses = classNames(
      classes.sectionTitle,
      isDisabled ? classes.disabledTitle : null
    )
    return (
      <React.Fragment>
        <Typography className={titleClasses} variant='h5'>
          {t('titles.waterIn')}
        </Typography>
        <Typography className={isDisabled ? classes.disabledTitle : null}>
          {t('info.waterIn')}
        </Typography>
        <div className={classes.sectionContainer}>
          <RadiatorConnectionsSelect
            type='in'
            disabled={isDisabled}
            className={classes.connectionsInput}
          />
        </div>
      </React.Fragment>
    )
  }

  renderWaterOutSection(): Node {
    const { classes, t } = this.props
    const { isDisabled } = this.state

    const titleClasses = classNames(
      classes.sectionTitle,
      isDisabled ? classes.disabledTitle : null
    )
    return (
      <React.Fragment>
        <Typography className={titleClasses} variant='h5'>
          {t('titles.waterOut')}
        </Typography>
        <Typography className={isDisabled ? classes.disabledTitle : null}>
          {t('info.waterOut')}
        </Typography>
        <div className={classes.sectionContainer}>
          <RadiatorConnectionsSelect
            type='out'
            disabled={isDisabled}
            className={classes.connectionsInput}
          />
        </div>
      </React.Fragment>
    )
  }

  renderInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox>
        <InformationParagraph>
          {t('info.limitedConnections.part1')}
        </InformationParagraph>
        <InformationParagraph>
          {t('info.limitedConnections.part2')}
        </InformationParagraph>
      </InformationBox>
    )
  }

  render(): Node {
    const { classes, t } = this.props

    return (
      <div>
        <Typography className={classes.title} variant='h5'>
          {t('titles.limitedConnections')}
        </Typography>
        {this.renderSwitch()}
        <Grid className={classes.grid} container spacing={6}>
          <Grid item md={6} sm={12}>
            {this.renderWaterInSection()}
          </Grid>
          <Grid item md={6} sm={12}>
            {this.renderWaterOutSection()}
          </Grid>
        </Grid>
        {this.renderInfo()}
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {},
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  switchContainer: {
    marginBottom: 30,
    paddingTop: 10,
  },
  switchLabel: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  title: {
    marginTop: 60,
    marginBottom: 20,
  },
  sectionTitle: {
    marginBottom: 30,
  },
  disabledTitle: {
    color: theme.palette.disabled.main,
  },
  grid: {
    marginBottom: 80,
  },
  connectionsInput: {
    marginTop: 30,
    marginLeft: 10,
    marginRight: 10,
  },
})

export default withTranslation('common')(withStyles(styles)(ConnectionsForm))
