import Building from '../models/Building'

import { updateProject } from './ProjectActions'

const BuildingActionType = {
  BUILDING_SET: 'BUILDING_SET',
  BUILDING_CLEAR: 'BUILDING_CLEAR',
  BUILDING_UPDATE: 'BUILDING_UPDATE',
}

type AddBuildingAction = {|
  type: typeof BuildingActionType.BUILDING_SET,
  building: Building,
|}

type ClearBuildingAction = {|
  type: typeof BuildingActionType.BUILDING_CLEAR,
|}

type UpdateBuildingAction = {|
  type: typeof BuildingActionType.BUILDING_UPDATE,
  building: Building,
|}

export type BuildingAction =
  | AddBuildingAction
  | ClearBuildingAction
  | UpdateBuildingAction

const setBuilding = (building: Building): Function => {
  return (dispatch: Function) => {
    dispatch({
      type: BuildingActionType.BUILDING_SET,
      building: building,
    })
    dispatch(updateProject())
  }
}

const clearBuilding = (): BuildingAction => {
  return {
    type: BuildingActionType.BUILDING_CLEAR,
  }
}

const updateBuilding = (building: Building): Function => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: BuildingActionType.BUILDING_UPDATE,
      building: building,
    })
    dispatch(updateProject())
  }
}

export { BuildingActionType, clearBuilding, setBuilding, updateBuilding }
