import React from 'react'
import type { Node } from 'react'

import { Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

type CompanionContentContainerProps = {
  children: Array<Node>,
  classes: Object,
  t: Function,
}

class CompanionContentContainer extends React.Component<CompanionContentContainerProps> {
  render(): Node {
    const { children, classes, t } = this.props
    return (
      <div className={classes.contentContainer}>
        {children}
        <div className={classes.logoContainer}>
          <div style={{ textAlign: 'center' }}>
            <img src='/assets/logo_light.png' alt='logo' />
          </div>
          <Typography className={classes.logoLabel} variant='overline'>
            {t('labels.purmoSelector')}
          </Typography>
        </div>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    paddingLeft: 25,
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
  },
  logoContainer: {
    marginTop: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.secondary.light,
    textAlign: 'center',
  },
})

export default withTranslation('common')(
  withStyles(styles)(CompanionContentContainer)
)
