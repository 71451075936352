import Api from './Api'

class SpaceApi extends Api {
  static resource = 'space'

  static calculate(data: Object): any {
    return super
      .post(`${SpaceApi.resource}/calculate`, data)
      .then((response: any): any => response.json())
      .then((data: any): number => {
        console.log(data)
        return data ? data.power : 0
      })
  }
}

export default SpaceApi
