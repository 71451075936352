import EmailApi from '../api/EmailApi'

const EmailActionType = {
  EMAIL_SET_MAIL_SENT: 'EMAIL_SET_MAIL_SENT',
}

type SetMailSent = {|
  type: typeof EmailActionType.EMAIL_SET_MAIL_SENT,
  status: boolean,
|}

export type EmailAction = SetMailSent

const setMailSent = (status: boolean): EmailAction => {
  return {
    type: EmailActionType.EMAIL_SET_MAIL_SENT,
    status,
  }
}

const clearMailSent = (): EmailAction => {
  return {
    type: EmailActionType.EMAIL_SET_MAIL_SENT,
    status: false,
  }
}

const sendMail = (
  email: string,
  subject: string,
  message: string
): Function => {
  return (dispatch: Function): Function => {
    EmailApi.send(email, subject, message)
      .then(() => {
        dispatch(setMailSent(true))
      })
      .catch((error: Error) => {
        console.log(error)
      })
  }
}

export { EmailActionType, clearMailSent, sendMail }
