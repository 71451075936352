const templates = {
  actions: {
    goToTheProject: 'Przejdź do projektu',
    letsStartTheSelection: 'Rozpocznij wybór',
  },
  choiceRequest: 'Prośba o wybór modeli grzejników',
  info: {
    congratulations:
      'Gratulacje. Nowy project został pomyślnie założony w narzędziu HeatPicker Online Radiator Selector.',
    dontWorryGetInspired:
      'Przed wyborem modeli grzejników, można skorzystać z sekcji inspiracji stylistyki i design’u modeli naszych grzejników.',
    installerHasSetUpTheProject:
      'Instalator zainicjował project instalacji grzewczej i wprowadził parametry i wymagania dla Twoich grzejników.',
    projectName: 'Nazwa projektu',
    seeTheProjectNames:
      'Zobacz nazwy projektów oraz ich kody identyfikacyjne powiązane z Twoim adresem e-mail',
    selectionHasBeenCompleted:
      'Osoba, która otrzymała od Ciebie prośbę o stylistyczny i estetyczny wybór modeli grzejników dokonała wyboru.',
    thankYou: 'Dziękujemy za używanie HeatPicker Online Radiator Selector.',
    youHaveBeenRequested:
      'Twój instalator systemu grzewczego poprosił Cię o dokonanie wyboru stylistycznego modeli grzejników, które najbardziej Ci odpowiadają do Twoich wnętrz.',
  },
  modelsHasBeenSelected: 'Modele zostały wybrane',
  newProjectHasBeenSetUp: 'Nowy projekt został założony',
  notSpam: 'TO NIE JEST “SPAM”',
  yourProjectCodes: 'Twoje kody projektów',
}

export default templates
