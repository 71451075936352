import React from 'react'
import type { Node } from 'react'

import { Button, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames'

import LabelWithBackground from '../../components/labels/LabelWithBackground'
import CompanionContentContainer from '../../components/miscs/CompanionContentContainer'

import { getRoomTypeForId } from '../../utils/CalcProperties'

import Space from '../../models/Space'

type CreateRadiatorCompanionViewProps = {
  classes: Object,
  space: Space,
  onSave: Function,
  radiatorName: string,
  radiatorPower: number,
  t: Function,
  totalPower: number,
}

class CreateRadiatorCompanionView extends React.Component<CreateRadiatorCompanionViewProps> {
  onSave() {
    const { onSave } = this.props
    if (onSave) {
      onSave()
    }
  }

  getRoomLabel(): string {
    const { space, t } = this.props
    if (space && space.type && space.type.localeCompare('1') === 0) {
      return `${t('labels.other')} (${space.temperature}°C)`
    }
    return getRoomTypeForId(space && space.type, t)
  }

  renderButtons(): Node {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Button
          disabled
          className={classes.button}
          variant='outlined'
          color='inherit'>
          {t('labels.uploadImages')}
        </Button>
        <Button className={classes.button} variant='outlined' color='inherit'>
          {t('labels.cancelAndExit')}
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={(): void => this.onSave()}>
          {t('labels.saveAndExit')}
        </Button>
      </React.Fragment>
    )
  }

  renderInfoSection(): Node {
    const { classes, radiatorName, radiatorPower, t, totalPower } = this.props
    return (
      <React.Fragment>
        <div className={classes.valueContainer}>
          <div>
            <Typography variant='caption'>
              {t('labels.radiator.allPower')}
            </Typography>
            <Typography
              className={classNames(classes.valueLabel, classes.grayItem)}
              variant='h5'>
              {`${totalPower} W`}
            </Typography>
          </div>
          <div>
            <Typography variant='caption'>
              {t('labels.radiator.selectedPower')}
            </Typography>
            <Typography
              className={classes.valueLabel}
              variant='h5'
              color='primary'>
              {`${radiatorPower} W`}
            </Typography>
          </div>
        </div>
        <Typography variant='caption'>{t('labels.editedRadiator')}</Typography>
        <Typography className={classes.label} variant='h3'>
          {radiatorName}
        </Typography>
      </React.Fragment>
    )
  }

  renderImagesSection(): Node {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Typography variant='caption'>{t('labels.images')}</Typography>
        <div className={classes.imagesContainer}>
          <img
            className={classes.image}
            src='/assets/AdobeStock_258223993.png'
            alt='image1'
          />
          <img
            className={classes.image}
            src='/assets/AdobeStock_266057422.png'
            alt='image2'
          />
          <img
            className={classes.image}
            src='/assets/AdobeStock_274751085.png'
            alt='image3'
          />
        </div>
      </React.Fragment>
    )
  }

  render(): Node {
    const { classes, space, t } = this.props

    return (
      <div className={classes.container}>
        <LabelWithBackground label={t('labels.summary')} />
        <CompanionContentContainer>
          <Typography variant='caption'>{t('labels.space.name')}</Typography>
          <Typography className={classes.label} variant='h3'>
            {space && space.name}
          </Typography>
          <Typography variant='caption'>{t('labels.space.type')}</Typography>
          <Typography className={classes.label} variant='h5' color='secondary'>
            {this.getRoomLabel()}
          </Typography>
          {this.renderInfoSection()}
          {this.renderImagesSection()}
          {this.renderButtons()}
        </CompanionContentContainer>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    marginTop: 70,
    position: 'sticky',
    top: 0,
  },
  imagesContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
  },
  image: {
    width: '30%',
    height: '30%',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 100,
      margin: 5,
    },
    [theme.breakpoints.down('xs')]: {
      width: '30%',
      height: '30%',
    },
  },
  button: {
    marginTop: 30,
    width: '100%',
  },
  label: {
    paddingTop: 5,
    paddingBottom: 20,
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: 'space-between',
  },
  grayItem: {
    color: theme.palette.secondary.light,
  },
  valueLabel: {
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
})

export default withTranslation('common')(
  withStyles(styles)(CreateRadiatorCompanionView)
)
