import React from 'react'
import type { Node } from 'react'

import classNames from 'classnames'

import { Button, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import LabelWithBackground from '../../components/labels/LabelWithBackground'
import CompanionContentContainer from '../../components/miscs/CompanionContentContainer'

type ConfigureBuildingCompanionViewProps = {
  classes: Object,
  className: string,
  isManual: boolean,
  onSave?: Function,
  projectName?: string,
  t: Function,
}

class ConfigureBuildingCompanionView extends React.Component<ConfigureBuildingCompanionViewProps> {
  static defaultProps = {
    onSave: () => {},
    projectName: '',
  }

  onSave() {
    const { onSave } = this.props
    if (onSave) {
      onSave()
    }
  }

  renderContent(): Node {
    const { classes, isManual, projectName, t } = this.props
    return (
      <div>
        <LabelWithBackground label={t('labels.summary')} />
        <CompanionContentContainer>
          <Typography variant='caption'>{t('labels.projectName')}</Typography>
          <Typography className={classes.label} variant='h3'>
            {projectName}
          </Typography>
          <Typography variant='caption'>
            {t('labels.powerRequirements')}
          </Typography>
          <Typography className={classes.label} variant='h3'>
            {t(isManual ? 'labels.manualPowerInput' : 'labels.calculated')}
          </Typography>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={(): void => this.onSave()}>
            {t('labels.saveAndGoToSpaces')}
          </Button>
        </CompanionContentContainer>
      </div>
    )
  }

  render(): Node {
    const { classes, className } = this.props
    const containerClasses = classNames(className, classes.container)
    return <div className={containerClasses}>{this.renderContent()}</div>
  }
}

const styles = (theme: Object): Object => ({
  container: {
    marginTop: 70,
    position: 'sticky',
    top: 0,
  },
  button: {
    width: '100%',
    marginTop: 20,
  },
  contentContainer: {
    marginTop: 25,
    paddingLeft: 25,
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
  },
  label: {
    paddingTop: 5,
    paddingBottom: 20,
  },
})

export default withTranslation('common')(
  withStyles(styles)(ConfigureBuildingCompanionView)
)
