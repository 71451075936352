import { generateUUID } from '../utils/Uuid'

import Radiator from './Radiator'
class Space {
  name: string
  id: string
  type: string
  temperature: number | string
  enabled: boolean

  isCalculated: boolean
  requestedPower: number
  alternativePower: number

  glazingSurface: number | string
  glazingQuality: number | string
  insulationQuality: number | string
  storeys: number | string

  width: string
  height: string
  length: string

  radiators: Array<Radiator>

  constructor(data?: Object = {}) {
    this.name = data.name || ''
    this.id = data.id || generateUUID()
    this.type = data.type || 0
    this.temperature = data.temperature || 0
    this.enabled = data.enabled == null ? true : data.enabled === true

    this.isCalculated = data.isCalculated === true
    this.requestedPower = data.requestedPower || 0
    this.alternativePower = data.alternativePower || 0

    this.glazingSurface = data.glazingSurface || '37'
    this.glazingQuality = data.glazingQuality || '37'
    this.insulationQuality = data.insulationQuality || '37'
    this.storeys = data.storeys || '25'

    this.width = data.width || 0
    this.height = data.height || 0
    this.length = data.length || 0

    this.radiators = data.radiators
      ? data.radiators.map((item: Object): Radiator => new Radiator(item))
      : []
  }

  toJSON(): Object {
    const json = {
      name: this.name,
      id: this.id,
      type: this.type,
      temperature: this.temperature,
      enabled: this.enabled,
      isCalculated: this.isCalculated,
      requestedPower: parseInt(this.requestedPower),
      alternativePower: parseInt(this.alternativePower),
      glazingSurface: this.glazingSurface,
      glazingQuality: this.glazingQuality,
      insulationQuality: this.insulationQuality,
      storeys: this.storeys,
      width: parseInt(this.width),
      height: parseInt(this.height),
      length: parseInt(this.length),
      radiators: this.radiators.map((item: Radiator): Object => item.toJSON()),
    }

    return json
  }

  get radiatorsCount(): number {
    return 0
  }

  get totalPower(): number {
    return this.requestedPower + this.alternativePower
  }
}

export default Space
