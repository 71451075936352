import RadiatorModel from '../models/RadiatorModel'
import Radiator from '../models/Radiator'
import Space from '../models/Space'

import RadiatorModelsApi from '../api/RadiatorModelsApi'

import { updateSpace } from './SpaceActions'

const RadiatorActionType = {
  RADIATOR_SET_COLLECTION: 'RADIATOR_SET',
  RADIATOR_CLEAR_COLLECTION: 'RADIATOR_CLEAR',
  RADIATOR_ADD: 'RADIATOR_ADD',
  RADIATOR_UPDATE: 'RADIATOR_UPDATE',
  RADIATOR_REMOVE: 'RADIATOR_REMOVE',
  RADIATOR_SET_CURRENT: 'RADIATOR_SET_CURRENT',
  RADIATOR_CLEAR_CURRENT: 'RADIATOR_CLEAR_CURRENT',
}

type SetRadiatorsCollection = {|
  type: typeof RadiatorActionType.RADIATOR_SET_COLLECTION,
  radiators: Array<Radiator>,
|}

type ClearRadiatorsCollection = {|
  type: typeof RadiatorActionType.RADIATOR_CLEAR_COLLECTION,
|}

type AddRadiatorAction = {|
  type: typeof RadiatorActionType.RADIATOR_ADD,
  radiator: Radiator,
|}

type RemoveRadiatorAction = {|
  type: typeof RadiatorActionType.RADIATOR_REMOVE,
  radiator: Radiator,
|}

type UpdateRadiatorAction = {|
  type: typeof RadiatorActionType.RADIATOR_UPDATE,
  radiator: Radiator,
|}

type SetCurrentRadiatorAction = {|
  type: typeof RadiatorActionType.RADIATOR_SET_CURRENT,
  radiator: Radiator,
|}

type ClearRadiatorAction = {|
  type: typeof RadiatorActionType.RADIATOR_CLEAR_CURRENT,
|}

export type RadiatorAction =
  | SetRadiatorsCollection
  | ClearRadiatorsCollection
  | AddRadiatorAction
  | RemoveRadiatorAction
  | UpdateRadiatorAction
  | SetCurrentRadiatorAction
  | ClearRadiatorAction

const updateCollections = (): Function => {
  return (dispatch: Function, getState: Function) => {
    const state = getState()
    let space: Space = new Space(state.spaces.current || {})
    space.radiators = state.radiators.all
    dispatch(updateSpace(space))
  }
}

const setRadiatorsCollection = (radiators: Array<Radiator>): RadiatorAction => {
  return {
    type: RadiatorActionType.RADIATOR_SET_COLLECTION,
    radiators: radiators,
  }
}

const clearRadiatorsCollection = (): RadiatorAction => {
  return {
    type: RadiatorActionType.RADIATOR_CLEAR_COLLECTION,
  }
}

const addRadiatorToCollection = (radiator: Radiator): any => {
  return {
    type: RadiatorActionType.RADIATOR_ADD,
    radiator: radiator,
  }
}

const addRadiator = (radiator: Radiator): Function => {
  return (dispatch: Function, getState: Function) => {
    dispatch(addRadiatorToCollection(radiator))
    dispatch(updateCollections())
  }
}

const removeRadiatorFromCollection = (radiator: Radiator): RadiatorAction => {
  return {
    type: RadiatorActionType.RADIATOR_REMOVE,
    radiator: radiator,
  }
}

const removeRadiator = (radiator: Radiator): Function => {
  return (dispatch: Function, getState: Function) => {
    dispatch(removeRadiatorFromCollection(radiator))
    dispatch(updateCollections())
  }
}

const updateRadiatorInCollection = (radiator: Radiator): RadiatorAction => {
  return {
    type: RadiatorActionType.RADIATOR_UPDATE,
    radiator: radiator,
  }
}

const updateRadiator = (radiator: Radiator): Function => {
  return (dispatch: Function, getState: Function) => {
    dispatch(updateRadiatorInCollection(radiator))
    dispatch(updateCollections())
  }
}

const setCurrentRadiator = (radiator: Radiator): RadiatorAction => {
  return {
    type: RadiatorActionType.RADIATOR_SET_CURRENT,
    radiator: radiator,
  }
}

const clearCurrentRadiator = (): RadiatorAction => {
  return {
    type: RadiatorActionType.RADIATOR_CLEAR_CURRENT,
  }
}

const getRadiatorModelForRadiator = (radiator: Radiator): any => {
  return (dispatch: Function) => {
    RadiatorModelsApi.get({ productId: radiator.selectedModelId }).then(
      (data: any): any => {
        radiator.model = new RadiatorModel(data.models[0])
        dispatch(updateRadiatorInCollection(radiator))
      }
    )
  }
}

export {
  RadiatorActionType,
  setRadiatorsCollection,
  clearRadiatorsCollection,
  addRadiator,
  removeRadiator,
  updateRadiator,
  setCurrentRadiator,
  clearCurrentRadiator,
  getRadiatorModelForRadiator,
}
