import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'

import SingleSlider from '../components/inputs/SingleSlider'
import RadioButtonsGroup from '../components/inputs/RadioButtonsGroup'
import OutlinedTextField from '../components/inputs/OutlinedTextField'

import ConfigureBuildingCompanionView from './companion/ConfigureBuilingCompanionView'

import Building from '../models/Building'
import { updateBuilding } from '../actions/BuildingActions'

import {
  getBuildingLocations,
  getWindConditions,
} from '../utils/CalcProperties'

type ConfigureBuildingViewProps = {
  building?: Building | null,
  classes: Object,
  history: Object,
  projectName: string,
  t: Function,
  updateBuilding: Function,
}

type ConfigureBuildingViewState = {
  building: Building | null,
}

class ConfigureBuildingView extends React.Component<
  ConfigureBuildingViewProps,
  ConfigureBuildingViewState
> {
  state = {
    building: null,
  }

  sliderMarks = [{ value: 0, label: '0%' }, { value: 30, label: '30%' }]

  componentDidMount() {
    const { building } = this.props

    this.setState({ building: building || new Building() })
  }

  onSave() {
    const { history, updateBuilding } = this.props
    const { building } = this.state

    updateBuilding(building)
    history.push('/spaces/overview')
  }

  onChangeBuildingProperty(property: string, value: string | number | boolean) {
    const { building } = this.state

    this.setState({
      building: new Building({ ...building, [property]: value }),
    })
  }

  getLocationItems(): Array<Object> {
    const { t } = this.props
    return getBuildingLocations(t)
  }

  getWindItems(): Array<Object> {
    const { t } = this.props
    return getWindConditions(t)
  }

  sliderLabelTransformation(value: string): string {
    return `${value}%`
  }

  renderParametersInfo(): Node {
    const { classes, t } = this.props
    const { building } = this.state

    return (
      <InformationBox className={classes.parametersInfo}>
        <InformationParagraph>
          {t(
            building && building.isManual
              ? 'info.parameters.manually'
              : 'info.parameters.automatically'
          )}
        </InformationParagraph>
      </InformationBox>
    )
  }

  renderEmissionInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox>
        <InformationParagraph>{t('info.emission.part1')}</InformationParagraph>
        <InformationParagraph>{t('info.emission.part2')}</InformationParagraph>
      </InformationBox>
    )
  }

  renderBuildingParameters(): Node {
    const { classes, t } = this.props
    const { building } = this.state

    return (
      <Grid container spacing={8}>
        <Grid item md={6} xs={12}>
          <Typography
            className={
              building && building.isManual ? classes.disabledTitle : null
            }
            variant='h5'>
            {t('titles.buildingLocation')}
          </Typography>
          <RadioButtonsGroup
            disabled={building && building.isManual}
            items={this.getLocationItems()}
            onChange={(value: number): void =>
              this.onChangeBuildingProperty('buildingLocation', value)
            }
            value={building ? [building.buildingLocation] : []}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            className={
              building && building.isManual ? classes.disabledTitle : null
            }
            variant='h5'>
            {t('titles.windConditions')}
          </Typography>
          <RadioButtonsGroup
            disabled={building && building.isManual}
            items={this.getWindItems()}
            onChange={(value: number): void =>
              this.onChangeBuildingProperty('windConditions', value)
            }
            value={building ? [building.windConditions] : []}
          />
        </Grid>
      </Grid>
    )
  }

  renderHeatingSliders(): Node {
    const { classes, t } = this.props
    const { building } = this.state
    return (
      <Grid
        className={classes.heatEmissionSliderContainer}
        container
        spacing={8}>
        <Grid item md={6} xs={12}>
          <Typography className={classes.sectionTitle} variant='h5'>
            {t('titles.underheating')}
          </Typography>
          <SingleSlider
            defaultValue={15}
            marks={this.sliderMarks}
            max={30}
            labelTransformation={(value: string): string =>
              this.sliderLabelTransformation(value)
            }
            onChange={(value: number): void =>
              this.onChangeBuildingProperty('underheating', value)
            }
            value={building ? building.underheating : 0}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.sectionTitle} variant='h5'>
            {t('titles.overheating')}
          </Typography>
          <SingleSlider
            defaultValue={15}
            marks={this.sliderMarks}
            max={30}
            labelTransformation={(value: string): string =>
              this.sliderLabelTransformation(value)
            }
            onChange={(value: number): void =>
              this.onChangeBuildingProperty('overheating', value)
            }
            value={building ? building.overheating : 0}
          />
        </Grid>
      </Grid>
    )
  }

  renderHeatingParameters(): Node {
    const { classes, t } = this.props
    const { building } = this.state
    return (
      <React.Fragment>
        <Typography variant='h5'>{t('titles.heatEmission')}</Typography>
        <Grid className={classes.heatEmissionContainer} container spacing={8}>
          <Grid item md={6} xs={12}>
            <Typography variant='subtitle1' style={{ marginTop: 50 }}>
              {t('labels.waterInTemperature')}
            </Typography>
            <OutlinedTextField
              numeric
              hint={t('hints.waterInflowTemperature')}
              value={building && building.heatEmission.flow.toString()}
              onChange={(value: number) => {
                if (!building) {
                  return
                }
                this.onChangeBuildingProperty('heatEmission', {
                  ...building.heatEmission,
                  flow: value,
                })
              }}
            />
            <Typography variant='subtitle1'>
              {t('labels.waterOutTemperature')}
            </Typography>
            <OutlinedTextField
              numeric
              hint={t('hints.waterOutflowTemperature')}
              value={(building && building.heatEmission.return) || 0}
              onChange={(value: number) => {
                if (!building) {
                  return
                }
                this.onChangeBuildingProperty('heatEmission', {
                  ...building.heatEmission,
                  return: value,
                })
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            {this.renderEmissionInfo()}
          </Grid>
        </Grid>
        {this.renderHeatingSliders()}
      </React.Fragment>
    )
  }

  renderSwitch(): Node {
    const { classes, t } = this.props
    const { building } = this.state
    return (
      <div className={classes.knowledgeSwitch}>
        <FormControlLabel
          classes={{ label: classes.knowledgeSwitchLabel }}
          control={
            <Switch
              color='primary'
              checked={(building && building.isManual) || false}
              onChange={(event: Object): void =>
                this.onChangeBuildingProperty('isManual', event.target.checked)
              }
            />
          }
          label={t('info.requiredPower')}
        />
      </div>
    )
  }

  renderContent(): Node {
    const { classes, t } = this.props

    return (
      <React.Fragment>
        <Typography className={classes.sectionTitle} variant='h3'>
          {t('titles.building')}
        </Typography>
        {this.renderHeatingParameters()}
        {this.renderSwitch()}
        {this.renderBuildingParameters()}
        {this.renderParametersInfo()}
      </React.Fragment>
    )
  }

  renderCompanionView(): Node {
    const { classes, projectName } = this.props
    const { building } = this.state
    return (
      <ConfigureBuildingCompanionView
        isManual={building && building.isManual}
        className={classes.sideContainer}
        onSave={(): void => this.onSave()}
        projectName={projectName}
      />
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Grid container spacing={8}>
          <Grid item sm={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item sm={4} xs={12}>
            {this.renderCompanionView()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    width: '100%',
  },
  disabledTitle: {
    color: theme.palette.disabled.main,
  },
  sideContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  heatEmissionContainer: {
    marginBottom: 40,
  },
  heatEmissionSliderContainer: {
    marginBottom: 50,
  },
  sectionTitle: {
    paddingBottom: 40,
  },
  knowledgeSwitch: {
    paddingTop: 10,
    paddingBottom: 60,
  },
  knowledgeSwitchLabel: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  parametersInfo: {
    marginTop: 100,
    marginBottom: 70,
  },
})

const mapStateToProps = (state: Object): Object => {
  return {
    building: state.building.current,
    projectName: state.settings.project.name,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = { updateBuilding }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ConfigureBuildingView)
  )
)
