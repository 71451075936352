import React from 'react'
import type { Node } from 'react'

import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import RoundedButton from '../buttons/RoundedButton'

type ApplicationTopBarProps = {
  classes: Object,
  history: Object,
  location: Object,
  t: Function,
}

class ApplicationTopBar extends React.Component<ApplicationTopBarProps> {
  paths = ['start', 'building', 'spaces', 'radiators', 'project']
  urls = ['/start', '/building', '/spaces/overview', null]
  labels = [
    'navigation.start',
    'navigation.building',
    'navigation.spaces',
    'navigation.radiators',
    'navigation.projectSummary',
  ]

  onClickItem(index: number) {
    const { history } = this.props
    if (this.urls[index]) {
      history.push(this.urls[index])
    }
  }

  renderStepper(): Array<Node> {
    const { location, t } = this.props
    const currentStep: number = this.paths.indexOf(
      location.pathname.split('/')[1]
    )

    return this.labels.map((item: string, idx: number): Node => {
      let state: string = 'active'
      if (idx !== currentStep) {
        state = idx < currentStep ? 'done' : 'inactive'
        if (idx === 3) {
          state = 'inactive'
        }
      }
      return (
        <RoundedButton
          state={state}
          key={idx}
          onClick={() => {
            this.onClickItem(idx)
          }}>
          {t(item)}
        </RoundedButton>
      )
    })
  }

  render(): Node {
    const { classes } = this.props

    return <div className={classes.container}>{this.renderStepper()}</div>
  }
}

const styles = (theme: Object): Object => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    minHeight: 82,
  },
})

export default withTranslation('common')(withStyles(styles)(ApplicationTopBar))
