import Building from '../models/Building'
import Radiator from '../models/Radiator'
import RadiatorModel from '../models/RadiatorModel'
import RadiatorModelsApi from '../api/RadiatorModelsApi'

const RadiatorModelActionType = {
  RADIATOR_MODEL_SET_CATEGORY: 'RADIATOR_MODEL_SET_CATEGORY',
  RADIATOR_MODEL_CLEAR_CATEGORIES: 'RADIATOR_MODEL_CLEAR_CATEGORIES',
}

type SetRadiatorCategoryModels = {|
  type: typeof RadiatorModelActionType.RADIATOR_MODEL_SET_CATEGORY,
  models: Array<RadiatorModel>,
  category: string,
|}

type ClearRadiatorsCategories = {|
  type: typeof RadiatorModelActionType.RADIATOR_MODEL_CLEAR_CATEGORIES,
|}

export type RadiatorModelAction =
  | SetRadiatorCategoryModels
  | ClearRadiatorsCategories

const getModelsByCategoryForRadiator = (
  categoryId: string,
  radiator: Radiator
): Function => {
  return (dispatch: Function, getState: Function) => {
    const building: Building = getState().building.current

    if (building == null || radiator == null) {
      return
    }
    const computedParams: Object = {
      categoryId: categoryId,
      minHeight: radiator.heightFrom,
      maxHeight: radiator.heightTo,
      minLength: radiator.widthFrom,
      maxLength: radiator.widthTo,
      power: radiator.power,
      underheating: building.underheating,
      overheating: building.overheating,
      inTemp: '75', //building.heatEmission.flow,
      outTemp: '65', //building.heatEmission.return,
      roomTemp: building.heatEmission.room,
    }
    RadiatorModelsApi.get(computedParams).then((data: Object) => {
      dispatch(
        setRadiatorCategoryModels(
          data.models.map(
            (item: Object): RadiatorModel => new RadiatorModel(item)
          ),
          categoryId
        )
      )
    })
  }
}

const setRadiatorCategoryModels = (
  radiatorModels: Array<RadiatorModel>,
  category: string
): RadiatorModelAction => {
  return {
    type: RadiatorModelActionType.RADIATOR_MODEL_SET_CATEGORY,
    models: radiatorModels,
    category: category,
  }
}

const clearRadiatorsCategoriesModels = (): RadiatorModelAction => {
  return {
    type: RadiatorModelActionType.RADIATOR_MODEL_CLEAR_CATEGORIES,
  }
}

export {
  RadiatorModelActionType,
  getModelsByCategoryForRadiator,
  clearRadiatorsCategoriesModels,
}
