import React from 'react'
import type { Node } from 'react'

import classNames from 'classnames'

import {
  Button,
  Divider,
  MobileStepper,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import SwipeableViews from 'react-swipeable-views'

import GridItemBase from './base/GridItemBase'

import Radiator from '../../../../models/Radiator'

type RadiatorItemProps = {
  classes: Object,
  data: Radiator,
  onDelete?: Function,
  onDuplicate?: Function,
  onSelect?: Function,
  onSelectItem?: Function,
  t: Function,
  title: string,
}

type RadiatorItemState = {
  contentCardIndex: number,
}

class RadiatorItem extends React.Component<
  RadiatorItemProps,
  RadiatorItemState
> {
  state = {
    contentCardIndex: 0,
  }

  onSwipeableClick() {
    const { data } = this.props
    const { contentCardIndex } = this.state
    if (data.isSelected) {
      this.setState({ contentCardIndex: contentCardIndex === 1 ? 0 : 1 })
    }
  }

  onSelect() {
    const { data, onSelect } = this.props
    if (onSelect) {
      onSelect(data)
    }
  }

  onSelectItem() {
    const { data, onSelectItem } = this.props
    if (onSelectItem) {
      onSelectItem(data)
    }
  }

  onDelete() {
    const { data, onDelete } = this.props
    if (onDelete) {
      onDelete(data)
    }
  }

  onDuplicate() {
    const { data, onDuplicate } = this.props
    if (onDuplicate) {
      onDuplicate(data)
    }
  }

  onChangeContentCard(index: number) {
    this.setState({ contentCardIndex: index })
  }

  renderQuestionMarkIcon(): Node {
    const { classes } = this.props
    return (
      <div className={classes.questionMarkContainer}>
        <Typography style={{ color: 'white', fontSize: 58, fontWeight: 800 }}>
          ?
        </Typography>
      </div>
    )
  }

  renderModelInfo(): Node {
    const { classes, data } = this.props
    return (
      <div
        className={classes.modelContainer}
        key={'model'}
        onClick={(): void => this.onSwipeableClick()}>
        <div className={classes.thumbnailContainer}>
          <img
            className={classes.thumbnail}
            src={data.model ? data.model.thumbnail : null}
            alt='model_thumbnail'
          />
          {data.isItemSelected ? null : this.renderQuestionMarkIcon()}
        </div>

        <Typography
          className={classes.modelLabel}
          variant='h6'
          color='secondary'>
          {data.model ? data.model.name : ''}
        </Typography>
      </div>
    )
  }

  renderInfoValues(): Node {
    const { classes, data, t } = this.props

    return (
      <div
        className={classes.infoContainer}
        key={'values'}
        onClick={(): void => this.onSwipeableClick()}>
        <div className={classes.infoItemContainer}>
          <div className={classes.infoItem}>
            <Typography
              className={classes.label}
              variant='h5'>{`${data.power} W`}</Typography>
          </div>
          <Divider />
          <Typography variant='caption'>
            {t('labels.radiator.power')}
          </Typography>
        </div>
        <div className={classes.infoItemContainer}>
          <div className={classes.infoItem}>
            <img
              className={classes.image}
              src='/assets/width_arrow.png'
              alt='width'
            />
            <Typography className={classes.label} variant='h5'>
              {`${data.widthFrom} - ${data.widthTo} mm`}
            </Typography>
          </div>
          <Divider />
          <Typography variant='caption'>
            {t('labels.radiator.widthLimitations')}
          </Typography>
        </div>
        <div className={classes.infoItemContainer}>
          <div className={classes.infoItem}>
            <img
              className={classes.image}
              src='/assets/height_arrow.png'
              alt='height'
            />
            <Typography className={classes.label} variant='h5'>
              {`${data.heightFrom} - ${data.heightTo} mm`}
            </Typography>
          </div>
          <Divider />
          <Typography variant='caption'>
            {t('labels.radiator.heightLimitations')}
          </Typography>
        </div>
      </div>
    )
  }

  renderContentCards(): Array<Node> {
    const { data } = this.props

    let content: Array<Node> = [this.renderInfoValues()]
    if (data.isSelected) {
      content.unshift(this.renderModelInfo())
    }
    return content
  }

  renderContent(): Node {
    const { contentCardIndex } = this.state
    const { classes, data } = this.props
    return (
      <div>
        <SwipeableViews
          enableMouseEvents
          index={contentCardIndex}
          onChangeIndex={(index: number): void =>
            this.onChangeContentCard(index)
          }>
          {this.renderContentCards()}
        </SwipeableViews>
        <MobileStepper
          className={classes.contentStepper}
          classes={{
            dot: classes.disabledDot,
            dotActive: classes.enabledDot,
          }}
          position='static'
          variant='dots'
          steps={data.isSelected ? 2 : 0}
          activeStep={contentCardIndex}
        />
      </div>
    )
  }

  renderButton(): Node {
    const { classes, data, t } = this.props
    let buttons = []
    if (data.isSelected) {
      buttons.push(
        <Button
          key='selectItem'
          className={classes.button}
          color={data.isItemSelected ? 'secondary' : 'primary'}
          variant='contained'
          onClick={(): void => this.onSelectItem()}>
          {data.isItemSelected ? t('labels.viewItem') : t('labels.selectItem')}
        </Button>
      )
    } else {
      buttons.push(
        <Button
          key='edit'
          className={classes.button}
          color='secondary'
          variant='contained'
          onClick={(): void => this.onSelect()}>
          {t('labels.editRadiator')}
        </Button>
      )
    }
    buttons.push(
      <Button
        key='duplicate'
        style={{ marginTop: 10 }}
        className={classes.button}
        color='inherit'
        variant='outlined'
        onClick={(): void => this.onDuplicate()}>
        {t('labels.duplicate')}
      </Button>
    )
    return buttons
  }

  render(): Node {
    const { classes, data, title } = this.props
    const titleClasses = classNames(classes.title, classes.label)

    return (
      <GridItemBase onClose={(): void => this.onDelete()} title={title}>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Typography className={titleClasses} variant='h6'>
              {data.name}
            </Typography>
          </div>
          <Divider />
          {this.renderContent()}
          {this.renderButton()}
        </div>
      </GridItemBase>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 22px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.secondary.main,
  },
  infoItemContainer: {
    marginTop: 17,
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  contentStepper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    height: 24,
  },
  button: {
    marginTop: 6,
  },
  titleContainer: {
    height: 78,
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  },
  title: {
    fontWeight: 200,
    maxHeight: 78,
    overflow: 'hidden',
  },
  image: {
    paddingRight: 10,
  },
  modelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modelLabel: {
    fontWeight: 200,
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    height: 38,
  },
  thumbnail: {
    width: '80%',
    maxWidth: 195,
    minHeight: 100,
    height: 'auto',
  },
  thumbnailContainer: {
    position: 'relative',
    width: '100%',
    minHeight: 70,
    display: 'flex',
    justifyContent: 'center',
  },
  disabledDot: {
    backgroundColor: theme.palette.disabled.main,
  },
  enabledDot: {
    backgroundColor: theme.palette.primary.main,
  },
  questionMarkContainer: {
    height: 70,
    width: 70,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 35,
    position: 'absolute',
    top: 'calc(50% - 35px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default withTranslation('common')(withStyles(styles)(RadiatorItem))
