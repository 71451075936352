import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Divider,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'
import ShoppingListTable from '../components/collections/tables/ShoppingListTable'

import Radiator from '../models/Radiator'

import { allRadiators } from '../selectors/spaceSelector'

type ShoppingListViewProps = {
  classes: Object,
  history: Object,
  shoppingItems: Array<Radiator>,
  t: Function,
}

class ShoppingListView extends React.Component<ShoppingListViewProps> {
  onBackToSummary() {
    const { history } = this.props
    history.goBack()
  }

  renderInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox>
        <InformationParagraph>
          {t('info.shoppingList.part1')}
        </InformationParagraph>
        <InformationParagraph>
          {t('info.shoppingList.part2')}
        </InformationParagraph>
      </InformationBox>
    )
  }

  renderButtons(): Node {
    const { classes, t } = this.props
    return (
      <Grid className={classes.buttonsContainer} container spacing={6}>
        <Grid item sm={12} md={3} />
        <Grid item sm={12} md={3}>
          <Button
            className={classes.button}
            variant='outlined'
            color='inherit'
            onClick={(): void => this.onBackToSummary()}>
            {t('labels.goToSummary')}
          </Button>
        </Grid>
        <Grid item sm={12} md={3}>
          <Button className={classes.button} variant='outlined' color='inherit'>
            {t('labels.print')}
          </Button>
        </Grid>
        <Grid item sm={12} md={3}>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'>
            {t('labels.saveList')}
          </Button>
        </Grid>
      </Grid>
    )
  }

  renderSummary(): Node {
    const { classes, shoppingItems, t } = this.props

    const priceCount: number = shoppingItems.reduce(
      (currentValue: number, item: Radiator): number => {
        return currentValue + ((item.item && item.item.price) || 0)
      },
      0
    )
    return (
      <React.Fragment>
        <Typography style={{ marginBottom: 30, marginTop: 50 }} variant='h3'>
          {t('titles.suggestedListPrice')}
        </Typography>
        <Grid container>
          <Grid item sm={12} md={6} />
          <Grid item sm={12} md={6}>
            <div className={classes.summaryContainer}>
              <Typography style={{ marginLeft: 180 }} variant='subtitle1'>
                {t('labels.radiatorsSubtotal')}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.summaryContainer}>
              <Typography
                style={{ minWidth: 180, maxWidth: 180 }}
                variant='h4'>{`€ ${priceCount}`}</Typography>
              <div>
                <Typography style={{ fontWeight: 200 }}>
                  {t('labels.suggestedListPriceValueOnly')}
                </Typography>
                <Typography style={{ fontWeight: 200, marginTop: 20 }}>
                  {t('info.realValues')}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>

        {this.renderButtons()}
      </React.Fragment>
    )
  }

  renderShoppingListTable(): Node {
    const { shoppingItems } = this.props

    return <ShoppingListTable data={shoppingItems} />
  }

  render(): Node {
    const { classes, t } = this.props
    return (
      <div>
        <Typography className={classes.title} variant='h3'>
          {t('titles.finalListOfItems')}
        </Typography>
        {this.renderShoppingListTable()}
        {this.renderSummary()}
        {this.renderInfo()}
      </div>
    )
  }
}

const styles = {
  buttonsContainer: {
    marginTop: 70,
    marginBottom: 100,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    width: '100%',
  },
  divider: {
    marginTop: 5,
    marginBottom: 8,
  },
  title: {
    paddingBottom: 120,
  },
}

const mapStateToProps = (state: Object): Object => {
  return {
    shoppingItems: allRadiators(state.spaces.all).filter(
      (item: Radiator): boolean => {
        return item.isItemSelected
      }
    ),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {}
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ShoppingListView)
  )
)
