import React from 'react'
import type { Node } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

type EmailFeedbackDialogProps = {
  classes: Object,
  isOpen: boolean,
  onAgree: Function,
  t: Function,
  message: string,
}

class EmailFeedbackDialog extends React.Component<EmailFeedbackDialogProps> {
  render(): Node {
    const { isOpen, message, onAgree, t } = this.props

    return (
      <Dialog
        open={isOpen}
        onClose={onAgree}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          {t('labels.email.feedbackTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t(message || 'labels.email.sent')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAgree} color='primary' autoFocus>
            {t('labels.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = {}

export default withTranslation('common')(
  withStyles(styles)(EmailFeedbackDialog)
)
