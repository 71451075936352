import React from 'react'
import type { Node } from 'react'

import { Button, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import InformationBox from '../../components/info/InformationBox'
import InformationParagraph from '../../components/info/InformationParagraph'

type ProjectSummaryCompanionViewProps = {
  classes: Object,
  onBackToSpaces: Function,
  onGoToSelectionMode: Function,
  onShowFinalList: Function,
  isShowFinalListDisabled: boolean,
  t: Function,
}

class ProjectSummaryCompanionView extends React.Component<ProjectSummaryCompanionViewProps> {
  static defaultProps = {
    isShowFinalListDisabled: true,
  }

  renderLogo(): Node {
    const { classes } = this.props
    return (
      <div className={classes.logoContainer}>
        <img src='/assets/logo.png' alt='logo' />
      </div>
    )
  }

  renderButtons(): Node {
    const {
      classes,
      isShowFinalListDisabled,
      onBackToSpaces,
      onGoToSelectionMode,
      onShowFinalList,
      t,
    } = this.props
    return (
      <React.Fragment>
        <Button
          className={classes.button}
          variant='outlined'
          color='inherit'
          onClick={onBackToSpaces}>
          {t('labels.goToSpaces')}
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          color='inherit'
          onClick={onGoToSelectionMode}>
          {t('labels.modelSelectionMode')}
        </Button>
        <Button
          disabled={isShowFinalListDisabled}
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={onShowFinalList}>
          {t('labels.finalListOfRadiators')}
        </Button>
      </React.Fragment>
    )
  }

  renderInfo(): Node {
    const { classes, t } = this.props
    return (
      <InformationBox className={classes.info}>
        <InformationParagraph>{t('info.summary.part1')}</InformationParagraph>
        <InformationParagraph>{t('info.summary.part2')}</InformationParagraph>
      </InformationBox>
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderLogo()}
        {this.renderButtons()}
        {this.renderInfo()}
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: 30,
    width: '100%',
  },
  info: {
    marginTop: 360,
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 50,
  },
}

export default withTranslation('common')(
  withStyles(styles)(ProjectSummaryCompanionView)
)
