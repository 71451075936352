import React from 'react'
import type { Node } from 'react'

import {
  Checkbox,
  Divider,
  Radio,
  Typography,
  withStyles,
} from '@material-ui/core'

import classNames from 'classnames'

type RadioButtonsGroupProps = {
  classes: Object,
  disabled: boolean,
  items: Array<Object>,
  multipleChoice?: boolean,
  onChange?: Function,
  value?: Array<any>,
}

class RadioButtonsGroup extends React.Component<RadioButtonsGroupProps> {
  static defaultProps = {
    disabled: false,
    items: [],
    multipleChoice: false,
    onChange: () => {},
    value: [],
  }

  onChange(newValue: boolean, index: number) {
    const { disabled, onChange, items, multipleChoice, value } = this.props
    if (!onChange || disabled) {
      return
    }

    if (multipleChoice) {
      const toChangeValue = items[index].value
      if (newValue && value !== undefined) {
        onChange(value.concat(toChangeValue))
      } else {
        if (value === undefined) {
          onChange([])
          return
        }
        const newValueState = value.filter((item: number): boolean => {
          return item !== items[index].value
        })
        onChange(newValueState)
      }
    } else if (newValue) {
      onChange(items[index].value)
    }
  }

  checkIfValueExists(toCheck: any): boolean {
    const { value } = this.props

    if (value === undefined || value.length === 0) {
      return false
    }
    const toCheckString: string = JSON.stringify(toCheck)
    const res: Array<any> = value.filter((item: Object): boolean => {
      return JSON.stringify(item).localeCompare(toCheckString) === 0
    })
    return res.length > 0
  }

  renderControl(isChecked: boolean): Node {
    const { disabled, multipleChoice } = this.props
    if (multipleChoice) {
      return (
        <Checkbox disabled={disabled} checked={isChecked} color='primary' />
      )
    }
    return <Radio disabled={disabled} checked={isChecked} color='primary' />
  }

  renderIcon(item: Object): Node {
    const { disabled } = this.props
    const iconStyle = { opacity: disabled ? 0.3 : 1 }

    if (item.iconPath) {
      return <img style={iconStyle} src={item.iconPath} alt={item.iconPath} />
    }
    return item.icon && <item.icon style={iconStyle} color='secondary' />
  }

  renderItems(): Node {
    const { classes, disabled, items, value } = this.props

    return items.map((item: Object, index: number): Node => (
      <React.Fragment key={index}>
        <div className={classes.container}>
          <div
            className={classes.item}
            onClick={() => {
              if (value) {
                this.onChange(!this.checkIfValueExists(item.value), index)
              }
            }}>
            {this.renderControl(this.checkIfValueExists(item.value))}
            <Typography
              classes={{
                root: classNames(
                  classes.label,
                  disabled ? classes.disabledLabel : null
                ),
              }}>
              {item.label}
            </Typography>
          </div>
          {this.renderIcon(item)}
        </div>
        <Divider classes={{ root: classes.divider }} />
      </React.Fragment>
    ))
  }
  render(): Node {
    return <div>{this.renderItems()}</div>
  }
}

const styles = (theme: Object): Object => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 8,
    marginTop: 40,
    marginBottom: 2,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    marginLeft: 40,
  },
  label: {
    cursor: 'pointer',
  },
  disabledLabel: {
    color: theme.palette.disabled.main,
  },
})

export default withStyles(styles)(RadioButtonsGroup)
