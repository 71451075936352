class RadiatorItem {
  id: string
  power: string
  type: string
  name: string

  width: string
  height: string
  color: number

  price: number

  ean: string

  constructor(data: Object = {}) {
    this.id = data.param_id || ''
    this.power = data.product_power || ''
    this.type = data.type_name || ''
    this.name = data.product_name || ''
    this.width = data.param_lenght || ''
    this.height = data.param_height || ''
    this.color = data.color || 9016
    this.price = data.price || 1100 + parseInt(700 * Math.random())

    this.ean = data.product_ean_no
  }

  toJSON(): Object {
    const json: Object = {
      param_id: this.id,
      product_power: this.power,
      type_name: this.type,
      product_name: this.name,
      param_lenght: this.width,
      param_height: this.height,
      color: this.color,
      price: this.price,
      product_ean_no: this.ean,
    }
    return json
  }

  get itemNumber(): string {
    return `${this.ean}-${this.color}`
  }
}

export default RadiatorItem
