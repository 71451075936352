const config = {
  buildingLocations: [
    {
      id: '28',
      label: 'labels.locations.seaSide',
      value: '28',
      iconPath: '/assets/ic_pool_24px.png',
      icon: 'DirectionsBoat',
    },
    {
      id: '29',
      label: 'labels.locations.midCountry',
      value: '29',
      icon: 'LocalFlorist',
    },
    {
      id: '30',
      label: 'labels.locations.mountain',
      value: '30',
      iconPath: '/assets/Group 103.png',
      icon: 'BrightnessHigh',
    },
  ],
  windConditions: [
    {
      id: '25',
      label: 'labels.windConditions.strong',
      value: '25',
      iconPath: '/assets/Group 107.png',
      icon: 'DirectionsBoat',
    },
    {
      id: '26',
      label: 'labels.windConditions.normal',
      value: '26',
      iconPath: '/assets/Group 110.png',
      icon: 'LocalFlorist',
    },
    {
      id: '27',
      label: 'labels.windConditions.smooth',
      value: '27',
      iconPath: '/assets/Group 111.png',
      icon: 'BrightnessHigh',
    },
  ],
  storeys: [
    {
      id: '25',
      label: 'labels.storey.groundCeiling',
      value: '25',
      iconPath: '/assets/Group 142.png',
      icon: 'DirectionsBoat',
    },
    {
      id: '26',
      label: 'labels.storey.groundRoof',
      value: '26',
      iconPath: '/assets/Group 143.png',
      icon: 'LocalFlorist',
    },
    {
      id: '27',
      label: 'labels.storey.floorCeiling',
      value: '27',
      iconPath: '/assets/Group 144.png',
      icon: 'BrightnessHigh',
    },
    {
      id: '28',
      label: 'labels.storey.floorRoof',
      value: '28',
      iconPath: '/assets/Group 145.png',
      icon: 'BrightnessHigh',
    },
  ],
  insulations: [
    {
      id: '37',
      label: 'labels.insulation.poor',
      value: '37',
      iconPath: '/assets/Group 137.png',
      icon: 'DirectionsBoat',
    },
    {
      id: '38',
      label: 'labels.insulation.average',
      value: '38',
      iconPath: '/assets/Group 138.png',
      icon: 'LocalFlorist',
    },
    {
      id: '39',
      label: 'labels.insulation.good',
      value: '39',
      iconPath: '/assets/Group 139.png',
      icon: 'BrightnessHigh',
    },
    {
      id: '40',
      label: 'labels.insulation.veryGood',
      value: '40',
      iconPath: '/assets/Group 141.png',
      icon: 'BrightnessHigh',
    },
  ],
  roomTypes: [
    {
      id: '73',
      label: 'labels.room.type.livingroom',
      value: '73',
      temperature: '22',
    },
    {
      id: '74',
      label: 'labels.room.type.bathroom',
      value: '74',
      temperature: '24',
    },
    {
      id: '75',
      label: 'labels.room.type.garage',
      value: '75',
      temperature: '12',
    },
    {
      id: '76',
      label: 'labels.room.type.storage',
      value: '76',
      temperature: '16',
    },
    {
      id: '77',
      label: 'labels.room.type.hall',
      value: '77',
      temperature: '10',
    },
    {
      id: '78',
      label: 'labels.room.type.kitchen',
      value: '78',
      temperature: '20',
    },
    {
      id: '79',
      label: 'labels.room.type.office',
      value: '79',
      temperature: '18',
    },
    {
      id: '80',
      label: 'labels.room.type.bedroom',
      value: '80',
      temperature: '18',
    },
  ],
  externalWalls: [
    {
      id: '37',
      label: '0',
      value: '37',
    },
    {
      id: '38',
      label: '1',
      value: '238',
    },
    {
      id: '39',
      label: '2',
      value: '39',
    },
    {
      id: '40',
      label: '3',
      value: '40',
    },
  ],
  glazingPercentages: [
    {
      id: '37',
      label: 'labels.glazingSurface.noWindows',
      value: '37',
      iconPath: '/assets/Path 113.png',
      icon: 'DirectionsBoat',
    },
    {
      id: '38',
      label: 'labels.glazingSurface.lessThan10',
      value: '38',
      iconPath: '/assets/Path 111.png',
      icon: 'LocalFlorist',
    },
    {
      id: '39',
      label: 'labels.glazingSurface.between10And20',
      value: '39',
      iconPath: '/assets/Path 112.png',
      icon: 'BrightnessHigh',
    },
    {
      id: '40',
      label: 'labels.glazingSurface.moreThan20',
      value: '40',
      iconPath: '/assets/Path 114.png',
      icon: 'BrightnessHigh',
    },
  ],
  glazingQualities: [
    {
      id: '37',
      label: 'labels.glazingQuality.single',
      value: '37',
      iconPath: '/assets/Group 146.png',
      icon: 'DirectionsBoat',
    },
    {
      id: '38',
      label: 'labels.glazingQuality.doubleOld',
      value: '38',
      iconPath: '/assets/Group 147.png',
      icon: 'LocalFlorist',
    },
    {
      id: '39',
      label: 'labels.glazingQuality.doubleNew',
      value: '39',
      iconPath: '/assets/Group 148.png',
      icon: 'BrightnessHigh',
    },
    {
      id: '40',
      label: 'labels.glazingQuality.triple',
      value: '40',
      iconPath: '/assets/Group 149.png',
      icon: 'BrightnessHigh',
    },
  ],
  heatEmissions: [
    {
      id: '11',
      label: '75/65/20 °C',
      value: {
        flow: '75',
        return: '65',
        room: '20',
      },
    },
    {
      id: '14',
      label: '70/50/20 °C',
      value: {
        flow: '70',
        return: '50',
        room: '20',
      },
    },
    {
      id: '13',
      label: '45/35/20 °C',
      value: {
        flow: '45',
        return: '35',
        room: '20',
      },
    },
    {
      id: '12',
      label: '90/70/20 °C',
      value: {
        flow: '90',
        return: '70',
        room: '20',
      },
    },
  ],
}

export default config
