import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import queryString from 'query-string'

import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'
import OutlinedTextField from '../components/inputs/OutlinedTextField'
import DropDown from '../components/inputs/DropDown'
import SpaceParametersForm from '../components/forms/SpaceParametersForm'
import RoomForm from '../components/forms/RoomForm'

import CreateSpaceCompanionView from './companion/CreateSpaceCompanionView'

import Space from '../models/Space'
import {
  addSpace,
  calculateSpacePowerAndAdd,
  calculateSpacePowerAndUpdate,
  updateSpace,
} from '../actions/SpaceActions'
import { findSpaceById } from '../selectors/spaceSelector'

import { getRoomTypes, getRoomTemperatureForId } from '../utils/CalcProperties'

type CreateSpaceViewProps = {
  addSpace: Function,
  calculateSpacePowerAndAdd: Function,
  calculateSpacePowerAndUpdate: Function,
  classes: Object,
  currentSpace: Space,
  history: Object,
  location: Object,
  projectName: string,
  spaces: Array<Space>,
  t: Function,
  updateSpace: Function,
}

type CreateSpaceViewState = {
  isRequiredPowerEnabled: boolean,
  isAlternativePowerEnabled: boolean,
  space: Space | null,
  errors: Object,
}

class CreateSpaceView extends React.Component<
  CreateSpaceViewProps,
  CreateSpaceViewState
> {
  state = {
    isRequiredPowerEnabled: false,
    isAlternativePowerEnabled: false,
    space: null,
    errors: {},
  }

  componentDidMount() {
    const { history, location } = this.props
    const page: string = location.pathname.split('/')[2]
    const query: Object = queryString.parse(location.search)
    if (page === 'create' && query.id) {
      history.replace({
        pathname: '/spaces/edit',
        search: queryString.stringify(query),
      })
    } else if (page === 'edit' && !query.id) {
      history.replace('/spaces/create')
    } else {
      this.prepareData(query.id)
    }
  }

  shouldComponentUpdate(nextProps: CreateSpaceViewProps): boolean {
    if (this.props.spaces !== nextProps.spaces) {
      this.goBack()
      return false
    }

    return true
  }

  onCalculate() {
    const {
      addSpace,
      calculateSpacePowerAndAdd,
      calculateSpacePowerAndUpdate,
      location,
      updateSpace,
    } = this.props
    const { isRequiredPowerEnabled, isAlternativePowerEnabled } = this.state
    const page: string = location.pathname.split('/')[2]
    let space: Space
    if (this.state.space) {
      space = this.state.space
    } else {
      this.goBack()
      return
    }

    if (!isAlternativePowerEnabled) {
      space.alternativePower = 0
    }

    if (!this.validateRequired()) {
      return
    }

    if (!isRequiredPowerEnabled && !this.validate()) {
      return
    }
    if (!isRequiredPowerEnabled && page.localeCompare('edit') === 0) {
      space.requestedPower = 0
      calculateSpacePowerAndUpdate(space)
    } else if (!isRequiredPowerEnabled) {
      calculateSpacePowerAndAdd(space)
    } else if (page.localeCompare('edit') === 0) {
      space.isCalculated = false
      updateSpace(space)
    } else {
      space.isCalculated = false
      addSpace(space)
    }
  }

  onDelete() {
    this.goBack()
  }

  onDisable() {
    this.goBack()
  }

  onChangeSwitch(key: string, value: boolean) {
    this.setState({ [key]: value })
  }

  onChangeSpaceProperty(property: string, value: string | number) {
    const { errors, space } = this.state

    let newSpace: Space = new Space({
      ...space,
      [property]: value,
    })
    if (property.localeCompare('type') === 0) {
      newSpace.temperature = getRoomTemperatureForId(value)
    }

    this.setState({
      space: newSpace,
      errors: { ...errors, [property]: false },
    })
  }

  validateProperty(propertyValue: any): boolean {
    let isError: boolean = false
    if (propertyValue === undefined) {
      return false
    }

    if (parseInt(propertyValue) === 0) {
      isError = true
    }
    return !isError
  }

  validate(): boolean {
    const { errors, space } = this.state

    const isWidthError: boolean = !this.validateProperty(space && space.width)
    const isHeightError: boolean = !this.validateProperty(space && space.height)
    const isLengthError: boolean = !this.validateProperty(space && space.length)

    this.setState({
      ...this.state,
      errors: {
        ...errors,
        width: isWidthError,
        height: isHeightError,
        length: isLengthError,
      },
    })
    return !isWidthError && !isHeightError && !isLengthError
  }

  validateRequired(): boolean {
    const { errors, space } = this.state
    const isTypeError: boolean = !this.validateProperty(space && space.type)
    this.setState({
      ...this.state,
      errors: {
        ...errors,
        type: isTypeError,
      },
    })
    return !isTypeError
  }

  getRoomTypesData(): Array<Object> {
    const { t } = this.props
    let values = [{ label: `(${t('labels.choose')})`, value: 0 }]
    values = values.concat(getRoomTypes(t))
    values = values.concat([{ label: `${t('labels.other')}...`, value: 1 }])

    return values
  }

  prepareData(spaceId?: string) {
    const { spaces } = this.props
    let space: Space | null
    let requiredPowerEnabled: boolean = false
    if (spaceId === undefined) {
      space = new Space()
    } else {
      space = findSpaceById(spaces, spaceId)
      requiredPowerEnabled = space ? !space.isCalculated : false
    }
    if (space) {
      this.setState({
        space: space,
        isRequiredPowerEnabled: requiredPowerEnabled,
        isAlternativePowerEnabled: space.alternativePower > 0,
      })
    }
  }

  goBack() {
    const { history } = this.props
    history.goBack()
  }

  renderRoomTypeSelection(): Node {
    const { errors, space } = this.state
    const { classes, t } = this.props

    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h5'>
          {t('titles.roomType')}
        </Typography>
        <DropDown
          error={errors.type}
          data={this.getRoomTypesData()}
          hint={t('hints.roomSelection')}
          value={(space && space.type) || 0}
          onChange={(value: number) => {
            this.onChangeSpaceProperty('type', value)
          }}
        />
        <OutlinedTextField
          disabled={
            space && space.type ? space.type.localeCompare('1') !== 0 : true
          }
          className={classes.textField}
          numeric
          label={t('labels.customTemperature')}
          hint={t('hints.customTemperature')}
          value={space && space.temperature}
          onChange={(value: number): void =>
            this.onChangeSpaceProperty('temperature', value)
          }
        />
      </React.Fragment>
    )
  }

  renderSwitch(label: string, key: string, value: boolean): Node {
    const { classes } = this.props
    return (
      <div>
        <FormControlLabel
          classes={{ label: classes.knowledgeSwitchLabel }}
          control={
            <Switch
              color='primary'
              checked={value}
              onChange={(event: Object): void =>
                this.onChangeSwitch(key, event.target.checked)
              }
            />
          }
          label={label}
        />
      </div>
    )
  }

  renderRequiredPowerSection(): Node {
    const { t } = this.props
    const { isRequiredPowerEnabled, space } = this.state
    return (
      <Grid container spacing={2}>
        <Grid xs={12} md={6} item>
          {this.renderSwitch(
            t('info.requiredPowerForRoom'),
            'isRequiredPowerEnabled',
            isRequiredPowerEnabled
          )}
        </Grid>
        <Grid xs={12} md={6} item>
          <OutlinedTextField
            numeric
            disabled={!isRequiredPowerEnabled}
            onChange={(value: number): void =>
              this.onChangeSpaceProperty('requestedPower', value)
            }
            label={t('labels.enterPower')}
            hint={t('hints.powerForSpace')}
            value={space && !space.isCalculated && space.requestedPower}
          />
        </Grid>
      </Grid>
    )
  }

  renderSpaceForm(): Node {
    const { isRequiredPowerEnabled, space } = this.state
    return (
      <SpaceParametersForm
        disabled={isRequiredPowerEnabled}
        space={space}
        onChange={(property: string, value: number): void =>
          this.onChangeSpaceProperty(property, value)
        }
      />
    )
  }

  renderRoomForm(): Node {
    const { errors, isRequiredPowerEnabled, space } = this.state
    return (
      <RoomForm
        disabled={isRequiredPowerEnabled}
        errors={errors}
        space={space}
        onChange={(property: string, value: number): void =>
          this.onChangeSpaceProperty(property, value)
        }
      />
    )
  }

  renderAlternativePowerSourceSection(): Node {
    const { classes, t } = this.props
    const { isAlternativePowerEnabled, space } = this.state
    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h5'>
          {t('titles.alternativePowerSource')}
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item>
            {this.renderSwitch(
              t('info.alternativePowerSource'),
              'isAlternativePowerEnabled',
              isAlternativePowerEnabled
            )}
          </Grid>
          <Grid xs={12} md={6} item>
            <OutlinedTextField
              numeric
              disabled={!isAlternativePowerEnabled}
              label={t('labels.enterPower')}
              hint={t('hints.powerForSpace')}
              value={(space && space.alternativePower) || ''}
              onChange={(value: number): void =>
                this.onChangeSpaceProperty('alternativePower', value)
              }
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  renderInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox>
        <InformationParagraph>{t('info.createSpace')}</InformationParagraph>
      </InformationBox>
    )
  }

  renderContent(): Node {
    const { classes, t } = this.props
    const { space } = this.state
    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h3'>
          {t('titles.addSpace')}
        </Typography>
        <OutlinedTextField
          onChange={(value: number): void =>
            this.onChangeSpaceProperty('name', value)
          }
          label={t('labels.enterSpace')}
          hint={t('hints.space')}
          value={(space && space.name) || ''}
        />
        {this.renderRoomTypeSelection()}
        {this.renderRequiredPowerSection()}
        {this.renderSpaceForm()}
        {this.renderRoomForm()}
        {this.renderAlternativePowerSourceSection()}
        {this.renderInfo()}
      </React.Fragment>
    )
  }

  renderCompanionView(): Node {
    const { space, isAlternativePowerEnabled } = this.state
    const { projectName } = this.props
    return (
      <CreateSpaceCompanionView
        onDelete={(): void => this.onDelete()}
        onDisable={(): void => this.onDisable()}
        onCalculate={(): void => this.onCalculate()}
        calculatedPower={space && space.requestedPower}
        alternativePower={
          isAlternativePowerEnabled && space ? space.alternativePower : 0
        }
        projectName={projectName}
      />
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Grid container spacing={8}>
          <Grid item sm={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item sm={4} xs={12}>
            {this.renderCompanionView()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = {
  container: {},
  title: {
    paddingBottom: 30,
  },
  knowledgeSwitchLabel: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  textField: {
    marginTop: 20,
    paddingBottom: 50,
  },
}

const mapStateToProps = (state: Object): Object => {
  return {
    spaces: state.spaces.all,
    currentSpace: state.spaces.current,
    projectName: state.settings.project.name,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {
    addSpace,
    calculateSpacePowerAndAdd,
    calculateSpacePowerAndUpdate,
    updateSpace,
  }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CreateSpaceView)
  )
)
