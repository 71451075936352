import type { EmailAction } from '../actions/EmailActions'
import { EmailActionType } from '../actions/EmailActions'

type EmailStateType = {
  isSent: boolean,
}

const defaultState: EmailStateType = {
  isSent: false,
}

export default (
  state: EmailStateType = defaultState,
  action: EmailAction
): EmailStateType => {
  let newState = null

  switch (action.type) {
    case EmailActionType.EMAIL_SET_MAIL_SENT:
      newState = { ...state, isSent: action.status }
      break
    default:
      break
  }

  return newState || state
}
