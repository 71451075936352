import React from 'react'
import type { Node } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import ShoppingItem from './items/ShoppingItem'

import Radiator from '../../../models/Radiator'

type ShoppingListTableProps = {
  classes: Object,
  data: Array<Radiator>,
  t: Function,
}

class ShoppingListTable extends React.Component<ShoppingListTableProps> {
  renderHeads(): Node {
    const { classes, t } = this.props
    return (
      <TableRow>
        <TableCell align='left' />
        <TableCell align='right'>
          <Typography className={classes.head}>
            {`${t('labels.item.power')} [W]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {t('labels.item.type')}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {`${t('labels.item.width')} [mm]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {`${t('labels.item.height')} [mm]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {t('labels.item.color')}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.headBold}>
            {`${t('labels.item.price')} [€]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {t('labels.item.number')}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  renderCells(): Array<Node> {
    const { data } = this.props

    return data.map((item: Radiator, idx: number): Node => {
      return <ShoppingItem data={item} key={idx} index={idx} />
    })
  }
  render(): Node {
    return (
      <div>
        <Table size={'small'}>
          <TableHead>{this.renderHeads()}</TableHead>
          <TableBody>{this.renderCells()}</TableBody>
        </Table>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {},
  head: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontWeight: 200,
  },
  headBold: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontWeight: 500,
  },
})

export default withStyles(styles)(withTranslation('common')(ShoppingListTable))
