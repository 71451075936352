import Radiator from '../models/Radiator'
import type { RadiatorAction } from '../actions/RadiatorActions'
import { RadiatorActionType } from '../actions/RadiatorActions'

import { removeRadiator, updateRadiator } from '../selectors/radiatorSelectors'

type RadiatorStateType = {
  all: Array<Radiator>,
  current: Radiator | null,
}

const defaultState: RadiatorStateType = {
  all: [],
  current: null,
}

export default (
  state: RadiatorStateType = defaultState,
  action: RadiatorAction
): RadiatorStateType => {
  let newState: RadiatorStateType | null = null

  switch (action.type) {
    case RadiatorActionType.RADIATOR_SET_COLLECTION:
      newState = { ...state, all: action.radiators }
      break
    case RadiatorActionType.RADIATOR_CLEAR_COLLECTION:
      newState = { ...state, all: [] }
      break
    case RadiatorActionType.RADIATOR_ADD:
      newState = { ...state, all: state.all.concat(action.radiator) }
      break
    case RadiatorActionType.RADIATOR_UPDATE:
      newState = { ...state, all: updateRadiator(state.all, action.radiator) }
      break
    case RadiatorActionType.RADIATOR_REMOVE:
      newState = { ...state, all: removeRadiator(state.all, action.radiator) }
      break
    case RadiatorActionType.RADIATOR_SET_CURRENT:
      newState = { ...state, current: action.radiator }
      break
    case RadiatorActionType.RADIATOR_CLEAR_CURRENT:
      newState = { ...state, current: null }
      break
    default:
      break
  }

  return newState || state
}
