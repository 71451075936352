import Project from '../models/Project'
import Building from '../models/Building'
import Space from '../models/Space'
import RadiatorModel from '../models/RadiatorModel'

import ProjectApi from '../api/ProjectApi'

import { setSpaces } from './SpaceActions'
import { setBuilding } from './BuildingActions'
import { setSettings, setMyModels } from './SettingActions'

const createProject = (project: Project, lang: string): Function => {
  return (dispatch: Function) => {
    ProjectApi.create(project, lang)
      .then((data: Project) => {
        dispatch(setSettings(data.name, data.code, data.email))
        dispatch(setSpaces(data.spaces))
        dispatch(setBuilding(data.building))
        dispatch(setMyModels(data.myModels || []))
      })
      .catch((error: Error) => {
        console.log(error)
      })
  }
}

const getProjectsCodes = (email: string, lang: string): Function => {
  return (dispatch: Function) => {
    ProjectApi.getCodes(email, lang).then((data: boolean) => {
      console.log('Project codes requested')
    })
  }
}

const getProject = (id: string): Function => {
  return (dispatch: Function) => {
    ProjectApi.get(id)
      .then((data: Project) => {
        dispatch(setSpaces(data.spaces))
        dispatch(setBuilding(data.building))
        dispatch(setSettings(data.name, data.code, data.email))
        dispatch(setMyModels(data.myModels || []))
      })
      .catch((error: Error) => {
        console.log(error)
      })
  }
}

const updateProject = (): Function => {
  return (dispatch: Function, getState: Function) => {
    const state = getState()
    if (
      !state.settings.project.code ||
      state.settings.project.code.length === 0
    ) {
      return
    }

    let project: Project = new Project({
      name: state.settings.project.name,
      code: state.settings.project.code,
      email: state.settings.user.email,
      data: {
        building: new Building(state.building.current).toJSON(),
        spaces: state.spaces.all.map((item: Space): Object => item.toJSON()),
        myModels: state.settings.project.myModels.map(
          (item: RadiatorModel): Object => item.toJSON()
        ),
      },
    })
    ProjectApi.update(project)
      .then((data: Project) => {
        console.log('updated')
      })
      .catch((error: Error) => {
        console.log(error)
      })
  }
}

export { createProject, getProject, getProjectsCodes, updateProject }
