import React from 'react'
import type { Node } from 'react'

import { Button, withStyles } from '@material-ui/core'
import classNames from 'classnames'

type RoundedButtonState = 'active' | 'done' | 'inactive'
type RoundedButtonProps = {
  classes: Object,
  children: Node,
  onClick: Function,
  state: RoundedButtonState,
}

class RoundedButton extends React.Component<RoundedButtonProps> {
  static defaultProps = {
    state: 'inactive',
  }

  onClick() {
    const { onClick } = this.props
    if (onClick) {
      onClick()
    }
  }

  getClasses(): Object {
    const { classes, state } = this.props
    let buttonClasses: Object

    switch (state) {
      case 'active':
        buttonClasses = classNames(classes.container, classes.containerActive)
        break
      case 'done':
        buttonClasses = classNames(classes.container, classes.containerDone)
        break
      case 'inactive':
        buttonClasses = classNames(classes.container, classes.containerInactive)
        break
      default:
        buttonClasses = classNames(classes.container)
        break
    }
    return buttonClasses
  }

  render(): Node {
    const { children, state } = this.props

    return (
      <Button
        disabled={state.localeCompare('done') !== 0}
        classes={{
          outlined: this.getClasses(),
        }}
        onClick={(): void => this.onClick()}
        variant='outlined'>
        {children}
      </Button>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    backgroundColor: 'white',
    border: 'solid',
    borderRadius: 21,
    borderWidth: 5,
    height: 42,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 8,
    marginRight: 8,
    '&:hover': {
      border: 'solid',
      borderWidth: 5,
    },
    '&:disabled': {
      border: 'solid',
      borderWidth: 5,
    },
  },
  containerActive: {
    '&:disabled': {
      color: theme.palette.primary.main,
    },
  },
  containerInactive: {
    '&:disabled': {
      color: theme.palette.disabled.main,
    },
  },
  containerDone: {
    color: theme.palette.secondary.main,
    '&:disabled': {
      color: theme.palette.secondary.main,
    },
  },
})

export default withStyles(styles)(RoundedButton)
