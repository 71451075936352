import Space from '../models/Space'
import type { SpaceAction } from '../actions/SpaceActions'
import { SpaceActionType } from '../actions/SpaceActions'

import { updateSpace } from '../selectors/spaceSelector'

type SpacesStateType = {
  all: Array<Space>,
  current: Space | null,
}

const defaultState: SpacesStateType = {
  all: [],
  current: null,
}

export default (
  state: SpacesStateType = defaultState,
  action: SpaceAction
): SpacesStateType => {
  let newState: SpacesStateType | null = null

  switch (action.type) {
    case SpaceActionType.SPACE_SET:
      newState = { ...state, all: action.spaces }
      break
    case SpaceActionType.SPACE_ADD:
      newState = { ...state, all: state.all.concat(action.space) }
      break
    case SpaceActionType.SPACE_UPDATE:
      newState = {
        ...state,
        all: updateSpace(state.all, action.space),
        current: action.space,
      }
      break
    case SpaceActionType.SPACE_SET_CURRENT:
      newState = { ...state, current: action.space }
      break
    case SpaceActionType.SPACE_CLEAR_CURRENT:
      newState = { ...state, current: null }
      break
    default:
      break
  }

  return newState || state
}
