import React from 'react'
import type { Node } from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import RadioButtonsGroup from '../inputs/RadioButtonsGroup'

import Space from '../../models/Space'

import {
  getGlazingPercentages,
  getGlazingQualities,
  getInsulations,
  getStoreys,
} from '../../utils/CalcProperties'

type SpaceParametersFormProps = {
  classes: Object,
  disabled: boolean,
  onChange: Function,
  space: Space,
  t: Function,
}

class SpaceParametersForm extends React.Component<SpaceParametersFormProps> {
  static defaultProps = {
    disabled: false,
  }

  getGlazingSurfaceItems(): Array<Object> {
    const { t } = this.props
    return getGlazingPercentages(t)
  }

  getGlazingQualityItems(): Array<Object> {
    const { t } = this.props
    return getGlazingQualities(t)
  }

  getInsulationQualityItems(): Array<Object> {
    const { t } = this.props
    return getInsulations(t)
  }

  getSpaceStoreysItems(): Array<Object> {
    const { t } = this.props
    return getStoreys(t)
  }

  renderRadioButtons(
    data: Array<Object>,
    key: string,
    defaultValue: number | string
  ): Node {
    const { disabled, onChange } = this.props
    return (
      <RadioButtonsGroup
        disabled={disabled}
        items={data}
        value={[defaultValue]}
        onChange={(value: number): void => onChange(key, value)}
      />
    )
  }

  renderGlazingSurfaceSection(): Node {
    const { classes, disabled, space, t } = this.props
    return (
      <React.Fragment>
        <Typography
          className={disabled ? classes.disabledTitle : classes.title}
          variant='h5'>
          {t('titles.glazingSurface')}
        </Typography>
        {this.renderRadioButtons(
          this.getGlazingSurfaceItems(),
          'glazingSurface',
          (space && space.glazingSurface) || 0
        )}
      </React.Fragment>
    )
  }

  renderGlazingQualitySection(): Node {
    const { classes, disabled, space, t } = this.props
    return (
      <React.Fragment>
        <Typography
          className={disabled ? classes.disabledTitle : classes.title}
          variant='h5'>
          {t('titles.glazingQuality')}
        </Typography>
        {this.renderRadioButtons(
          this.getGlazingQualityItems(),
          'glazingQuality',
          (space && space.glazingQuality) || 0
        )}
      </React.Fragment>
    )
  }

  renderInsulationQualitySection(): Node {
    const { classes, disabled, space, t } = this.props
    return (
      <React.Fragment>
        <Typography
          className={disabled ? classes.disabledTitle : classes.title}
          variant='h5'>
          {t('titles.insulationQuality')}
        </Typography>
        {this.renderRadioButtons(
          this.getInsulationQualityItems(),
          'insulationQuality',
          (space && space.insulationQuality) || 0
        )}
      </React.Fragment>
    )
  }

  renderSpaceStoreysSection(): Node {
    const { classes, disabled, space, t } = this.props
    return (
      <React.Fragment>
        <Typography
          className={disabled ? classes.disabledTitle : classes.title}
          variant='h5'>
          {t('titles.spaceStoreys')}
        </Typography>
        {this.renderRadioButtons(
          this.getSpaceStoreysItems(),
          'storeys',
          (space && space.storeys) || 0
        )}
      </React.Fragment>
    )
  }
  render(): Node {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Grid className={classes.topContainer} container spacing={6}>
          <Grid item xs={12} md={6}>
            {this.renderGlazingSurfaceSection()}
          </Grid>
          <Grid item xs={12} md={6}>
            {this.renderGlazingQualitySection()}
          </Grid>
        </Grid>
        <Grid className={classes.bottomContainer} container spacing={6}>
          <Grid item xs={12} md={6}>
            {this.renderInsulationQualitySection()}
          </Grid>
          <Grid item xs={12} md={6}>
            {this.renderSpaceStoreysSection()}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const styles = (theme: Object): Object => ({
  topContainer: {
    marginTop: 5,
  },
  bottomContainer: {
    marginTop: 50,
    marginBottom: 40,
  },
  title: {
    paddingTop: 10,
  },
  disabledTitle: {
    paddingTop: 10,
    color: theme.palette.disabled.main,
  },
})

export default withTranslation('common')(
  withStyles(styles)(SpaceParametersForm)
)
