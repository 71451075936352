import React from 'react'
import type { Node } from 'react'

import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import LabelWithBackground from '../../components/labels/LabelWithBackground'
import CompanionContentContainer from '../../components/miscs/CompanionContentContainer'

type CreateSpaceCompanionViewProps = {
  alternativePower?: number,
  calculatedPower?: number,
  classes: Object,
  onCalculate: Function,
  onDelete: Function,
  onDisable: Function,
  projectName?: string,
  t: Function,
}

class CreateSpaceCompanionView extends React.Component<CreateSpaceCompanionViewProps> {
  static defaultProps = {
    alternativePower: 0,
    calculatedPower: 0,
    projectName: '',
  }

  renderPowerInfo(): Node {
    const { alternativePower, calculatedPower, classes, t } = this.props

    return (
      <Grid container className={classes.powerInfoContainer}>
        <Grid item xs={12} md={6}>
          <Typography variant='caption'>
            {t('labels.calculatedPower')}
          </Typography>
          <Typography className={classes.label} variant='h5'>
            {`${calculatedPower || 0} W`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='caption'>
            {t('labels.alternativePowerSource')}
          </Typography>
          <Typography className={classes.label} variant='h5'>
            {`${alternativePower || 0} W`}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  renderButtons(): Node {
    const { classes, onCalculate, onDelete, onDisable, t } = this.props
    return (
      <React.Fragment>
        <Button
          className={classes.button}
          variant='outlined'
          color='inherit'
          onClick={onDelete}>
          {t('labels.deleteSpaceAndExit')}
        </Button>
        <Button
          className={classes.button}
          disabled
          variant='outlined'
          color='inherit'
          onClick={onDisable}>
          {t('labels.disableSpace')}
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={onCalculate}>
          {t('labels.calculateAndExit')}
        </Button>
      </React.Fragment>
    )
  }

  render(): Node {
    const { classes, projectName, t } = this.props
    return (
      <div className={classes.container}>
        <LabelWithBackground label={t('labels.summary')} />
        <CompanionContentContainer>
          <Typography variant='caption'>{t('labels.projectName')}</Typography>
          <Typography className={classes.label} variant='h3'>
            {projectName}
          </Typography>
          {this.renderPowerInfo()}
          {this.renderButtons()}
        </CompanionContentContainer>
      </div>
    )
  }
}

const styles = {
  container: {
    marginTop: 70,
    position: 'sticky',
    top: 0,
  },
  powerInfoContainer: {
    paddingTop: 40,
    paddingBottom: 30,
  },
  button: {
    marginTop: 30,
    width: '100%',
  },
  label: {
    paddingTop: 5,
    paddingBottom: 20,
  },
}

export default withTranslation('common')(
  withStyles(styles)(CreateSpaceCompanionView)
)
