import {
  BrightnessHigh,
  DirectionsBoat,
  LocalFlorist,
} from '@material-ui/icons'

import calcProps from '../config/properties'

const translateLabels = (
  items: Array<Object>,
  t: Function = null
): Array<Object> => {
  if (!t) {
    return items
  }
  return items.map((item: Object): Object => {
    return { ...item, label: t(item.label) }
  })
}

const resolveIcons = (items: Array<Object>): Array<Object> => {
  return items.map((item: Object): Object => {
    let icon = null
    switch (item.icon) {
      case 'DirectionsBoat':
        icon = DirectionsBoat
        break
      case 'BrightnessHigh':
        icon = BrightnessHigh
        break
      case 'LocalFlorist':
        icon = LocalFlorist
        break
      default:
        break
    }
    if (icon) {
      return { ...item, icon: icon }
    }
    return item
  })
}

const prepareRoomsData = (items: Array<Object>): Array<Object> => {
  return items.map((item: Object): Object => {
    if (item.temperature) {
      return { ...item, label: `${item.label} (${item.temperature}°C)` }
    }
    return item
  })
}

const getBuildingLocations = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.buildingLocations, t))
}

const getWindConditions = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.windConditions, t))
}

const getStoreys = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.storeys, t))
}

const getInsulations = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.insulations, t))
}

const getRoomTypes = (t: Function = null): Array<Object> => {
  return prepareRoomsData(resolveIcons(translateLabels(calcProps.roomTypes, t)))
}

const getRoomTypeForId = (id: string | number, t: Function): string => {
  if (id === undefined || id === null) {
    return ''
  }
  for (let i = 0; i < calcProps.roomTypes.length; i++) {
    const element = calcProps.roomTypes[i]
    if (element.id.localeCompare(id.toString()) === 0) {
      return `${t(element.label)} (${element.temperature}°C)`
    }
  }
  return ''
}

const getRoomTemperatureForId = (id: string | number): string => {
  if (id === undefined || id === null) {
    return ''
  }
  for (let i = 0; i < calcProps.roomTypes.length; i++) {
    const element = calcProps.roomTypes[i]
    if (element.id.localeCompare(id.toString()) === 0) {
      return element.temperature
    }
  }
  return '0'
}

const getExternalWalls = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.externalWalls, t))
}

const getGlazingPercentages = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.glazingPercentages, t))
}

const getGlazingQualities = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.glazingQualities, t))
}

const getHeatingEmissions = (t: Function = null): Array<Object> => {
  return resolveIcons(translateLabels(calcProps.heatEmissions, t))
}

export {
  getBuildingLocations,
  getWindConditions,
  getStoreys,
  getInsulations,
  getRoomTypes,
  getRoomTypeForId,
  getRoomTemperatureForId,
  getExternalWalls,
  getGlazingPercentages,
  getGlazingQualities,
  getHeatingEmissions,
}
