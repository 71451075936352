import RadiatorItem from '../models/RadiatorItem'

import RadiatorItemsApi from '../api/RadiatorItemsApi'

const RadiatorItemActionType = {
  RADIATOR_ITEMS_SET: 'RADIATOR_ITEMS_SET',
  RADIATOR_ITEMS_CLEAR: 'RADIATOR_ITEMS_CLEAR',
}

type SetRadiatorsItems = {|
  type: typeof RadiatorItemActionType.RADIATOR_ITEMS_SET,
  items: Array<RadiatorItem>,
|}

type ClearRadiatorsItems = {|
  type: typeof RadiatorItemActionType.RADIATOR_ITEMS_CLEAR,
|}

export type RadiatorItemAction = SetRadiatorsItems | ClearRadiatorsItems

const setItems = (items: Array<RadiatorItem>): RadiatorItemAction => {
  return {
    type: RadiatorItemActionType.RADIATOR_ITEMS_SET,
    items: items,
  }
}

const getItems = (productId: string, params: Object): Function => {
  return (dispatch: Function) => {
    const combinedParams = {
      ...params,
      productId: productId,
    }
    RadiatorItemsApi.get(combinedParams).then((data: Object) => {
      const items: Array<RadiatorItem> = data.items.map(
        (item: Object): RadiatorItem => {
          return new RadiatorItem(item)
        }
      )
      dispatch(setItems(items))
    })
  }
}

const clearItems = (): RadiatorItemAction => {
  return {
    type: RadiatorItemActionType.RADIATOR_ITEMS_CLEAR,
  }
}

export { RadiatorItemActionType, getItems, clearItems }
