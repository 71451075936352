import { combineReducers } from 'redux'

import settings from './SettingsReducer'
import spaces from './SpacesReducer'
import radiators from './RadiatorsReducer'
import building from './BuildingReducer'
import models from './RadiatorModelsReducer'
import items from './RadiatorItemsReducer'
import email from './EmailReducer'

const reducers = {
  building,
  settings,
  spaces,
  radiators,
  models,
  items,
  email,
}

export type ReducersType = typeof reducers

// #FlowExpectError
export default combineReducers(reducers)
