import Space from '../models/Space'
import Radiator from '../models/Radiator'

import { amountOfSelectedRadiators } from './radiatorSelectors'

const findSpaceById = (spaces: Array<Space>, id: string): Space | null => {
  for (let i = 0; i < spaces.length; i++) {
    if (spaces[i].id.localeCompare(id) === 0) {
      return spaces[i]
    }
  }
  return null
}

const updateSpace = (spaces: Array<Space>, space: Space): Array<Space> => {
  return spaces.map((item: Space): Space => {
    if (item.id.localeCompare(space.id) === 0) {
      return space
    }
    return item
  })
}

const getTotalRequiredPower = (space: Space | null): number => {
  if (!space) {
    return 0
  }
  return (space.requestedPower || 0) - (space.alternativePower || 0)
}

const amountOfSelectedModels = (spaces: Array<Space> = []): number => {
  return spaces.reduce((currentValue: number, item: Space): number => {
    return currentValue + amountOfSelectedRadiators(item.radiators || [])
  }, 0)
}

const amountOfRadiators = (spaces: Array<Space> = []): number => {
  return spaces.reduce((currentValue: number, item: Space): number => {
    return currentValue + (item.radiators || []).length
  }, 0)
}
const allRadiators = (spaces: Array<Space> = []): Array<Radiator> => {
  return spaces.reduce(
    (currentValue: Array<Radiator>, item: Space): Array<Radiator> => {
      return currentValue.concat(item.radiators)
    },
    []
  )
}

export {
  allRadiators,
  amountOfRadiators,
  amountOfSelectedModels,
  findSpaceById,
  getTotalRequiredPower,
  updateSpace,
}
