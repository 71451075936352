import type { SettingAction } from '../actions/SettingActions'
import { SettingActionType } from '../actions/SettingActions'

type SettingsStateType = {
  user?: Object,
  project?: Object,
}

const defaultState: SettingsStateType = {
  user: {
    email: '',
  },
  project: {
    name: '',
    code: '',
    myModels: [],
  },
}

export default (
  state: SettingsStateType = defaultState,
  action: SettingAction
): SettingsStateType => {
  let newState = null

  switch (action.type) {
    case SettingActionType.SETTING_SET:
      newState = {
        ...state,
        user: { email: action.email },
        project: { ...state.project, name: action.name, code: action.code },
      }
      break
    case SettingActionType.SETTING_SET_NAME:
      newState = {
        ...state,
        project: { name: action.name },
      }
      break
    case SettingActionType.SETTING_SET_CODE:
      newState = {
        ...state,
        project: { code: action.code },
      }
      break
    case SettingActionType.SETTING_SET_MY_MODELS:
      newState = {
        ...state,
        project: { ...state.project, myModels: action.models },
      }
      break
    default:
      break
  }
  return newState || state
}
