import Api from './Api'

import Project from '../models/Project'

class ProjectApi extends Api {
  static resource: string = 'projects'

  static post(): any {
    return super.post
  }

  static create(project: Project, lang: string): any {
    return super
      .post(`${ProjectApi.resource}?lang=${lang}`, project.toJSON())
      .then((response: Object): Object => response.json())
      .then((data: Object): any => {
        return new Project(data)
      })
  }

  static get(projectId: string): any {
    return super
      .get(`${ProjectApi.resource}/${projectId}`)
      .then((data: any): Project => {
        return new Project(data)
      })
  }

  static getCodes(email: string, lang: string): any {
    return super
      .get(`${ProjectApi.resource}?email=${email}&lang=${lang}`)
      .then((data: any): boolean => {
        return true
      })
  }

  static update(project: Project): any {
    const path: string = `${ProjectApi.resource}/${project.code}`
    return super.put(path, project.toJSON()).then((data: any): Project => {
      return new Project(data)
    })
  }
}

export default ProjectApi
