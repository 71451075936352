import React from 'react'
import type { Node } from 'react'

import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import RadioButtonsGroup from '../inputs/RadioButtonsGroup'
import RangeSlider from '../inputs/RangeSlider'
import SingleSlider from '../inputs/SingleSlider'
import OutlinedTextField from '../inputs/OutlinedTextField'

import Radiator from '../../models/Radiator'

type RadiatorFormProps = {
  classes: Object,
  index: number,
  onChangeProperty: Function,
  radiator: Radiator,
  maxPower: number,
  t: Function,
}

class RadiatorForm extends React.Component<RadiatorFormProps> {
  static defaultProps = {
    radiator: new Radiator(),
    onChangeProperty: () => {},
  }

  getProductCategoriesPart1(): Array<Object> {
    const { t } = this.props
    return [
      { label: t('labels.radiator.type.panelRadiators'), value: '141' },
      { label: t('labels.radiator.type.horizontalDesign'), value: '143' },
      { label: t('labels.radiator.type.columnRadiators'), value: '145' },
    ]
  }

  getProductCategoriesPart2(): Array<Object> {
    const { t } = this.props
    return [
      { label: t('labels.radiator.type.towelWarmers'), value: '146' },
      { label: t('labels.radiator.type.verticalDesign'), value: '144' },
      { label: t('labels.radiator.type.plinthRadiators'), value: '142' },
    ]
  }

  renderNameField(): Node {
    const { index, onChangeProperty, radiator, t } = this.props
    return (
      <React.Fragment>
        <OutlinedTextField
          label={`${t('labels.enterRadiatorName')} "${t(
            'labels.radiator.radiator'
          )} #${index}"`}
          hint={t('hints.radiatorName')}
          value={radiator.name}
          onChange={(value: string): void => onChangeProperty('name', value)}
        />
      </React.Fragment>
    )
  }

  renderElectricalRadiatorsSwitch(): Node {
    const { classes, onChangeProperty, radiator, t } = this.props

    return (
      <React.Fragment>
        <FormControlLabel
          classes={{
            label: classes.knowledgeSwitchLabel,
            root: classes.knowledgeSwitchContainer,
          }}
          control={
            <Switch
              disabled
              color='primary'
              checked={radiator.isElectrical || false}
              onChange={(event: Object): void =>
                onChangeProperty('isElectrical', event.target.checked)
              }
            />
          }
          label={t('info.electricalRadiators')}
        />
      </React.Fragment>
    )
  }

  renderCategories(): Node {
    const { classes, onChangeProperty, radiator, t } = this.props
    return (
      <div className={classes.categoriesContainer}>
        <Typography className={classes.title} variant='h5'>
          {t('titles.productCategories')}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <RadioButtonsGroup
              multipleChoice
              items={this.getProductCategoriesPart1()}
              value={radiator.categories}
              onChange={(value: Array<number>): void =>
                onChangeProperty('categories', value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RadioButtonsGroup
              multipleChoice
              items={this.getProductCategoriesPart2()}
              value={radiator.categories}
              onChange={(value: Array<number>): void =>
                onChangeProperty('categories', value)
              }
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  renderPowerSlider(): Node {
    const { classes, onChangeProperty, maxPower, radiator, t } = this.props

    let sliderMarks = [{ value: 0, label: '0 W' }]
    if (maxPower) {
      sliderMarks.push({ value: 100, label: `${maxPower} W` })
    }

    return (
      <div className={classes.sliderContainer}>
        <Typography className={classes.title} variant='h5'>
          {t('labels.radiator.power')}
        </Typography>
        <SingleSlider
          marks={sliderMarks}
          max={100}
          labelTransformation={(value: string): string =>
            `${parseInt(
              (maxPower / 100) * Math.round(parseFloat(value))
            )} W = ${Math.round(parseFloat(value))} %`
          }
          onChange={(value: number): void =>
            onChangeProperty(
              'power',
              parseInt((maxPower / 100) * Math.round(parseFloat(value)))
            )
          }
          value={radiator.power ? (100 * radiator.power) / maxPower : 0}
        />
      </div>
    )
  }

  renderWidthSlider(): Node {
    const { classes, onChangeProperty, radiator, t } = this.props

    const sliderMarks = [
      { value: 400, label: '400 mm' },
      { value: 1000, label: '1000' },
      { value: 2000, label: '2000' },
      { value: 3000, label: '3000' },
      { value: 3300, label: '3330 mm' },
    ]
    return (
      <div className={classes.sliderContainer}>
        <Typography className={classes.title} variant='h5'>
          {t('labels.radiator.widthLimitations')}
        </Typography>
        <RangeSlider
          defaultValue={[400, 3300]}
          marks={sliderMarks}
          min={400}
          max={3330}
          labelTransformation={(value: string): string => `${value} mm`}
          value={[radiator.widthFrom, radiator.widthTo]}
          onChange={(value: [number, number]) => {
            onChangeProperty('widthFrom', value[0])
            onChangeProperty('widthTo', value[1])
          }}
        />
      </div>
    )
  }

  renderHeightSlider(): Node {
    const { classes, onChangeProperty, radiator, t } = this.props

    const sliderMarks = [
      { value: 400, label: '400 mm' },
      { value: 1000, label: '1000' },
      { value: 2000, label: '2000' },
      { value: 3000, label: '3000' },
      { value: 3300, label: '3330 mm' },
    ]
    return (
      <div className={classes.sliderContainer}>
        <Typography className={classes.title} variant='h5'>
          {t('labels.radiator.heightLimitations')}
        </Typography>
        <RangeSlider
          defaultValue={[400, 3300]}
          marks={sliderMarks}
          min={400}
          max={3330}
          labelTransformation={(value: string): string => `${value} mm`}
          value={[radiator.heightFrom, radiator.heightTo]}
          onChange={(value: [number, number]) => {
            onChangeProperty('heightFrom', value[0])
            onChangeProperty('heightTo', value[1])
          }}
        />
      </div>
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderNameField()}
        {this.renderElectricalRadiatorsSwitch()}
        {this.renderCategories()}
        {this.renderPowerSlider()}
        {this.renderWidthSlider()}
        {this.renderHeightSlider()}
      </div>
    )
  }
}

const styles = {
  container: {
    marginBottom: 70,
  },
  categoriesContainer: {
    marginBottom: 60,
  },
  knowledgeSwitchContainer: {
    marginBottom: 30,
    paddingTop: 10,
  },
  knowledgeSwitchLabel: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  sliderContainer: {
    marginBottom: 40,
  },
  textField: {
    paddingBottom: 8,
  },
  title: {
    marginBottom: 20,
  },
}

export default withTranslation('common')(withStyles(styles)(RadiatorForm))
