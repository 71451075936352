const generateUUIDPart = (): string => {
  const part: string = `${Math.floor(Math.random() * 100000)}`
  return part.padStart(6, '0')
}

const generateUUID = (): string => {
  const id = `${generateUUIDPart()}-${generateUUIDPart()}-${generateUUIDPart()}`
  console.log('generated id: ' + id)
  return id
}

export { generateUUID }
