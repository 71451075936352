import { createMuiTheme } from '@material-ui/core/styles'

import StyleGuide from './StyleGuide'

const palette = {
  primary: StyleGuide.Colors.primary,
  secondary: StyleGuide.Colors.secondary,
  dark: StyleGuide.Colors.dark,
  disabled: StyleGuide.Colors.disabled,
  background: StyleGuide.Colors.background,
}

const main = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1040,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: palette,
  typography: {
    useNextVariants: true,
    h2: {
      fontSize: 44,
      fontWeight: 200,
      lineHeight: 1.3,
      '@media (max-width:600px)': {
        fontSize: 30,
      },
    },
    h3: {
      fontSize: 32,
      fontWeight: 200,
      '@media (max-width:600px)': {
        fontSize: 24,
      },
    },
    h4: {
      fontSize: 32,
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: 24,
      },
    },
    h5: {
      fontSize: 28,
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: 20,
      },
    },
    h6: {
      fontSize: 24,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 22,
      fontWeight: 200,
    },
    caption: {
      fonSize: 12,
      color: palette.secondary.main,
    },
    overline: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
})

export default {
  main,
}
