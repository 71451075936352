import React from 'react'
import type { Node } from 'react'

import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

type InformationBoxProps = {
  classes: Object,
  className: string,
  children: Array<Node>,
  continuation: boolean,
}

class InformationBox extends React.Component<InformationBoxProps> {
  static defaultProps = {
    continuation: false,
  }

  renderContinuationIcon(): Node {
    const { continuation, classes } = this.props
    if (continuation) {
      return null
    }
    return (
      <div className={classes.icon}>
        <img src='/assets/ic_lightbulb_outline_24px.png' alt='bulb' />
      </div>
    )
  }

  render(): Node {
    const { children, classes, className, continuation } = this.props

    const contentClasses = classNames(
      classes.content,
      continuation ? classes.contentContinuation : null
    )
    const containerClasses = classNames(classes.container, className)

    return (
      <div className={containerClasses}>
        <div>{this.renderContinuationIcon()}</div>
        <div className={contentClasses}>{children}</div>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    borderRadius: 7,
    marginTop: 35,
  },
  content: {
    transform: 'translate(0px, -35px)',
    paddingLeft: 16,
    paddingRight: 16,
    width: '100%',
  },
  contentContinuation: {
    marginTop: 50,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 70,
    border: 'solid',
    backgroundColor: theme.palette.background.main,
    borderColor: 'white',
    borderRadius: 35,
    borderWidth: 5,
    transform: 'translate(0px, -35px)',
  },
})

export default withStyles(styles)(InformationBox)
