import React from 'react'
import type { Node } from 'react'

import { Typography, withStyles } from '@material-ui/core'

type InformationParagraphProps = {
  classes: Object,
  children: Node,
}

class InformationParagraph extends React.Component<InformationParagraphProps> {
  render(): Node {
    const { children, classes } = this.props
    return <Typography className={classes.label}>{children}</Typography>
  }
}

const styles = {
  label: {
    paddingTop: 16,
    fontSize: 18,
    fontWeight: 200,
  },
}

export default withStyles(styles)(InformationParagraph)
