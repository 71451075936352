/* global process */

class Api {
  static url: string =
    process.env.REACT_APP_API_URL ||
    `${window.location.protocol}//${window.location.hostname}`
  static port: string = process.env.REACT_APP_API_PORT || '80'
  static service: string = process.env.REACT_APP_API_SERVICE || 'api'

  static get(resource: string): any {
    const options = {
      method: 'GET',
    }

    return Api.getFetch(Api.buildPath() + resource, options).then(
      (response: any): any => response.json()
    )
  }

  static post(resource: string, data: Object): any {
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
    }

    return Api.getFetch(Api.buildPath() + resource, options)
  }

  static put(resource: string, data: Object): any {
    const options = {
      method: 'PUT',
      body: JSON.stringify(data),
    }

    return Api.getFetch(Api.buildPath() + resource, options)
  }

  static delete(resource: string): any {
    const options = {
      method: 'DELETE',
    }
    return Api.getFetch(Api.buildPath() + resource, options)
  }

  static getFetch(path: string, options: any): any {
    console.log(`${options.method} ${path}`)

    return fetch(path, {
      mode: 'cors',
      headers: Api.commonHeaders(),
      ...options,
    }).then((data: Object): Object => {
      if (data.status >= 300) {
        const error = Error(JSON.stringify({ data: { code: data.status } }))
        throw error
      }
      return data
    })
  }

  static buildPath(): string {
    console.log(Api.url)
    if (!Api.service || Api.service.length === 0) {
      if (!Api.port || Api.port === '80') {
        return `${Api.url}/`
      }
      return `${Api.url}:${Api.port}/`
    }
    if (!Api.port || Api.port === '80') {
      return `${Api.url}/${Api.service}/`
    }
    return `${Api.url}:${Api.port}/${Api.service}/`
  }

  static commonHeaders(): Object {
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Access-Control-Allow-Origin', '*')
    headers.append('Content-Type', 'application/json')
    return headers
  }
}

export default Api
