import React from 'react'
import type { Node } from 'react'

import { Slider, withStyles } from '@material-ui/core'

import ValueLabel from '../miscs/ValueLabel'

export type MarkType = {
  value: number,
  label: string,
}

type SingleSliderProps = {
  classes: Object,
  marks?: Array<any>,
  max?: number,
  min?: number,
  labelTransformation?: Function,
  onChange: Function,
  value: number,
}

class SingleSlider extends React.Component<SingleSliderProps> {
  static defaultProps = {
    max: 100,
    min: 0,
    labelTransformation: (value: string): string => value,
    onChange: () => {},
    value: 0,
  }

  onChange(value: number, event: Object) {
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render(): Node {
    const { classes, labelTransformation, marks, min, max, value } = this.props

    return (
      <Slider
        classes={{ root: classes.container, thumb: classes.thumb }}
        value={value}
        marks={marks}
        min={min}
        max={max}
        onChange={(event: Event, val: number): void => this.onChange(val)}
        aria-labelledby='slider'
        getAriaValueText={(v: string): string => 'aa'}
        valueLabelFormat={labelTransformation}
        valueLabelDisplay='on'
        ValueLabelComponent={ValueLabel}
      />
    )
  }
}

const styles = {
  container: {
    marginTop: 45,
  },
  thumb: {
    width: 29,
    height: 29,
    border: '4px solid white',
    bottom: -1,
  },
}

export default withStyles(styles)(SingleSlider)
