import React from 'react'
import type { Node } from 'react'

import classNames from 'classnames'

import { Button, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import LabelWithBackground from '../../components/labels/LabelWithBackground'
import CompanionContentContainer from '../../components/miscs/CompanionContentContainer'

type SpacesOverviewCompanionViewProps = {
  classes: Object,
  className: string,
  onApprove: Function,
  onProjectSummary: Function,
  projectCode: string,
  projectName?: string,
  radiatorsCount: number,
  t: Function,
  totalPower: number,
}

class SpacesOverviewCompanionView extends React.Component<SpacesOverviewCompanionViewProps> {
  static defaultProps = {
    projectName: '',
    radiatorsCount: 0,
    totalPower: 0,
  }

  onApprove() {
    const { onApprove } = this.props
    if (onApprove) {
      onApprove()
    }
  }

  onProjectSummary() {
    const { onProjectSummary } = this.props
    if (onProjectSummary) {
      onProjectSummary()
    }
  }

  renderPowerInfo(): Node {
    const { classes, t, totalPower } = this.props
    return (
      <React.Fragment>
        <Typography variant='caption'>
          {t('labels.totalRequiredPower')}
        </Typography>
        <Typography className={classes.label} variant='h5'>
          {`${totalPower} W`}
        </Typography>
      </React.Fragment>
    )
  }

  renderRadiatorsInfo(): Node {
    const { classes, radiatorsCount, t } = this.props
    return (
      <React.Fragment>
        <Typography variant='caption'>
          {`${t('labels.requiredRadiators')}`}
        </Typography>
        <Typography
          style={{ paddingBottom: 0 }}
          className={classes.label}
          variant='h5'>
          {radiatorsCount}
        </Typography>
      </React.Fragment>
    )
  }

  renderButtons(): Node {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Button
          className={classes.button}
          variant='outlined'
          color='inherit'
          onClick={(): void => this.onApprove()}>
          {t('labels.saveAndExit')}
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={(): void => this.onProjectSummary()}>
          {t('labels.projectSummary')}
        </Button>
      </React.Fragment>
    )
  }

  render(): Node {
    const { classes, className, projectCode, projectName, t } = this.props
    const containerClasses = classNames(className, classes.container)
    return (
      <div className={containerClasses}>
        <LabelWithBackground label={t('labels.summary')} />
        <CompanionContentContainer>
          <Typography variant='caption'>{t('labels.projectName')}</Typography>
          <Typography className={classes.label} variant='h3'>
            {projectName}
          </Typography>
          <Typography variant='caption'>{t('labels.projectCode')}</Typography>
          <Typography className={classes.label} variant='h3'>
            {projectCode}
          </Typography>
          {this.renderPowerInfo()}
          {this.renderRadiatorsInfo()}
          {this.renderButtons()}
        </CompanionContentContainer>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    marginTop: 70,
    position: 'sticky',
    top: 0,
  },
  button: {
    marginTop: 30,
    width: '100%',
  },
  contentContainer: {
    marginTop: 25,
    paddingLeft: 25,
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 17,
    color: 'white',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.light,
    padding: '20px 40px',
    marginBottom: 20,
    marginTop: 15,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'red',
    marginTop: 90,
  },
  label: {
    paddingTop: 5,
    paddingBottom: 20,
  },
  logoLabel: {
    textAlign: 'center',
    color: theme.palette.secondary.light,
  },
  codeViewer: {
    marginTop: 40,
  },
})

export default withTranslation('common')(
  withStyles(styles)(SpacesOverviewCompanionView)
)
