import React from 'react'
import type { Node } from 'react'

import { TableCell, TableRow, Typography, withStyles } from '@material-ui/core'

import classNames from 'classnames'

import RadiatorItem from '../../../../models/RadiatorItem'
import Radiator from '../../../../models/Radiator'

type ShoppingItemProps = {
  classes: Object,
  index: number,
  data: Radiator,
  selected: boolean,
}
class ShoppingItem extends React.Component<ShoppingItemProps> {
  static defaultProps = {
    selected: false,
  }

  renderColorViewer(color: string | number): Node {
    const { classes } = this.props
    return (
      <div className={classes.colorViewerContainer}>
        <Typography className={classes.cell}>{color}</Typography>
        <div className={classes.colorIndicator} />
      </div>
    )
  }

  render(): Node {
    const { classes, index, data, selected } = this.props
    const item: RadiatorItem | null = data && data.item

    const itemClasses = classNames(
      index % 2 === 0 ? classes.evenRow : classes.oddRow,
      selected ? classes.selectedRow : null
    )

    return (
      <React.Fragment>
        <TableRow
          classes={{
            root: itemClasses,
          }}>
          <TableCell className={classes.cell} rowSpan={2} align='left'>
            <div className={classes.indexBox}>
              <Typography style={{ fontWeight: 500 }}>{index + 1}</Typography>
            </div>
          </TableCell>
          <TableCell className={classes.cell} align='right'>
            <Typography className={classes.cellLabel}>
              {item && item.power}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell} align='right'>
            <Typography className={classes.cellLabel}>
              {item && item.type}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell} align='right'>
            <div className={classes.rowCell}>
              <img
                className={classes.arrow}
                src={
                  selected
                    ? '/assets/width_arrow_table_opaque.png'
                    : '/assets/width_arrow_table.png'
                }
                alt='width_arrow_table'
              />
              <Typography className={classes.cellLabel}>
                {item && item.width}
              </Typography>
            </div>
          </TableCell>
          <TableCell className={classes.cell} align='right'>
            <div className={classes.rowCell}>
              <img
                className={classes.arrow}
                src={
                  selected
                    ? '/assets/height_arrow_table_opaque.png'
                    : '/assets/height_arrow_table.png'
                }
                alt='height_arrow_table'
              />
              <Typography className={classes.cellLabel}>
                {item && item.height}
              </Typography>
            </div>
          </TableCell>
          <TableCell className={classes.cell} align='right'>
            {this.renderColorViewer((item && item.color) || '')}
          </TableCell>
          <TableCell className={classes.cell} align='right'>
            <Typography className={classes.cellLabelBold}>
              {item && item.price}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell} align='right'>
            <Typography className={classes.cellLabel}>
              {item && item.itemNumber}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow
          classes={{
            root: itemClasses,
          }}>
          <TableCell className={classes.cell} colSpan={7}>
            <Typography className={classes.cellNameLabel}>
              {item && item.name}
            </Typography>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
}

const styles = (theme: Object): Object => ({
  cell: {
    border: '0px solid rgba(224, 224, 224, 1)',
  },
  cellLabel: {
    fontSize: 22,
    fontWeight: 200,
  },
  cellLabelBold: {
    fontSize: 22,
    fontWeight: 500,
  },
  cellNameLabel: {
    fontSize: 22,
    fontWeight: 200,
    paddingLeft: 22,
  },
  colorViewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  colorIndicator: {
    width: 27,
    height: 27,
    borderRadius: 3,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'white',
    marginLeft: 10,
  },
  rowCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  arrow: {
    height: 'fit-content',
    width: 'fit-content',
    marginRight: 5,
    marginTop: 1,
  },
  evenRow: {
    backgroundColor: theme.palette.background.default,
  },
  oddRow: {
    backgroundColor: theme.palette.background.main,
  },
  indexBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 6,
    backgroundColor: 'white',
  },
})

export default withStyles(styles)(ShoppingItem)
