import React from 'react'
import ReactDOM from 'react-dom'

import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enLocale from './locale/en/common'
import plLocale from './locale/pl/common'
import enTemplatesLocale from './locale/en/templates'
import plTemplatesLocale from './locale/pl/templates'

import Application from './Application'
import rootReducer from './reducers'

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

i18n.use(initReactI18next).init({
  languages: ['en', 'pl'],
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {
      common: enLocale,
      templates: enTemplatesLocale,
    },
    pl: {
      common: plLocale,
      templates: plTemplatesLocale,
    },
  },
  interpolation: {
    escapeValue: false,
  },
})

const container = document.getElementById('root')
if (container) {
  ReactDOM.render(
    <Provider store={store}>
      <Application />
    </Provider>,
    container
  )
}
