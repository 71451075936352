import React from 'react'
import type { Node } from 'react'

import {
  Radio,
  TableCell,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'

import classNames from 'classnames'

import RadiatorItem from '../../../../models/RadiatorItem'

type RadiatorItemItemProps = {
  classes: Object,
  index: number,
  item: RadiatorItem,
  onSelect: Function,
  selected: boolean,
}
class RadiatorItemItem extends React.Component<RadiatorItemItemProps> {
  static defaultProps = {
    selected: false,
  }

  renderColorViewer(color: string | number): Node {
    const { classes } = this.props
    return (
      <div className={classes.colorViewerContainer}>
        <Typography className={classes.cell}>{color}</Typography>
        <div className={classes.colorIndicator} />
      </div>
    )
  }

  render(): Node {
    const { classes, index, item, onSelect, selected } = this.props
    const itemClasses = classNames(
      index % 2 === 0 ? classes.evenRow : classes.oddRow,
      selected ? classes.selectedRow : null
    )

    return (
      <TableRow
        onClick={onSelect}
        classes={{
          root: itemClasses,
          selected: classes.selectedRow,
        }}>
        <TableCell align='center'>
          <Radio
            classes={{
              checked: classes.selectedRadioEmpty,
            }}
            color='primary'
            checked={selected}
          />
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.cell}>{item.power}</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.cell}>{item.type}</Typography>
        </TableCell>
        <TableCell align='right'>
          <div className={classes.rowCell}>
            <img
              className={classes.arrow}
              src={
                selected
                  ? '/assets/width_arrow_table_opaque.png'
                  : '/assets/width_arrow_table.png'
              }
              alt='width_arrow_table'
            />
            <Typography className={classes.cell}>{item.width}</Typography>
          </div>
        </TableCell>
        <TableCell align='right'>
          <div className={classes.rowCell}>
            <img
              className={classes.arrow}
              src={
                selected
                  ? '/assets/height_arrow_table_opaque.png'
                  : '/assets/height_arrow_table.png'
              }
              alt='height_arrow_table'
            />
            <Typography className={classes.cell}>{item.height}</Typography>
          </div>
        </TableCell>
        <TableCell align='right'>
          {this.renderColorViewer(item.color)}
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.cellBold}>{item.price}</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.cell}>{item.itemNumber}</Typography>
        </TableCell>
      </TableRow>
    )
  }
}

const styles = (theme: Object): Object => ({
  cell: {
    fontSize: 22,
    fontWeight: 200,
  },
  cellBold: {
    fontSize: 22,
    fontWeight: 500,
  },
  colorViewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  colorIndicator: {
    width: 27,
    height: 27,
    borderRadius: 3,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'white',
    marginLeft: 10,
  },
  rowCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  arrow: {
    height: 'fit-content',
    width: 'fit-content',
    marginRight: 5,
    marginTop: 1,
  },
  evenRow: {
    backgroundColor: theme.palette.background.default,
  },
  oddRow: {
    backgroundColor: theme.palette.background.main,
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
  },

  selectedRadio: {
    '&$checked': {
      color: 'white',
    },
  },
  selectedRadioEmpty: {
    color: 'white !important',
  },
})

export default withStyles(styles)(RadiatorItemItem)
