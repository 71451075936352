import RadiatorModel from '../models/RadiatorModel'
const SettingActionType = {
  SETTING_SET: 'SETTING_SET',
  SETTING_SET_NAME: 'SETTING_SET_NAME',
  SETTING_SET_CODE: 'SETTING_SET_CODE',
  SETTING_SET_EMAIL: 'SETTING_SET_EMAIL',
  SETTING_SET_MY_MODELS: 'SETTING_SET_MY_MODELS',
}

type SetAction = {|
  type: typeof SettingActionType.SETTING_SET,
  name: string,
  code: string,
  email: string,
|}

type SetNameAction = {|
  type: typeof SettingActionType.SETTING_SET_NAME,
  name: string,
|}

type SetCodeAction = {|
  type: typeof SettingActionType.SETTING_SET_CODE,
  code: string,
|}

type SetEmailAction = {|
  type: typeof SettingActionType.SETTING_SET_EMAIL,
  email: string,
|}

type SetMyModelsAction = {|
  type: typeof SettingActionType.SETTING_SET_MY_MODELS,
  models: Array<RadiatorModel>,
|}

export type SettingAction =
  | SetAction
  | SetNameAction
  | SetCodeAction
  | SetEmailAction
  | SetMyModelsAction

const setSettings = (name: string, code: string, email: string): Object => {
  return {
    type: SettingActionType.SETTING_SET,
    name,
    code,
    email,
  }
}

const setNameSetting = (name: string): Object => {
  return {
    type: SettingActionType.SETTING_SET_NAME,
    name,
  }
}

const setCodeSetting = (code: string): Object => {
  return {
    type: SettingActionType.SETTING_SET_CODE,
    code,
  }
}

const setEmailSetting = (email: string): Object => {
  return {
    type: SettingActionType.SETTING_SET_EMAIL,
    email,
  }
}

const setModels = (models: Array<RadiatorModel>): Object => {
  return {
    type: SettingActionType.SETTING_SET_MY_MODELS,
    models,
  }
}

const setMyModels = (models: Array<RadiatorModel>): Object => {
  return (dispatch: Function, getState: Function) => {
    dispatch(setModels(models))
  }
}

export {
  SettingActionType,
  setSettings,
  setNameSetting,
  setCodeSetting,
  setEmailSetting,
  setMyModels,
}
