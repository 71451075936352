import React from 'react'
import type { Node } from 'react'

import classNames from 'classnames'

import { Grid, Typography, withStyles } from '@material-ui/core'

type GridItemBaseProps = {
  children?: Array<Node>,
  classes: Object,
  className?: string,
  disabled?: boolean,
  onClose?: Function,
  title?: string,
}

class GridItemBase extends React.Component<GridItemBaseProps> {
  static defaultProps = {
    disabled: false,
  }

  renderTitle(): Node {
    const { classes, title } = this.props
    if (!title) {
      return null
    }
    return (
      <div className={classes.titleContainer}>
        <Typography variant='h4' className={classes.title}>
          {title}
        </Typography>
      </div>
    )
  }

  renderCloseButton(): Node {
    const { classes, onClose } = this.props
    if (!onClose) {
      return null
    }
    return (
      <div className={classes.closeContainer} onClick={onClose}>
        <img src='/assets/ic_close.png' alt='ic_close' />
      </div>
    )
  }

  render(): Node {
    const { children, classes, className, disabled } = this.props
    const containerClasses = classNames(
      classes.container,
      disabled ? classes.containerDisabled : classes.containerEnabled,
      className
    )
    return (
      <Grid className={classes.gridContainer} item md={6} xs={12}>
        {this.renderTitle()}
        {this.renderCloseButton()}
        <div className={containerClasses}>{children}</div>
      </Grid>
    )
  }
}

const styles = (theme: Object): Object => ({
  gridContainer: {
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    width: '100%',
    height: '100%',
  },
  closeContainer: {
    position: 'absolute',
    right: 7,
    marginTop: -18,
    padding: '6px 6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    borderRadius: 25,
  },
  titleContainer: {
    position: 'absolute',
    marginTop: -16,
    padding: '2px 20px',
    marginLeft: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: 'white',
  },
  containerEnabled: {
    border: `10px solid ${theme.palette.secondary.lighter}`,
  },
  containerDisabled: {
    border: `10px solid ${theme.palette.disabled.lighter}`,
  },
})

export default withStyles(styles)(GridItemBase)
