import React from 'react'
import type { Node } from 'react'

import { TextField, withStyles } from '@material-ui/core'

import classNames from 'classnames'

type DropDownProps = {
  className?: string,
  classes: Object,
  data: Array<Object>,
  error: boolean,
  hint?: string,
  label: string,
  onChange?: Function,
  value?: number | string,
}

class DropDown extends React.Component<DropDownProps> {
  static defaultProps = {
    data: [{ label: 'aa', value: 1 }, { label: 'bb', value: 2 }],
    error: false,
    onChange: () => {},
  }

  onChange(event: Object) {
    const { onChange } = this.props
    const newValue: number = event.target.value

    if (onChange) {
      onChange(newValue)
    }
  }

  render(): Node {
    const { className, classes, data, error, hint, label, value } = this.props
    const fieldClasses = classNames(classes.textField, className)

    return (
      <TextField
        error={error}
        id='outlined-select-currency-native'
        select
        label={label}
        value={value}
        onChange={(event: Object): void => this.onChange(event)}
        className={fieldClasses}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu,
          },
        }}
        helperText={hint}
        margin='dense'
        variant='outlined'>
        {data.map((option: Object, idx: number): Node => (
          <option key={idx} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    )
  }
}

const styles = {
  menu: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
}

export default withStyles(styles)(DropDown)
