import React from 'react'
import type { Node } from 'react'

import {
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames'

type RadiatorConnectionsSelectProps = {
  classes: Object,
  className: string,
  type: string,
  disabled: boolean,
  t: Function,
}

type RadiatorConnectionsSelectState = {
  topLeft: boolean,
  top: boolean,
  topRight: boolean,
  rightTop: boolean,
  rightBottom: boolean,
  bottomRight: boolean,
  bottom: boolean,
  bottomLeft: boolean,
  leftBottom: boolean,
  leftTop: boolean,
}

class RadiatorConnectionsSelect extends React.Component<
  RadiatorConnectionsSelectProps,
  RadiatorConnectionsSelectState
> {
  state = {
    topLeft: true,
    top: true,
    topRight: true,
    rightTop: true,
    rightBottom: true,
    bottomRight: true,
    bottom: true,
    bottomLeft: true,
    leftBottom: true,
    leftTop: true,
  }

  static defaultProps = {
    type: 'in',
  }

  onChangeConnectionState(id: string, newState: boolean) {
    switch (id) {
      case '1':
        this.setState({ bottomLeft: newState })
        break
      case '2':
        this.setState({ leftBottom: newState })
        break
      case '3':
        this.setState({ leftTop: newState })
        break
      case '4':
        this.setState({ topLeft: newState })
        break
      case 'MO':
        this.setState({ top: newState })
        break
      case '5':
        this.setState({ topRight: newState })
        break
      case '6':
        this.setState({ rightTop: newState })
        break
      case '7':
        this.setState({ rightBottom: newState })
        break
      case '8':
        this.setState({ bottomRight: newState })
        break
      case 'MB':
        this.setState({ bottom: newState })
        break
      default:
        return
    }
  }

  getConnectionState(id: string): boolean {
    switch (id) {
      case '1':
        return this.state.bottomLeft
      case '2':
        return this.state.leftBottom
      case '3':
        return this.state.leftTop
      case '4':
        return this.state.topLeft
      case 'MO':
        return this.state.top
      case '5':
        return this.state.topRight
      case '6':
        return this.state.rightTop
      case '7':
        return this.state.rightBottom
      case '8':
        return this.state.bottomRight
      case 'MB':
        return this.state.bottom
      default:
        return false
    }
  }

  renderRadiator(): Node {
    const { classes, disabled, t } = this.props
    const radiatorClasses = classNames(
      classes.radiator,
      disabled ? classes.disabledRadiator : null
    )
    const labelClasses = classNames(
      classes.label,
      disabled ? classes.disabledLabel : null
    )
    return (
      <div className={radiatorClasses}>
        <Typography className={labelClasses} variant='h6'>
          {t('labels.frontOfRadiator').toUpperCase()}
        </Typography>
      </div>
    )
  }

  renderCheckboxIcon(id: string, disabled: boolean = false): Node {
    const { type } = this.props
    let path: string = ''
    switch (id) {
      case '1':
      case '8':
      case 'MB':
        path = `/assets/arrow_${type}_bottom${disabled ? '_disabled' : ''}.png`
        break
      case '4':
      case '5':
      case 'MO':
        path = `/assets/arrow_${type}_top${disabled ? '_disabled' : ''}.png`
        break
      case '6':
      case '7':
        path = `/assets/arrow_${type}_right${disabled ? '_disabled' : ''}.png`
        break
      case '2':
      case '3':
        path = `/assets/arrow_${type}_left${disabled ? '_disabled' : ''}.png`
        break
      default:
        break
    }

    return <img src={path} alt={disabled ? 'unchecked' : 'checked'} />
  }

  renderSelectionCheckbox(id: string, checkboxClass: string): Node {
    const { disabled } = this.props
    return (
      <FormControlLabel
        className={checkboxClass}
        control={
          <Checkbox
            disabled={disabled}
            checked={this.getConnectionState(id)}
            onChange={(event: Object): void =>
              this.onChangeConnectionState(id, event.target.checked)
            }
            color='primary'
            icon={this.renderCheckboxIcon(id, true)}
            checkedIcon={this.renderCheckboxIcon(id, disabled)}
            value={id}
          />
        }
      />
    )
  }

  render(): Node {
    const { className, classes } = this.props
    return (
      <div className={classNames(classes.container, className)}>
        {this.renderRadiator()}
        {this.renderSelectionCheckbox('4', classes.topLeft)}
        {this.renderSelectionCheckbox('MO', classes.top)}
        {this.renderSelectionCheckbox('5', classes.topRight)}

        {this.renderSelectionCheckbox('6', classes.rightTop)}
        {this.renderSelectionCheckbox('7', classes.rightBottom)}

        {this.renderSelectionCheckbox('8', classes.bottomRight)}
        {this.renderSelectionCheckbox('MB', classes.bottom)}
        {this.renderSelectionCheckbox('1', classes.bottomLeft)}

        {this.renderSelectionCheckbox('2', classes.leftBottom)}
        {this.renderSelectionCheckbox('3', classes.leftTop)}
      </div>
    )
  }
}

const edgeDistance: number = -2
const distance: number = 50

const styles = (theme: Object): Object => ({
  container: {
    position: 'relative',
    paddingTop: 1,
    paddingBottom: 1,
    maxWidth: 400,
  },
  radiator: {
    border: `4px solid ${theme.palette.secondary.lighter}`,
    borderRadius: 10,
    width: 'calc(100% - 90px)',
    height: 160,
    margin: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledRadiator: {
    border: `4px solid ${theme.palette.disabled.lighter}`,
  },
  label: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.secondary.light,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  disabledLabel: {
    color: theme.palette.disabled.lighter,
  },
  topRight: {
    position: 'absolute',
    top: edgeDistance,
    right: distance - 16,
  },
  top: {
    position: 'absolute',
    top: edgeDistance,
    left: '50%',
    marginLeft: -22,
  },
  topLeft: {
    position: 'absolute',
    top: edgeDistance,
    left: distance + 11,
  },
  rightTop: {
    position: 'absolute',
    top: distance,
    right: edgeDistance - 16,
  },
  rightBottom: {
    position: 'absolute',
    bottom: distance,
    right: edgeDistance - 16,
  },
  bottomRight: {
    position: 'absolute',
    bottom: edgeDistance,
    right: distance - 16,
  },
  bottom: {
    position: 'absolute',
    bottom: edgeDistance,
    left: '50%',
    marginLeft: -22,
  },
  bottomLeft: {
    position: 'absolute',
    bottom: edgeDistance,
    left: distance + 11,
  },
  leftTop: {
    position: 'absolute',
    top: distance,
    left: edgeDistance + 11,
  },
  leftBottom: {
    position: 'absolute',
    bottom: distance,
    left: edgeDistance + 11,
  },
})

export default withTranslation('common')(
  withStyles(styles)(RadiatorConnectionsSelect)
)
