class RadiatorModel {
  categoryName: string
  id: string
  name: string
  shortName: string
  slug: string
  image: string
  thumbnail: string
  description: string

  constructor(data: Object = {}) {
    this.categoryName = data.category_name || ''
    this.id = data.product_id || ''
    this.name = data.product_name || ''
    this.shortName = data.product_name_short || ''
    this.slug = data.product_slug || ''
    this.image = data.product_image || ''
    this.thumbnail = data.product_image_min || ''
    this.description =
      data.description ||
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  }

  toJSON(): Object {
    const json = {
      category_name: this.categoryName,
      product_id: this.id,
      product_name: this.name,
      product_name_short: this.shortName,
      product_slug: this.slug,
      product_image: this.image,
      product_image_min: this.thumbnail,
      description: this.description,
    }
    return json
  }
}

export default RadiatorModel
