import React from 'react'
import type { Node } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import RadiatorItemItem from './items/RadiatorItemItem'

import RadiatorItem from '../../../models/RadiatorItem'

type ItemsTableProps = {
  classes: Object,
  data: Array<RadiatorItem>,
  selectedItem: RadiatorItem | null,
  onSelect: Function,
  t: Function,
}

class ItemsTable extends React.Component<ItemsTableProps> {
  static defaultProps = {
    onSelect: () => {},
  }

  onSelect(index: number) {
    const { data, onSelect } = this.props
    onSelect(data[index])
  }

  renderHeads(): Node {
    const { classes, t } = this.props
    return (
      <TableRow>
        <TableCell align='center'>
          <Typography className={classes.head}>
            {t('labels.item.state')}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {`${t('labels.item.power')} [W]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {t('labels.item.type')}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {`${t('labels.item.width')} [mm]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {`${t('labels.item.height')} [mm]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {t('labels.item.color')}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.headBold}>
            {`${t('labels.item.price')} [€]`}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography className={classes.head}>
            {t('labels.item.number')}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  renderCells(): Array<Node> {
    const { data, selectedItem } = this.props

    return data.map((item: RadiatorItem, idx: number): Node => {
      return (
        <RadiatorItemItem
          selected={item.id === (selectedItem && selectedItem.id)}
          item={item}
          key={idx}
          index={idx}
          onSelect={(): void => this.onSelect(idx)}
        />
      )
    })
  }
  render(): Node {
    return (
      <div>
        <Table size={'small'}>
          <TableHead>{this.renderHeads()}</TableHead>
          <TableBody>{this.renderCells()}</TableBody>
        </Table>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {},
  head: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontWeight: 200,
  },
  headBold: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontWeight: 500,
  },
})

export default withStyles(styles)(withTranslation('common')(ItemsTable))
