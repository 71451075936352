const colors = {
  primary: {
    main: '#EE7101',
  },
  secondary: {
    main: '#898989',
    light: '#B9B9B9',
    lighter: '#DEDEDE',
  },
  dark: {
    main: '#202020',
  },
  disabled: {
    main: '#DFDFDF',
    lighter: '#EDEDED',
  },
  background: {
    main: '#F4F3F4',
    default: '#FFFFFF',
  },
}

const StyleGuide = {
  Colors: colors,
}

export default StyleGuide
