import React from 'react'
import type { Node } from 'react'

import { TextField, withStyles } from '@material-ui/core'

import classNames from 'classnames'

type TextFieldProps = {
  className?: string,
  classes: Object,
  disabled?: boolean,
  error: boolean,
  hint: string,
  numeric?: boolean,
  onChange?: Function,
  label: string,
  type: string,
  value?: string,
}

class OutlinedTextField extends React.Component<TextFieldProps> {
  static defaultProps = {
    disabled: false,
    error: false,
    numeric: false,
    onChange: () => {},
    value: '',
  }

  onChange(value: string) {
    const { numeric, onChange } = this.props

    if (!onChange) {
      return
    }

    if (numeric) {
      var regexp = /^\d+((.|,)\d{0,2})?$/

      if (value.length === 0) {
        onChange('')
      } else if (regexp.test(value)) {
        onChange(value)
      }
    } else {
      onChange(value)
    }
  }

  render(): Node {
    const {
      className,
      classes,
      disabled,
      error,
      label,
      hint,
      type,
      value,
    } = this.props

    const fieldClasses = classNames(
      classes.container,
      className,
      disabled ? classes.disabled : null
    )
    return (
      <TextField
        error={error}
        disabled={disabled}
        color='primary'
        className={fieldClasses}
        id='text-field'
        margin='dense'
        label={label}
        value={(value && value.toString()) || ''}
        type={type}
        helperText={hint}
        onChange={(event: Object): void => this.onChange(event.target.value)}
        FormHelperTextProps={{
          classes: {
            root: classes.rootLabel,
            disabled: classes.disabledLabel,
          },
        }}
        InputProps={{
          classes: {
            notchedOutline: disabled ? classes.disabledNotchedField : null,
          },
        }}
        variant='outlined'
      />
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    width: '100%',
    marginTop: 8,
    paddingBottom: 24,
  },
  disabledNotchedField: {
    borderColor: `${theme.palette.disabled.main} !important`,
  },
  rootLabel: {
    '&$disabledLabel': {
      color: theme.palette.disabled.main,
    },
  },
  disabledLabel: {},
  disabled: {
    '& label.Mui-disabled': {
      color: theme.palette.disabled.main,
    },
  },
})

export default withStyles(styles)(OutlinedTextField)
