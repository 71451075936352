import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import Api from '../api/Api'

import {
  Button,
  Divider,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames'

import ProjectSummaryCompanionView from './companion/ProjectSummaryCompanionView'
import OutlinedTextField from '../components/inputs/OutlinedTextField'
import EmailFeedbackDialog from '../components/dialogs/EmailFeedbackDialog'

import { clearMailSent, sendMail } from '../actions/EmailActions'

import Space from '../models/Space'
import Radiator from '../models/Radiator'

import { choiceRequestTemplate } from '../constants/mailTemplates'

import {
  allRadiators,
  amountOfRadiators,
  amountOfSelectedModels,
} from '../selectors/spaceSelector'

import { validateEmail } from '../utils/Validators'

type ProjectSummaryViewProps = {
  classes: Object,
  clearMailSent: Function,
  currentSpace: Space,
  emailSent: boolean,
  history: Object,
  project: Object,
  radiators: Array<Radiator>,
  sendMail: Function,
  shoppingItemsCount: number,
  spaces: Array<Space>,
  t: Function,
}

type ProjectSummaryViewState = {
  mail: string,
  errors: Object,
}

class ProjectSummaryView extends React.Component<
  ProjectSummaryViewProps,
  ProjectSummaryViewState
> {
  state = {
    mail: '',
    errors: {
      mail: false,
    },
  }

  onBackToSpaces() {
    const { history } = this.props
    history.goBack()
  }

  onGoToSelectionMode() {
    const { project } = this.props
    window.location.href = `https://radiator2.webservice.pl/inspiration/start?isInstaller=true&projectId=${project.code}`
  }

  onShowFinalList() {
    const { history } = this.props
    history.push('/project/shopping-list')
  }

  onMailChange(newValue: string) {
    this.setState({
      mail: newValue,
      errors: { ...this.state.errors, mail: false },
    })
  }

  onSendRequest() {
    const { project, sendMail, t } = this.props
    const { mail } = this.state
    const emailError: boolean = !validateEmail(mail)

    if (emailError) {
      this.setState({ errors: { mail: emailError } })
      return
    }

    sendMail(
      mail,
      t('templates:choiceRequest'),
      choiceRequestTemplate(t, project.code)
    )
  }

  onEmailFeedbackClose() {
    const { clearMailSent } = this.props
    clearMailSent()
  }

  renderProjectSummary(): Node {
    const { classes, project, shoppingItemsCount, spaces, t } = this.props

    return (
      <div className={classes.summaryContainer}>
        <Typography className={classes.fieldTitle}>
          {t('labels.projectName')}
        </Typography>
        <Divider className={classes.divider} />
        <Typography variant='h3'>{project && project.name}</Typography>
        <Grid className={classes.projectGrid} container spacing={6}>
          <Grid item sm={12} md={3}>
            <Typography className={classes.fieldTitle}>
              {t('labels.projectCode')}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant='h3'>{project && project.code}</Typography>
          </Grid>
          <Grid item sm={12} md={9}>
            <Typography className={classes.fieldTitle}>
              {t('labels.projectStatus')}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant='h4' color='primary'>
              Radiator parameters completed
            </Typography>
          </Grid>
        </Grid>
        <Typography
          style={{ textAlign: 'center' }}
          variant='subtitle1'
          color='secondary'>
          {t('labels.radiatorsInProject')}
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={0}>
          <Grid item md={4} sm={12}>
            <Typography
              className={classNames(classes.centeredTitle, classes.fieldTitle)}>
              {t('labels.addedRadiators')}
            </Typography>
            <div className={classes.fieldValueContainer}>
              <Typography
                className={classNames(
                  classes.fieldValueWithDivider,
                  classes.fieldValue
                )}
                variant='h5'>
                {amountOfRadiators(spaces)}
              </Typography>
              <div className={classes.dividerBox} />
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <Typography
              className={classNames(classes.centeredTitle, classes.fieldTitle)}>
              {t('labels.selectedModels')}
            </Typography>
            <div className={classes.fieldValueContainer}>
              <Typography
                className={classNames(
                  classes.fieldValueWithDivider,
                  classes.fieldValue
                )}
                variant='h5'
                color='primary'>
                {amountOfSelectedModels(spaces)}
              </Typography>
              <div className={classes.dividerBox} />
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <Typography
              className={classNames(classes.centeredTitle, classes.fieldTitle)}>
              {t('labels.selectedItems')}
            </Typography>
            <Typography
              className={classes.fieldValue}
              variant='h5'
              color='primary'>
              {shoppingItemsCount}
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderRequestSection(): Node {
    const { classes, t } = this.props
    const { mail, errors } = this.state
    return (
      <div className={classes.requestContainer}>
        <Typography className={classes.requestTitle} variant='h5'>
          {t('labels.sendRequestToCustomer')}
        </Typography>
        <OutlinedTextField
          error={errors.mail}
          label={t('labels.enterRecipientsMails')}
          hint={t('hints.sendRequest')}
          value={mail}
          onChange={(newValue: string): void => this.onMailChange(newValue)}
        />
        <Grid container spacing={6}>
          <Grid item sm={12} md={4} />
          <Grid item sm={12} md={4}>
            <Button
              disabled
              className={classes.button}
              variant='outlined'
              color='inherit'>
              {t('labels.previewRequest')}
            </Button>
          </Grid>
          <Grid item sm={12} md={4}>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={(): void => this.onSendRequest()}>
              {t('labels.sendRequest')}
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderEmailFeedbackDialog(): Node {
    const { emailSent } = this.props
    return (
      <EmailFeedbackDialog
        isOpen={emailSent}
        onAgree={(): void => this.onEmailFeedbackClose()}
      />
    )
  }

  renderContent(): Node {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h3'>
          {t('titles.projectSummary')}
        </Typography>
        {this.renderProjectSummary()}
        {this.renderRequestSection()}
        {this.renderEmailFeedbackDialog()}
      </React.Fragment>
    )
  }

  renderCompanionView(): Node {
    const { shoppingItemsCount } = this.props
    return (
      <ProjectSummaryCompanionView
        onBackToSpaces={(): void => this.onBackToSpaces()}
        onGoToSelectionMode={(): void => this.onGoToSelectionMode()}
        onShowFinalList={(): void => this.onShowFinalList()}
        isShowFinalListDisabled={shoppingItemsCount === 0}
      />
    )
  }

  render(): Node {
    return (
      <div>
        <Grid container spacing={8}>
          <Grid item sm={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item sm={4} xs={12}>
            {this.renderCompanionView()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  summaryContainer: {
    marginTop: 30,
  },
  requestContainer: {
    marginTop: 80,
  },
  divider: {
    marginTop: 3,
    marginBottom: 7,
  },
  title: {
    paddingBottom: 50,
  },
  centeredTitle: {
    textAlign: 'center',
  },
  fieldTitle: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
  requestTitle: {
    marginBottom: 15,
  },
  fieldValue: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  fieldValueWithDivider: {
    width: '100%',
  },
  dividerBox: {
    borderRight: `1px solid ${theme.palette.secondary.light}`,
    height: 50,
  },
  fieldValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  projectGrid: {
    marginTop: 10,
    marginBottom: 60,
  },
  button: {
    width: '100%',
  },
})

const mapStateToProps = (state: Object): Object => {
  return {
    currentSpace: state.spaces.current,
    emailSent: state.email.isSent,
    spaces: state.spaces.all,
    radiators: state.radiators.all,
    project: state.settings.project,
    shoppingItemsCount: allRadiators(state.spaces.all).filter(
      (item: Radiator): boolean => {
        return item.isItemSelected
      }
    ).length,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = { clearMailSent, sendMail }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation(['common', 'templates'])(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ProjectSummaryView)
  )
)
