import Space from '../models/Space'
import { updateProject } from './ProjectActions'

import SpaceApi from '../api/SpaceApi'

import Building from '../models/Building'

const SpaceActionType = {
  SPACE_SET: 'SPACE_SET',
  SPACE_ADD: 'SPACE_ADD',
  SPACE_UPDATE: 'SPACE_UPDATE',
  SPACE_CALCULATE_POWER: 'SPACE_CALCULATE_POWER',
  SPACE_SET_CURRENT: 'SPACE_SET_CURRENT',
  SPACE_CLEAR_CURRENT: 'SPACE_CLEAR_CURRENT',
}

type SetSpacesAction = {|
  type: typeof SpaceActionType.SPACE_SET,
  spaces: Array<Space>,
|}

type AddSpaceAction = {|
  type: typeof SpaceActionType.SPACE_ADD,
  space: Space,
|}

type SetCurrentSpaceAction = {|
  type: typeof SpaceActionType.SPACE_SET_CURRENT,
  space: Space,
|}

type ClearCurrentSpaceAction = {|
  type: typeof SpaceActionType.SPACE_CLEAR_CURRENT,
|}

type UpdateSpaceAction = {|
  type: typeof SpaceActionType.SPACE_UPDATE,
  space: Space,
|}

export type SpaceAction =
  | SetSpacesAction
  | AddSpaceAction
  | UpdateSpaceAction
  | SetCurrentSpaceAction
  | ClearCurrentSpaceAction

const calculateSpacePowerAndUpdate = (space: Space): Function => {
  return (dispatch: Function, getState: Function) => {
    const building: Building = getState().building.current
    const calculationParams: Object = prepareCalculationParams(
      space,
      building || new Building()
    )

    SpaceApi.calculate(calculationParams).then((power: number) => {
      space.requestedPower = power
      space.isCalculated = true
      dispatch(updateSpace(space))
    })
  }
}

const calculateSpacePowerAndAdd = (space: Space): Function => {
  return (dispatch: Function, getState: Function) => {
    const building: Building = getState().building.current
    const calculationParams: Object = prepareCalculationParams(
      space,
      building || new Building()
    )

    SpaceApi.calculate(calculationParams).then((power: number) => {
      space.requestedPower = power
      space.isCalculated = true
      dispatch(addSpace(space))
    })
  }
}

const setSpaces = (spaces: Array<Space>): SpaceAction => {
  return {
    type: SpaceActionType.SPACE_SET,
    spaces: spaces,
  }
}

const addSpace = (space: Space): Function => {
  return (dispatch: Function) => {
    dispatch({
      type: SpaceActionType.SPACE_ADD,
      space: space,
    })
    dispatch(updateProject())
  }
}

const updateSpace = (space: Space): Function => {
  return (dispatch: Function) => {
    dispatch({
      type: SpaceActionType.SPACE_UPDATE,
      space: space,
    })
    dispatch(updateProject())
  }
}

const setCurrentSpace = (space: Space): SpaceAction => {
  return {
    type: SpaceActionType.SPACE_SET_CURRENT,
    space: space,
  }
}

const clearCurrentSpace = (space: Space): SpaceAction => {
  return {
    type: SpaceActionType.SPACE_CLEAR_CURRENT,
  }
}

const prepareCalculationParams = (space: Space, building: Building): Object => {
  const calculationParams: Object = {
    location: building.buildingLocation,
    storeys: space.storeys,
    windCondition: building.windConditions,
    insulation: space.insulationQuality,
    roomType: space.type.localeCompare('1') === 0 ? '73' : space.type,
    externalWall: space.storeys,
    glazingPercentage: space.glazingQuality,
    glazingSurface: space.glazingSurface,
    roomHeight: parseInt(space.height),
    roomWidth: parseInt(space.width),
    roomLength: parseInt(space.length),
  }
  return calculationParams
}

export {
  SpaceActionType,
  calculateSpacePowerAndAdd,
  calculateSpacePowerAndUpdate,
  addSpace,
  updateSpace,
  setCurrentSpace,
  setSpaces,
  clearCurrentSpace,
}
