import Radiator from '../models/Radiator'

const findRadiatorById = (
  radiators: Array<Radiator>,
  id: string
): Radiator | null => {
  for (let i = 0; i < radiators.length; i++) {
    if (radiators[i].id.localeCompare(id) === 0) {
      return radiators[i]
    }
  }
  return null
}

const updateRadiator = (
  radiators: Array<Radiator>,
  radiator: Radiator
): Array<Radiator> => {
  return radiators.map((item: Radiator): Radiator => {
    if (item.id.localeCompare(radiator.id) === 0) {
      return radiator
    }
    return item
  })
}

const removeRadiator = (
  radiators: Array<Radiator>,
  radiator: Radiator
): Array<Radiator> => {
  return radiators.filter((item: Radiator): boolean => item.id !== radiator.id)
}

const totalRadiatorsPower = (radiators: Array<Radiator>): number => {
  return radiators.reduce(
    (sum: number, item: Radiator): number => sum + item.power,
    0
  )
}

const totalRadiatorsPercentPower = (
  radiators: Array<Radiator>,
  requestedPower: number
): number => {
  if (requestedPower === 0) {
    return 0
  }
  const current: number = totalRadiatorsPower(radiators)
  return (100 * current) / requestedPower
}

const differenceInPower = (
  radiators: Array<Radiator>,
  requestedPower: number
): number => {
  return requestedPower - totalRadiatorsPower(radiators)
}

const amountOfSelectedRadiators = (radiators: Array<Radiator>): number => {
  return radiators.reduce((currentValue: number, item: Radiator): number => {
    return item.isSelected ? currentValue + 1 : currentValue
  }, 0)
}


const amountOfSelectedItems = (radiators: Array<Radiator>): number => {
  return radiators.reduce((currentValue: number, item: Radiator): number => {
    return item.isItemSelected ? currentValue + 1 : currentValue
  }, 0)
}

export {
  differenceInPower,
  findRadiatorById,
  removeRadiator,
  totalRadiatorsPower,
  totalRadiatorsPercentPower,
  updateRadiator,
  amountOfSelectedRadiators,
  amountOfSelectedItems,
}
