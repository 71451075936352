import React from 'react'
import type { Node } from 'react'

import { ButtonBase, Typography, withStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import GridItemBase from './base/GridItemBase'

type AddSpaceItemProps = {
  classes: Object,
  onClick: Function,
  t: Function,
}

class AddSpaceItem extends React.Component<AddSpaceItemProps> {
  onClick() {
    const { onClick } = this.props
    if (onClick) {
      onClick()
    }
  }

  render(): Node {
    const { classes, t } = this.props
    return (
      <GridItemBase>
        <ButtonBase
          className={classes.container}
          color='error'
          onClick={(): void => this.onClick()}>
          <div className={classes.iconBackground}>
            <Add className={classes.icon} />
          </div>
          <Typography className={classes.label} color='primary' variant='h5'>
            {t('labels.addNewSpace').toUpperCase()}
          </Typography>
        </ButtonBase>
      </GridItemBase>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
    width: '100%',
  },
  iconBackground: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 29,
    width: 58,
    height: 58,
    marginTop: 142,
    marginBottom: 111,
  },
  icon: {
    color: 'white',
    fontSize: 46,
    fontWeight: 800,
  },
  label: {
    paddingBottom: 10,
  },
})

export default withTranslation('common')(withStyles(styles)(AddSpaceItem))
