import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Grid, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import queryString from 'query-string'

import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'
import RadiatorsGrid from '../components/collections/grids/RadiatorsGrid'
import DeleteRadiatorDialog from '../components/dialogs/DeleteRadiatorDialog'

import SpaceDetailsCompanionView from './companion/SpaceDetailsCompanionView'

import { setCurrentSpace } from '../actions/SpaceActions'
import {
  addRadiator,
  clearRadiatorsCollection,
  getRadiatorModelForRadiator,
  removeRadiator,
  setCurrentRadiator,
  setRadiatorsCollection,
} from '../actions/RadiatorActions'
import { findSpaceById } from '../selectors/spaceSelector'
import { amountOfSelectedRadiators } from '../selectors/radiatorSelectors'

import Radiator from '../models/Radiator'
import Space from '../models/Space'

type SpaceDetailsViewProps = {
  addRadiator: Function,
  classes: Object,
  clearRadiatorsCollection: Function,
  currentSpace: Space,
  getRadiatorModelForRadiator: Function,
  history: Object,
  location: Object,
  radiators: Array<Radiator>,
  removeRadiator: Function,
  setCurrentRadiator: Function,
  setCurrentSpace: Function,
  setRadiatorsCollection: Function,
  spaces: Array<Space>,
  t: Function,
}

type SpaceDetailsViewState = {
  radiatorToDelete: Radiator | null,
  isDialogOpen: boolean,
}

class SpaceDetailsView extends React.Component<
  SpaceDetailsViewProps,
  SpaceDetailsViewState
> {
  state = {
    radiatorToDelete: null,
    isDialogOpen: false,
  }
  componentDidMount() {
    this.setSpace()
  }

  shouldComponentUpdate(nextProps: SpaceDetailsViewProps): boolean {
    const { getRadiatorModelForRadiator, radiators } = this.props

    if (radiators && nextProps.radiators) {
      for (let index = 0; index < nextProps.radiators.length; index++) {
        const element = nextProps.radiators[index]
        if (element !== radiators[index]) {
          getRadiatorModelForRadiator(element)
        }
      }
    }
    return true
  }

  onAddRadiator() {
    const { history, radiators } = this.props
    history.push({
      pathname: '/radiators/create',
      state: {
        currentCount: radiators.length,
      },
    })
  }

  onOpenDeleteRadiatorDialog(item: Radiator) {
    this.setState({ isDialogOpen: true, radiatorToDelete: item })
  }

  onCloseDeleteRadiatorDialog() {
    this.setState({ isDialogOpen: false, radiatorToDelete: null })
  }

  onDeleteRadiator() {
    const { removeRadiator } = this.props
    const { radiatorToDelete } = this.state
    removeRadiator(radiatorToDelete)
    this.onCloseDeleteRadiatorDialog()
  }

  onDuplicate(radiator: Radiator) {
    const { addRadiator } = this.props
    let radiatorData: Object = radiator.toJSON()
    delete radiatorData.id
    radiatorData.name += ' (copy)'

    addRadiator(new Radiator(radiatorData))
  }

  onEditRadiator(item: Radiator) {
    const { history, radiators } = this.props
    history.push({
      pathname: '/radiators/edit',
      search: '?id=' + item.id,
      state: {
        currentCount: radiators.length,
      },
    })
  }

  onSelectRadiatorItem(radiator: Radiator) {
    const { history, setCurrentRadiator } = this.props

    setCurrentRadiator(radiator)
    history.push('/item/selection')
  }

  onEditSpace() {
    const { currentSpace, history } = this.props

    if (currentSpace) {
      history.push('/spaces/edit?id=' + currentSpace.id)
    }
  }

  onSave() {
    const { clearRadiatorsCollection, history } = this.props

    clearRadiatorsCollection()
    history.push('/spaces/overview')
  }

  setSpace() {
    const {
      location,
      setCurrentSpace,
      setRadiatorsCollection,
      spaces,
    } = this.props
    const query: Object = queryString.parse(location.search)
    if (query.id) {
      const space: Space | null = findSpaceById(spaces, query.id)
      setCurrentSpace(space)
      setRadiatorsCollection((space && space.radiators) || [])
    }
  }

  getInfo(): string {
    const { currentSpace, t } = this.props
    if (!currentSpace) {
      return ''
    }
    return amountOfSelectedRadiators(currentSpace.radiators) ===
      currentSpace.radiators.length
      ? t('info.spaceDetails.modelsSelected')
      : t('info.spaceDetails.define')
  }

  renderRadiatorsGrid(): Node {
    const { classes, radiators } = this.props
    return (
      <RadiatorsGrid
        className={classes.radiatorsContainer}
        data={radiators}
        onDelete={(item: Radiator): void =>
          this.onOpenDeleteRadiatorDialog(item)
        }
        onDuplicate={(item: Radiator): void => this.onDuplicate(item)}
        onSelect={(item: Radiator): void => this.onEditRadiator(item)}
        onSelectItem={(item: Radiator): void => this.onSelectRadiatorItem(item)}
        onAddItem={(): void => this.onAddRadiator()}
      />
    )
  }

  renderInfo(): Node {
    return (
      <InformationBox>
        <InformationParagraph>{this.getInfo()}</InformationParagraph>
      </InformationBox>
    )
  }

  renderContent(): Node {
    const { classes, currentSpace, t } = this.props

    const spaceName: string = currentSpace ? currentSpace.name : ''
    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h3'>
          {`${t('labels.radiatorsIn')}: ${spaceName}`}
        </Typography>
        {this.renderRadiatorsGrid()}
        {this.renderInfo()}
      </React.Fragment>
    )
  }

  renderCompanionView(): Node {
    const { currentSpace, radiators } = this.props
    return (
      <SpaceDetailsCompanionView
        space={currentSpace || {}}
        radiators={radiators || []}
        onEditSpace={(): void => this.onEditSpace()}
        onSave={(): void => this.onSave()}
      />
    )
  }

  render(): Node {
    const { classes } = this.props
    const { isDialogOpen } = this.state

    return (
      <div className={classes.container}>
        <DeleteRadiatorDialog
          isOpen={isDialogOpen}
          onAgree={(): void => this.onDeleteRadiator()}
          onCancel={(): void => this.onCloseDeleteRadiatorDialog()}
        />
        <Grid container spacing={8}>
          <Grid item sm={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item sm={4} xs={12}>
            {this.renderCompanionView()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = {
  container: {},
  title: {
    paddingBottom: 50,
  },
  radiatorsContainer: {
    marginBottom: 160,
  },
}

const mapStateToProps = (state: Object): Object => {
  return {
    currentSpace: state.spaces.current,
    radiators: state.radiators.all,
    spaces: state.spaces.all,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {
    addRadiator,
    clearRadiatorsCollection,
    getRadiatorModelForRadiator,
    setCurrentRadiator,
    setCurrentSpace,
    setRadiatorsCollection,
    removeRadiator,
  }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SpaceDetailsView)
  )
)
