import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core'
import { BrightnessHigh } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import queryString from 'query-string'

import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'
import RadiatorForm from '../components/forms/RadiatorForm'
import ConnectionsForm from '../components/forms/ConnectionsForm'
import CreateRadiatorCompanionView from './companion/CreateRadiatorCompanionView'
import RecommendationsView from './RecommendationsView'

import RadiatorModel from '../models/RadiatorModel'
import Radiator from '../models/Radiator'
import Space from '../models/Space'

import { addRadiator, updateRadiator } from '../actions/RadiatorActions'
import {
  findRadiatorById,
  totalRadiatorsPower,
} from '../selectors/radiatorSelectors'
import { getTotalRequiredPower } from '../selectors/spaceSelector'

type CreateRadiatorViewProps = {
  addRadiator: Function,
  classes: Object,
  history: Object,
  location: Object,
  radiators: Array<Radiator>,
  space: Space,
  t: Function,
  updateRadiator: Function,
}

type CreateRadiatorViewState = {
  areRecommendations: boolean,
  areRecommendationsOpen: boolean,
  isElectric: boolean,
  radiator: Radiator | null,
}

class CreateRadiatorView extends React.Component<
  CreateRadiatorViewProps,
  CreateRadiatorViewState
> {
  state = {
    areRecommendations: false,
    areRecommendationsOpen: false,
    isElectric: false,
    radiator: null,
  }

  componentDidMount() {
    const { history, location } = this.props
    const query: Object = queryString.parse(location.search)

    if (!location.state) {
      history.push('/start')
    } else {
      this.prepareData(query.id)
    }
  }

  onSave() {
    const { addRadiator, location, t, updateRadiator } = this.props
    const page: string = location.pathname.split('/')[2]
    let radiator: Radiator | null = this.state.radiator
    if (!radiator) {
      this.goBack()
      return
    }
    if (radiator.name.length === 0) {
      radiator.name = `${t(
        'labels.radiator.radiator'
      )} #${this.getRadiatorIndex()}`
    }

    if (page.localeCompare('edit') === 0) {
      updateRadiator(radiator)
    } else {
      addRadiator(radiator)
    }

    this.goBack()
  }

  onChangeRadiatorProperty(
    property: string,
    value: string | number | boolean | Array<any>
  ) {
    const { radiator } = this.state
    if (radiator) {
      this.setState({
        radiator: new Radiator({ ...radiator.toJSON(), [property]: value }),
      })
    }
  }

  onOpenRecommendationsView() {
    this.setState({ areRecommendationsOpen: true })
  }

  onCloseRecommendationsView() {
    this.setState({ areRecommendationsOpen: false })
  }

  prepareData(radiatorId?: string) {
    const { radiators } = this.props
    let radiator: Radiator | null
    if (radiatorId) {
      radiator = findRadiatorById(radiators, radiatorId)
    } else {
      radiator = new Radiator({
        heightFrom: 400,
        heightTo: 3300,
        widthFrom: 400,
        widthTo: 3300,
      })
    }

    if (radiator) {
      this.setState({
        radiator: radiator,
        areRecommendations: radiator.recommendation.length > 0,
        isElectric: radiator.isElectrical,
      })
    }
  }

  goBack() {
    const { history } = this.props
    history.goBack()
  }

  getRadiatorIndex(): number {
    const { radiator } = this.state
    const { location, radiators } = this.props
    const page: string = location.pathname.split('/')[2]
    return page.localeCompare('edit') === 0
      ? radiators.indexOf(radiator) + 1
      : location.state.currentCount + 1
  }

  renderRadiatorForm(): Node {
    const { radiator } = this.state
    const { space } = this.props

    return (
      <RadiatorForm
        maxPower={getTotalRequiredPower(space)}
        index={this.getRadiatorIndex()}
        radiator={radiator || {}}
        onChangeProperty={(property: string, value: any): void =>
          this.onChangeRadiatorProperty(property, value)
        }
      />
    )
  }

  renderRecommendationSwitch(): Node {
    const { classes, t } = this.props
    const { areRecommendations } = this.state
    return (
      <div>
        <div className={classes.recommendationSwitchContainer}>
          <FormControlLabel
            classes={{
              label: classes.recommendationSwitchLabel,
            }}
            control={<Switch color='primary' />}
            label={t('labels.recommendedProducts')}
            checked={areRecommendations}
            onChange={(event: Object): void =>
              this.setState({ areRecommendations: event.target.checked })
            }
          />
          <BrightnessHigh color='secondary' />
        </div>
        <Divider style={{ marginLeft: 62 }} />
      </div>
    )
  }

  renderRecommendationModal(): Node {
    const { classes } = this.props
    const { areRecommendationsOpen, radiator } = this.state

    return (
      <Modal
        style={{ zIndex: 1501 }}
        open={areRecommendationsOpen}
        onClose={(): void => this.onCloseRecommendationsView()}>
        <div className={classes.recommendationModal}>
          <RecommendationsView
            radiator={
              radiator ||
              new Radiator({
                heightFrom: 400,
                heightTo: 3300,
                widthFrom: 400,
                widthTo: 3300,
                power: 2000,
              })
            }
            onSave={(items: Array<RadiatorModel>) => {
              this.onChangeRadiatorProperty('recommendation', items)
              this.onCloseRecommendationsView()
            }}
            onClose={(): void => this.onCloseRecommendationsView()}
          />
        </div>
      </Modal>
    )
  }
  renderRecommendation(): Node {
    const { classes, t } = this.props
    const { areRecommendations } = this.state
    return (
      <React.Fragment>
        <Typography className={classes.label} variant='h5'>
          {t('labels.productRecommendation')}
        </Typography>
        <Grid style={{ marginBottom: 60 }} container spacing={6}>
          <Grid item xs={12} md={6}>
            {this.renderRecommendationSwitch()}
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              className={classes.button}
              disabled={!areRecommendations}
              color='inherit'
              onClick={(): void => this.onOpenRecommendationsView()}
              variant='outlined'>
              {t('labels.editRecommendedList')}
            </Button>
          </Grid>
        </Grid>
        {this.renderRecommendationModal()}
      </React.Fragment>
    )
  }

  renderInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox>
        <InformationParagraph>{t('info.createRadiator')}</InformationParagraph>
      </InformationBox>
    )
  }

  renderConnectionsForm(): Node {
    return <ConnectionsForm />
  }
  renderContent(): Node {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h3'>
          {t('titles.addNewRadiator')}
        </Typography>
        {this.renderRadiatorForm()}
        {this.renderRecommendation()}
        {this.renderInfo()}
        {this.renderConnectionsForm()}
      </React.Fragment>
    )
  }

  renderCompanionView(): Node {
    const { radiator } = this.state
    const { space, radiators } = this.props
    return (
      <CreateRadiatorCompanionView
        space={space}
        onSave={(): void => this.onSave()}
        radiatorPower={(radiator && radiator.power) || 0}
        radiatorName={(radiator && radiator.name) || ''}
        totalPower={totalRadiatorsPower(radiators)}
      />
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Grid container spacing={8}>
          <Grid item sm={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item sm={4} xs={12}>
            {this.renderCompanionView()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    marginBottom: 40,
  },
  recommendationModal: {
    position: 'absolute',
    top: 100,
    bottom: 100,
    left: 'calc(50% - 600px)',
    width: '100%',
    maxWidth: 1200,
    [theme.breakpoints.down('md')]: {
      left: 40,
      right: 40,
      top: 40,
      bottom: 40,
      width: 'auto',
    },
  },
  recommendationSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
  },
  recommendationSwitchLabel: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  textField: {
    paddingBottom: 8,
  },
  title: {
    marginBottom: 30,
  },
  label: {
    marginBottom: 20,
  },
  button: {
    marginTop: 12,
    width: '100%',
  },
})

const mapStateToProps = (state: Object): Object => {
  return {
    radiators: state.radiators.all,
    space: state.spaces.current,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {
    addRadiator,
    updateRadiator,
  }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CreateRadiatorView)
  )
)
