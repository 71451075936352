import RadiatorItem from '../models/RadiatorItem'

import { RadiatorItemActionType } from '../actions/RadiatorItemActions'
import type { RadiatorItemAction } from '../actions/RadiatorItemActions'

type RadiatorItemStateType = {
  all: Array<RadiatorItem> | null,
}

const defaultState: RadiatorItemStateType = {
  all: null,
}

export default (
  state: RadiatorItemStateType = defaultState,
  action: RadiatorItemAction
): RadiatorItemStateType => {
  let newState: RadiatorItemStateType | null = null

  switch (action.type) {
    case RadiatorItemActionType.RADIATOR_ITEMS_SET:
      newState = {
        ...state,
        all: action.items,
      }
      break
    case RadiatorItemActionType.RADIATOR_ITEMS_CLEAR:
      newState = {
        ...state,
        all: null,
      }
      break
    default:
      break
  }

  return newState || state
}
