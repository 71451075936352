import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'

import { withTranslation } from 'react-i18next'

import Radiator from '../../models/Radiator'
import Space from '../../models/Space'

import {
  clearCurrentRadiator,
  updateRadiator,
} from '../../actions/RadiatorActions'
// import {
//   clearCurrentRadiatorModel,
//   clearRadiatorsCategoriesModels,
// } from '../../actions/RadiatorModelActions'

type ModelInfoBoxProps = {
  classes: Object,
  clearCurrentRadiator: Function,
  currentRadiator: Radiator | null,
  currentSpace: Space | null,
  t: Function,
  updateRadiator: Function,
}

class ModelInfoBox extends React.Component<ModelInfoBoxProps> {
  shouldComponentUpdate(nextProps: ModelInfoBoxProps): boolean {
    if (nextProps.currentRadiator == null) {
      return false
    }
    return true
  }

  onBack() {
    const { clearCurrentRadiator } = this.props
    clearCurrentRadiator()
  }

  onSave() {
    const { clearCurrentRadiator, currentRadiator, updateRadiator } = this.props
    if (currentRadiator) {
      currentRadiator.isItemSelected = true
      updateRadiator(currentRadiator)
    }

    clearCurrentRadiator()
  }

  renderModelInfo(): Node {
    const { classes, currentRadiator, currentSpace, t } = this.props
    return (
      <React.Fragment>
        <Typography variant='subtitle2'>
          {`${t('labels.selectedProduct')}:`}
        </Typography>
        <Typography className={classes.title} variant='h3'>
          {currentRadiator &&
            currentRadiator.model &&
            currentRadiator.model.name}
        </Typography>
        <Divider className={classes.divider} />
        <Typography variant='caption'>
          {t('labels.radiator.location')}
        </Typography>
        <Typography variant='subtitle1'>
          {currentSpace && currentSpace.name}
        </Typography>
      </React.Fragment>
    )
  }

  renderButtons(): Node {
    const { classes, t } = this.props
    return (
      <Grid container spacing={2}>
        <Grid item md={4} sm={12}>
          <Button
            style={{ backgroundColor: 'white' }}
            className={classes.button}
            variant='outlined'
            color='inherit'
            onClick={(): void => this.onBack()}>
            {t('labels.back')}
          </Button>
        </Grid>
        <Grid item md={8} sm={12}>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={(): void => this.onSave()}>
            {t('labels.saveSelectionAndClose')}
          </Button>
        </Grid>
      </Grid>
    )
  }

  render(): Node {
    const { classes, currentRadiator } = this.props
    return (
      <React.Fragment>
        <div style={{ height: 122 }} />
        <div className={classes.container}>
          <Container maxWidth='lg' classes={{ root: classes.containerRoot }}>
            <Grid container spacing={4}>
              <Grid item sm={7} xs={12}>
                {this.renderModelInfo()}
              </Grid>
              <Grid item sm={5} xs={12}>
                <Typography className={classes.nameLabel}>
                  {currentRadiator ? currentRadiator.name : ''}
                </Typography>
                {this.renderButtons()}
              </Grid>
            </Grid>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    backgroundColor: theme.palette.background.main,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 30,
    boxShadow: '0px 3px 6px #00000029',
    position: 'fixed',
    minHeight: 192,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },
  containerRoot: {
    paddingLeft: 80,
    paddingRight: 80,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  button: {
    width: '100%',
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  divider: {
    marginBottom: 12,
    marginRight: 10,
    backgroundColor: theme.palette.secondary.light,
  },
  nameLabel: {
    fontSize: 22,
    fontWeight: 200,
    textAlign: 'right',
    marginTop: 45,
    marginBottom: 28,
    height: 33,
  },
})

const mapStateToProps = (state: Object): Object => {
  return {
    currentRadiator: state.radiators.current,
    currentSpace: state.spaces.current,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {
    clearCurrentRadiator,
    updateRadiator,
  }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ModelInfoBox)
  )
)
