import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'

import queryString from 'query-string'
import classNames from 'classnames'

import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'
import EmailFeedbackDialog from '../components/dialogs/EmailFeedbackDialog'

import OutlinedTextField from '../components/inputs/OutlinedTextField'

import {
  createProject,
  getProjectsCodes,
  getProject,
} from '../actions/ProjectActions'
import { clearBuilding } from '../actions/BuildingActions'

import Project from '../models/Project'
import Building from '../models/Building'

import { validateEmail } from '../utils/Validators'

type CreateProjectViewProps = {
  building: Building | null,
  classes: Object,
  clearBuilding: Function,
  createProject: Function,
  getProject: Function,
  getProjectsCodes: Function,
  history: Object,
  location: Object,
  project: Object,
  t: Function,
}

type CreateProjectViewState = {
  name: string,
  email: string,
  emailSent: boolean,
  code: string,
  codesEmail: string,
  errors: Object,
}

class CreateProjectView extends React.Component<
  CreateProjectViewProps,
  CreateProjectViewState
> {
  state = {
    name: '',
    email: '',
    emailSent: false,
    code: '',
    codesEmail: '',
    errors: {
      name: false,
      email: false,
      codesEmail: false,
    },
  }

  componentDidMount() {
    const { building, clearBuilding, location } = this.props
    const query: Object = queryString.parse(location.search)

    if (query.projectId) {
      this.setState({ code: query.projectId })
    }
    if (building) {
      clearBuilding()
    }
  }

  onFieldChange(property: string, value: string) {
    this.setState({
      [property]: value,
      errors: { name: false, email: false, codesEmail: false },
    })
  }

  onStartProject() {
    const { createProject, history } = this.props
    const { email, errors, name } = this.state

    const emailError: boolean = !validateEmail(email)
    const nameError: boolean = !(name == null || name.length > 0)

    if (emailError || nameError) {
      this.setState({
        errors: { ...errors, name: nameError, email: emailError },
      })
      return
    }
    createProject(new Project({ name: name, email: email }), i18n.language)
    history.push('/building')
  }

  onGetProject() {
    const { getProject, history } = this.props
    const { code } = this.state
    getProject(code)
    history.push('/spaces/overview')
  }

  onRequestCodes() {
    const { getProjectsCodes } = this.props
    const { codesEmail, errors } = this.state

    const error: boolean = !validateEmail(codesEmail)
    console.log('req')
    console.log(error)
    if (error) {
      this.setState({
        errors: { ...errors, codesEmail: error },
      })
      return
    }
    getProjectsCodes(codesEmail, i18n.language)
    this.setState({ codesEmail: '', emailSent: true })
  }

  onEmailFeedbackClose() {
    this.setState({ emailSent: false })
  }

  renderCodesInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox continuation>
        <InformationParagraph>{t('info.codes')}</InformationParagraph>
      </InformationBox>
    )
  }

  renderProjectInfo(): Node {
    const { t } = this.props
    return (
      <InformationBox>
        <InformationParagraph>{t('info.project.part1')}</InformationParagraph>
        <InformationParagraph>{t('info.project.part2')}</InformationParagraph>
        <InformationParagraph>{t('info.project.part3')}</InformationParagraph>
      </InformationBox>
    )
  }

  renderLogo(): Node {
    const { classes } = this.props
    return (
      <div className={classes.logoContainer}>
        <img src='/assets/logo.png' alt='logo' />
      </div>
    )
  }

  renderNewProjectSection(): Node {
    const { classes, t } = this.props
    const { email, code, name, errors } = this.state
    const isNewProjectDisabled: boolean = code != null && code.length > 0

    return (
      <React.Fragment>
        <Typography
          className={classNames(
            classes.title,
            isNewProjectDisabled ? classes.disabledTitle : null
          )}
          variant='h5'>
          {`${t('labels.createProject')}...`}
        </Typography>
        <OutlinedTextField
          disabled={isNewProjectDisabled}
          error={errors.name}
          label={t('labels.enterProject')}
          hint={t('hints.project')}
          value={name}
          onChange={(value: string): void => this.onFieldChange('name', value)}
        />
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <OutlinedTextField
              disabled={isNewProjectDisabled}
              error={errors.email}
              label={t('labels.enterEmail')}
              hint={t('hints.email')}
              value={email}
              onChange={(value: string): void =>
                this.onFieldChange('email', value)
              }
            />
          </Grid>
          <Grid item md xs={12} className={classes.centerGridItem}>
            <Button
              disabled={isNewProjectDisabled}
              style={{ width: '100%' }}
              variant='contained'
              color='primary'
              onClick={(): void => this.onStartProject()}>
              {t('labels.startProject')}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  renderOpenProjectSection(): Node {
    const { classes, t } = this.props
    const { code } = this.state
    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h5'>
          {`...${t('labels.openExistingProject')}`}
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <OutlinedTextField
              label={t('labels.enterCode')}
              hint={t('hints.code')}
              value={code}
              onChange={(value: string): void =>
                this.onFieldChange('code', value)
              }
            />
          </Grid>
          <Grid item md xs={12} className={classes.centerGridItem}>
            <Button
              style={{ width: '100%' }}
              variant='contained'
              color='primary'
              onClick={(): void => this.onGetProject()}>
              {t('labels.openProject')}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  renderNoCodesSection(): Node {
    const { classes, t } = this.props
    const { codesEmail, errors } = this.state
    return (
      <React.Fragment>
        <Typography className={classes.title} variant='h5'>
          {`${t('labels.noCode')}`}
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <OutlinedTextField
              error={errors.codesEmail}
              label={t('labels.enterEmail')}
              hint={t('hints.email')}
              value={codesEmail}
              onChange={(value: string): void =>
                this.onFieldChange('codesEmail', value)
              }
            />
          </Grid>
          <Grid item md xs={12} className={classes.centerGridItem}>
            <Button
              style={{ width: '100%' }}
              variant='outlined'
              color='inherit'
              onClick={(): void => this.onRequestCodes()}>
              {t('labels.emailCodes')}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  renderEmailFeedbackDialog(): Node {
    const { emailSent } = this.state
    const { t } = this.props
    return (
      <EmailFeedbackDialog
        isOpen={emailSent}
        onAgree={(): void => this.onEmailFeedbackClose()}
        message={t('labels.codesHaveBeenSent')}
      />
    )
  }

  renderContent(): Node {
    const { classes, t } = this.props

    return (
      <React.Fragment>
        <Typography className={classes.header} variant='h2'>
          {t('labels.purmoSelector').toUpperCase()}
        </Typography>
        {this.renderNewProjectSection()}
        {this.renderOpenProjectSection()}
        {this.renderNoCodesSection()}
        {this.renderEmailFeedbackDialog()}
      </React.Fragment>
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Grid container spacing={6}>
          <Grid item sm={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item sm={4} xs={12}>
            {this.renderLogo()}
            {this.renderProjectInfo()}
            {this.renderCodesInfo()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    width: '100%',
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 50,
  },
  centerGridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  header: {
    color: theme.palette.secondary.light,
    fontWeight: 300,
    marginBottom: 40,
    textAlign: 'center',
  },
  title: {
    paddingTop: 60,
    paddingBottom: 16,
  },
  disabledTitle: {
    color: theme.palette.disabled.main,
  },
})

const mapStateToProps = (state: Object): Object => {
  return {
    project: state.settings.project,
    building: state.building.current,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {
    clearBuilding,
    createProject,
    getProjectsCodes,
    getProject,
  }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CreateProjectView)
  )
)
