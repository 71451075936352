import React from 'react'
import type { Node } from 'react'

import { bindActionCreators } from 'redux'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Button, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import ItemsTable from '../components/collections/tables/ItemsTable'
import InformationBox from '../components/info/InformationBox'
import InformationParagraph from '../components/info/InformationParagraph'

import Building from '../models/Building'
import Radiator from '../models/Radiator'
import RadiatorItem from '../models/RadiatorItem'

import { getItems, clearItems } from '../actions/RadiatorItemActions'
import { setCurrentRadiator } from '../actions/RadiatorActions'

type ItemSelectionViewProps = {
  building: Building,
  classes: Object,
  clearItems: Function,
  currentRadiator: Radiator,
  getItems: Function,
  history: Object,
  items: Array<RadiatorItem>,
  setCurrentRadiator: Function,
  t: Function,
}

class ItemSelectionView extends React.Component<ItemSelectionViewProps> {
  componentDidMount() {
    const { building, currentRadiator, getItems } = this.props
    if (currentRadiator && building) {
      getItems(currentRadiator.selectedModelId, {
        minHeight: currentRadiator.heightFrom,
        maxHeight: currentRadiator.heightTo,
        minLength: currentRadiator.widthFrom,
        maxLength: currentRadiator.widthTo,
        power: currentRadiator.power,
        underheating: building.underheating,
        overheating: building.overheating,
        inTemp: 75, //building.heatEmission.flow,
        outTemp: 65, //building.heatEmission.return,
        roomTemp: building.heatEmission.room,
      })
    }
  }

  shouldComponentUpdate(nextProps: ItemSelectionViewProps): boolean {
    if (nextProps.currentRadiator == null) {
      this.props.history.goBack()
      return false
    }
    return true
  }

  componentWillUnmount() {
    const { clearItems } = this.props
    clearItems()
  }

  onSelectItem(item: RadiatorItem) {
    const { currentRadiator, setCurrentRadiator } = this.props

    let radiator: Radiator = new Radiator({
      ...currentRadiator.toJSON(),
      item: item.toJSON(),
    })
    radiator.model = currentRadiator.model
    setCurrentRadiator(radiator)
  }

  renderColorSection(): Node {
    const { classes, t } = this.props
    return (
      <div className={classes.colorContainer}>
        <Typography variant='h3'>
          {t('labels.radiatorColorSelection')}
        </Typography>
        <div className={classes.colorPickerContainer}>
          <Typography variant='subtitle1'>
            {`${t('labels.selectedColor')}:`}
          </Typography>
          <div className={classes.colorPicker} />
          <Typography variant='subtitle2'>
            {'RAL 9016 Traffic white'}
          </Typography>
        </div>
        <Button
          disabled
          className={classes.colorButton}
          variant='outlined'
          color='inherit'>
          {t('labels.changeRadiatorColor')}
        </Button>
      </div>
    )
  }

  renderInfo(): Node {
    const { classes, t } = this.props
    return (
      <InformationBox className={classes.parametersInfo}>
        <InformationParagraph>{t('info.itemSelection')}</InformationParagraph>
      </InformationBox>
    )
  }

  render(): Node {
    const { classes, currentRadiator, items, t } = this.props
    return (
      <div>
        <div className={classes.topSectionContainer}>
          <img
            className={classes.image}
            src={
              currentRadiator &&
              currentRadiator.model &&
              currentRadiator.model.image
            }
            alt='model_image'
          />
          <Typography variant='h3'>{t('labels.selectRadiatorItem')}</Typography>
        </div>
        <ItemsTable
          data={items || []}
          selectedItem={currentRadiator && currentRadiator.item}
          onSelect={(item: RadiatorItem): void => this.onSelectItem(item)}
        />
        {this.renderColorSection()}
        {this.renderInfo()}
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {},
  topSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40,
  },
  image: {
    height: 280,
    width: 'auto',
  },
  colorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 100,
    marginTop: 80,
  },
  colorPickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 40,
  },
  colorPicker: {
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 3,
    width: 47,
    height: 47,
    marginLeft: 30,
    marginRight: 30,
  },
  colorButton: {
    minWidth: 280,
  },
})

const mapStateToProps = (state: Object): Object => {
  return {
    building: state.building.current,
    currentRadiator: state.radiators.current,
    items: state.items.all,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>): Object => {
  const actions = {
    clearItems,
    getItems,
    setCurrentRadiator,
  }
  return bindActionCreators(actions, dispatch)
}

export default withTranslation('common')(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ItemSelectionView)
  )
)
