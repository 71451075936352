import React from 'react'
import type { Node } from 'react'

import { Typography, withStyles } from '@material-ui/core'

type LabelWithBackgroundProps = {
  classes: Object,
  label: string,
}

class LabelWithBackground extends React.Component<LabelWithBackgroundProps> {
  render(): Node {
    const { classes, label } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <Typography className={classes.label} variant='h6'>
            {label}
          </Typography>
        </div>

        <div className={classes.sideMark} />
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  innerContainer: {
    backgroundColor: theme.palette.secondary.main,
    padding: '4px 18px',
  },
  label: {
    color: 'white',
  },
  sideMark: {
    backgroundColor: theme.palette.primary.main,
    width: 6,
    marginLeft: 1,
  },
})

export default withStyles(styles)(LabelWithBackground)
