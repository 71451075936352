const templates = {
  actions: {
    goToTheProject: 'Go to the project',
    letsStartTheSelection: "Let's start the selection",
  },
  choiceRequest: 'Radiator model choice request',
  info: {
    congratulations:
      'Congratulations. You have successfully set up the new project in the HeatPicker Online Radiator Selector.',
    dontWorryGetInspired:
      'Don’t worry, before making the choice you can get inspired by the design & style collections.',
    installerHasSetUpTheProject:
      'Your Installer has setup the project and set all technical requirements for the radiators.',
    projectName: 'Project name',
    seeTheProjectNames:
      'Please see the project names and codes associated with your e-mail address',
    selectionHasBeenCompleted:
      'The person who you have requested to make the radiator model choice in your project has completed the selection.',
    thankYou: 'Thank you for using the HeatPicker Online Radiator Selector.',
    youHaveBeenRequested:
      'Now you have been requested to make the model selection based on your design and style preferences.',
  },
  modelsHasBeenSelected: 'Models have been selected',
  newProjectHasBeenSetUp: 'New project has been set up',
  notSpam: 'This is not a spam',
  yourProjectCodes: 'Your projects codes',
}

export default templates
