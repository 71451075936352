import Building from './Building'
import Space from './Space'
import RadiatorModel from './RadiatorModel'

class Project {
  name: string
  email: string
  code: string
  building: Building
  spaces: Array<Space>
  myModels: Array<RadiatorModel>

  constructor(data: Object = {}) {
    this.name = data.name || ''
    this.email = data.email || ''
    this.code = data.code || ''
    this.building = new Building(data && data.data ? data.data.building : {})
    this.spaces =
      data && data.data
        ? (data.data.spaces || []).map((item: Object): Space => new Space(item))
        : []
    this.myModels =
      data && data.data
        ? (data.data.myModels || []).map(
            (item: Object): RadiatorModel => new RadiatorModel(item)
          )
        : []
  }

  toJSON(): Object {
    const json = {
      name: this.name,
      email: this.email,
      code: this.code,
      data: {
        building: this.building.toJSON(),
        spaces: this.spaces.map((item: Space): Object => item.toJSON()),
        myModels: this.myModels.map((item: RadiatorModel): Object =>
          item.toJSON()
        ),
      },
    }
    return json
  }
}

export default Project
