import Space from '../models/Space'

const calculateTotalRequestedPower = (spaces: Array<Space>): number => {
  return spaces.reduce((sum: number, item: Space): number => {
    return sum + item.requestedPower
  }, 0)
}

const calculateTotalRadiators = (spaces: Array<Space>): number => {
  return spaces.reduce((sum: number, item: Space): number => {
    return sum + item.radiators.length
  }, 0)
}
export { calculateTotalRadiators, calculateTotalRequestedPower }
