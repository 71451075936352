import React from 'react'
import type { Node } from 'react'

import { Button, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames'

import LabelWithBackground from '../../components/labels/LabelWithBackground'
import CompanionContentContainer from '../../components/miscs/CompanionContentContainer'

import Space from '../../models/Space'
import Radiator from '../../models/Radiator'

import {
  differenceInPower,
  totalRadiatorsPower,
  totalRadiatorsPercentPower,
} from '../../selectors/radiatorSelectors'

import { getRoomTypeForId } from '../../utils/CalcProperties'

type EditSpaceCompanionViewProps = {
  classes: Object,
  onEditSpace?: Function,
  onSave?: Function,
  space: Space,
  radiators: Array<Radiator>,
  t: Function,
}

class EditSpaceCompanionView extends React.Component<EditSpaceCompanionViewProps> {
  static defaultProps = {
    onEditSpace: () => {},
    onSave: () => {},
    space: new Space(),
  }

  onEditSpace() {
    const { onEditSpace } = this.props
    if (onEditSpace) {
      onEditSpace()
    }
  }

  onSave() {
    const { onSave } = this.props
    if (onSave) {
      onSave()
    }
  }

  getRoomLabel(): string {
    const { space, t } = this.props
    if (space && space.type && space.type.localeCompare('1') === 0) {
      return `${t('labels.other')} (${space.temperature}°C)`
    }
    return getRoomTypeForId(space && space.type, t)
  }

  renderButtons(): Node {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Button
          className={classes.button}
          variant='outlined'
          color='inherit'
          onClick={(): void => this.onEditSpace()}>
          {t('labels.editSpaceParameters')}
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={(): void => this.onSave()}>
          {t('labels.saveAndExit')}
        </Button>
      </React.Fragment>
    )
  }

  renderCalculatedPower(): Node {
    const { classes, space, t } = this.props
    return (
      <div className={classes.calculationContainer}>
        <div className={classes.calculationItemLeft}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {t('labels.calculatedPower')}
          </Typography>
          <Typography
            className={classNames(classes.grayLabel, classes.valueLabel)}
            variant='h5'>
            {`${space.requestedPower || 0} W`}
          </Typography>
        </div>
        <div className={classes.calculationItemRight}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {t('labels.alternativePowerSource')}
          </Typography>
          <Typography
            className={classNames(classes.grayLabel, classes.valueLabel)}
            variant='h5'>
            {`${(space && space.alternativePower) || 0} W`}
          </Typography>
        </div>
      </div>
    )
  }

  renderRequiredPower(): Node {
    const { classes, space, t } = this.props
    return (
      <div className={classes.calculationContainer}>
        <div className={classes.calculationItemLeft}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {t('labels.requiredPower')}
          </Typography>
          <Typography className={classes.valueLabel} variant='h5'>
            {`${((space && space.requestedPower) || 0) -
              ((space && space.alternativePower) || 0)} W`}
          </Typography>
        </div>
        <div>
          <Typography
            className={classNames(
              classes.calculationEqualSign,
              classes.valueLabel
            )}
            variant='h5'>
            =
          </Typography>
        </div>
        <div className={classes.calculationItemRight}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {`${t('labels.requiredPower')} (%)`}
          </Typography>
          <Typography className={classes.valueLabel} variant='h5'>
            100%
          </Typography>
        </div>
      </div>
    )
  }

  renderRadiatorsPower(): Node {
    const { classes, radiators, space, t } = this.props
    return (
      <div className={classes.calculationContainer}>
        <div className={classes.calculationItemLeft}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {t('labels.radiatorsPower')}
          </Typography>
          <Typography
            className={classes.valueLabel}
            variant='h5'
            color='primary'>
            {`${totalRadiatorsPower(radiators || [])} W`}
          </Typography>
        </div>
        <div>
          <Typography
            className={classNames(
              classes.calculationEqualSign,
              classes.valueLabel
            )}
            variant='h5'>
            =
          </Typography>
        </div>
        <div className={classes.calculationItemRight}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {`${t('labels.radiatorsPower')} (%)`}
          </Typography>
          <Typography
            className={classes.valueLabel}
            variant='h5'
            color='primary'>
            {`${Math.round(
              totalRadiatorsPercentPower(
                radiators || [],
                (space && space.requestedPower - space.alternativePower) || 0
              )
            )} %`}
          </Typography>
        </div>
      </div>
    )
  }

  renderDifferences(): Node {
    const { classes, radiators, space, t } = this.props
    return (
      <div className={classes.calculationContainer}>
        <div className={classes.calculationItemLeft}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {t('labels.powerDifference')}
          </Typography>
          <Typography className={classes.valueLabel} variant='h5'>
            {`${differenceInPower(
              radiators || [],
              (space && space.requestedPower - space.alternativePower) || 0
            )} W`}
          </Typography>
        </div>
        <div className={classes.calculationItemRight}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {t('labels.requiredRadiators')}
          </Typography>
          <Typography
            className={classes.valueLabel}
            variant='h5'
            color='primary'>
            {radiators.length}
          </Typography>
        </div>
      </div>
    )
  }

  renderPicturesSection(): Node {
    const { classes, t } = this.props
    return (
      <div className={classes.calculationContainer}>
        <div className={classes.calculationItemLeft}>
          <Typography className={classes.calculationLabel} variant='caption'>
            {t('labels.images')}
          </Typography>
          <div className={classes.picturesContainer}>
            <img src='/assets/ic_wallpaper_24px.png' alt='wallpaper' />
            <Typography
              style={{ marginLeft: 15 }}
              className={classes.valueLabel}
              variant='h5'>
              3
            </Typography>
          </div>
        </div>
        <div className={classes.calculationItemRight}>
          <Button
            disabled
            className={classes.buttonPrev}
            color='inherit'
            variant='outlined'>
            {t('labels.preview')}
          </Button>
        </div>
      </div>
    )
  }

  renderCalculations(): Node {
    return (
      <React.Fragment>
        {this.renderCalculatedPower()}
        {this.renderRequiredPower()}
        {this.renderRadiatorsPower()}
        {this.renderDifferences()}
        {this.renderPicturesSection()}
      </React.Fragment>
    )
  }

  render(): Node {
    const { classes, space, t } = this.props

    return (
      <div className={classes.container}>
        <LabelWithBackground label={t('labels.summary')} />
        <CompanionContentContainer>
          <Typography variant='caption'>{t('labels.space.name')}</Typography>
          <Typography className={classes.label} variant='h3'>
            {space.name}
          </Typography>
          <Typography variant='caption'>{t('labels.space.type')}</Typography>
          <Typography className={classes.label} variant='h5' color='secondary'>
            {this.getRoomLabel()}
          </Typography>
          {this.renderCalculations()}
          {this.renderButtons()}
        </CompanionContentContainer>
      </div>
    )
  }
}

const styles = (theme: Object): Object => ({
  container: {
    marginTop: 70,
    position: 'sticky',
    top: 0,
  },
  powerInfoContainer: {
    paddingTop: 40,
    paddingBottom: 30,
  },
  button: {
    marginTop: 30,
    width: '100%',
  },
  buttonPrev: {
    width: '100%',
    marginTop: 5,
  },
  label: {
    paddingTop: 5,
    paddingBottom: 20,
  },
  calculationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  calculationEqualSign: {
    paddingTop: 20,
  },
  calculationItemLeft: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  calculationItemRight: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 150,
    [theme.breakpoints.down('md')]: {
      maxWidth: 120,
      minWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 80,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 150,
    },
  },
  grayLabel: {
    color: theme.palette.secondary.light,
  },
  calculationLabel: {
    paddingBottom: 5,
  },
  picturesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  valueLabel: {
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
})

export default withTranslation('common')(
  withStyles(styles)(EditSpaceCompanionView)
)
