import React from 'react'
import type { Node } from 'react'

import { Slider, withStyles } from '@material-ui/core'

import ValueLabel from '../miscs/ValueLabel'

type RangeType = [number, number]
export type MarkType = {
  value: number,
  label: string,
}

type RangeSliderProps = {
  classes: Object,
  defaultValue?: RangeType,
  marks?: Array<MarkType>,
  max?: number,
  min?: number,
  labelTransformation?: Function,
  onChange: Function,
  value: RangeType,
}

type RangeSliderState = {
  value: RangeType,
}

class RangeSlider extends React.Component<RangeSliderProps, RangeSliderState> {
  static defaultProps = {
    min: 0,
    max: 30,
    labelTransformation: (value: string): string => value,
    onChange: Function,
    value: [undefined, undefined],
  }

  onChange(value: any) {
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render(): Node {
    const {
      classes,
      defaultValue,
      labelTransformation,
      marks,
      min,
      max,
      value,
    } = this.props

    let computedValue: RangeType
    if (defaultValue) {
      computedValue = [value[0] || defaultValue[0], value[1] || defaultValue[1]]
    } else {
      computedValue = value
    }
    return (
      <Slider
        classes={{ root: classes.container, thumb: classes.thumb }}
        value={computedValue}
        marks={marks}
        min={min}
        max={max}
        onChange={(event: Event, val: RangeType): void => this.onChange(val)}
        aria-labelledby='range-slider'
        getAriaValueText={(v: string): string => 'aa'}
        valueLabelFormat={labelTransformation}
        valueLabelDisplay='on'
        ValueLabelComponent={ValueLabel}
      />
    )
  }
}

const styles = {
  container: {
    marginTop: 45,
  },
  thumb: {
    width: 29,
    height: 29,
    border: '4px solid white',
    bottom: -1,
  },
}

export default withStyles(styles)(RangeSlider)
